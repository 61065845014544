import { useState, useEffect } from "react";
import { getDatabase, ref, onValue, push, update } from "firebase/database";
import { useLocation } from "react-router-dom";
import { useRights } from "hooks/useRights";
import {
  getFirestore,
  addDoc,
  collection,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

export interface Visit {
  url: string;
  timestamp: string;
}

export interface SessionInfo {
  name: string;
  userId: string;
  referrer: string;
  referrerPage?: string;
  userAgent: string;
  screenResolution: string;
  deviceType: string;
  visits?: { [key: string]: Visit };
  botType: string;
}

export interface DailyData {
  [sessionId: string]: SessionInfo;
}

export const useDailyVisitsData = (initialDate: string) => {
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [dailyData, setDailyData] = useState<DailyData>({});

  useEffect(() => {
    const db = getDatabase();
    const dailyVisitsRef = ref(db, `dailyVisits/${selectedDate}`);

    const unsubscribe = onValue(dailyVisitsRef, (snapshot) => {
      const data = snapshot.val();
      setDailyData(data);
    });

    return () => unsubscribe();
  }, [selectedDate]);

  return {
    selectedDate,
    setSelectedDate,
    dailyData,
  };
};

const generateUniqueId = () => {
  const timestamp = new Date().getTime().toString(36);
  const randomString = Math.random().toString(36).substring(2, 15);
  return timestamp + randomString;
};

const usePageVisitLogger = () => {
  const [sessionId, setSessionId] = useState("");
  const [lastDetailLogDate, setLastDetailLogDate] = useState("");
  const location = useLocation();
  const { authUser } = useRights();
  const database = getDatabase();

  useEffect(() => {
    let storedSessionId;

    try {
      storedSessionId = localStorage.getItem("session_id");

      if (!storedSessionId) {
        storedSessionId = generateUniqueId();
        localStorage.setItem("session_id", storedSessionId);
      }
    } catch (err) {
      storedSessionId = generateUniqueId();
    }

    setSessionId(storedSessionId);
  }, []);

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0]; // Format: "YYYY-MM-DD"

    if (sessionId && currentDate !== lastDetailLogDate) {
      const userAgent = navigator.userAgent;
      const referrer = document.referrer;

      // Determine bot type based on user agent
      const determineBotType = (userAgentString: string) => {
        if (/googlebot/i.test(userAgentString)) return "Googlebot";
        if (/facebookexternalhit/i.test(userAgentString)) return "Facebook";
        if (/bingbot/i.test(userAgentString)) return "Bingbot";
        if (/bot|crawl|spider/i.test(userAgentString)) return "Other Bot";
        return ""; // Not a bot
      };

      let referrerPage = "";
      if (referrer) {
        const referrerUrl = new URL(referrer);
        referrerPage = referrerUrl.pathname; // Extracts the path of the referrer URL
      }

      const botType = determineBotType(userAgent);

      const sessionInfoUpdate: SessionInfo = {
        name: authUser?.displayName ?? "Anonymus",
        userId: authUser?.uid ?? "",
        referrer,
        referrerPage,
        userAgent,
        botType,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        deviceType: window.screen.width <= 768 ? "Mobile" : "Desktop",
      };

      const sessionRef = ref(
        database,
        `dailyVisits/${currentDate}/${sessionId}`
      );

      // Perform the update
      update(sessionRef, sessionInfoUpdate).catch((error) => {
        console.error("Error updating session info: ", error);
      });

      if (authUser || authUser === null) {
        setLastDetailLogDate(currentDate);
      }
    }
  }, [sessionId, authUser, database, lastDetailLogDate]);

  useEffect(() => {
    if (sessionId) {
      const visitData = {
        url: location.pathname,
        timestamp: new Date().toISOString(),
      };

      const db = getDatabase();
      const currentDate = new Date().toISOString().split("T")[0];
      const newVisitRef = ref(
        db,
        `dailyVisits/${currentDate}/${sessionId}/visits`
      );
      push(newVisitRef, visitData);
    }
  }, [location, sessionId]);
};

export default usePageVisitLogger;

export const useBotPageVisitLoggerFirestore = () => {
  const location = useLocation();
  const firestore = getFirestore();

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    const userAgent = navigator.userAgent;

    const botType = determineBotType(userAgent);
    if (botType === "") return;

    const referrer = document.referrer;
    let referrerPage = referrer ? new URL(referrer).pathname : "";

    const sessionInfoUpdate = {
      referrer,
      referrerPage,
      userAgent,
      botType,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      deviceType: window.screen.width <= 768 ? "Mobile" : "Desktop",
      timestamp: serverTimestamp(),
      pageVisits: {
        url: location.pathname,
      },
    };

    const sessionsCollectionRef = collection(
      firestore,
      `dailyVisits/${currentDate}/sessions`
    );
    addDoc(sessionsCollectionRef, sessionInfoUpdate).catch((error) =>
      console.error("Error adding session info: ", error)
    );
  }, [firestore, location]);

  function determineBotType(userAgentString: string) {
    if (/googlebot/i.test(userAgentString)) return "Googlebot";
    if (/facebookexternalhit/i.test(userAgentString)) return "Facebook";
    if (/bingbot/i.test(userAgentString)) return "Bingbot";
    if (/bot|crawl|spider/i.test(userAgentString)) return "Other Bot";
    return "";
  }
};
