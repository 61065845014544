import { useStateValue } from "utils/StateProvider";
export enum UserAction {
  GRAPE_EDIT = "GRAPE_EDIT",
  GRAPE_IMAGE_UPLOAD = "GRAPE_IMAGE_UPLOAD",
  USER_MENU_SHOW = "USER_MENU_SHOW",
  NEW_GRAPE = "NEW_GRAPE",
}

export enum Roles {
  SIGNED = "SIGNED",
  EDITOR = "EDITOR",
  ADMIN = "ADMIN",
  VIEW = "VIEW",
}

export const useRights = () => {
  const { authUser, token, authLoading } = useStateValue()!;
  const hasRight = (action: UserAction): boolean => {
    switch (action) {
      case UserAction.GRAPE_EDIT:
      case UserAction.GRAPE_IMAGE_UPLOAD:
        return !!token?.claims?.["editor"];
      case UserAction.NEW_GRAPE:
        return !!token?.claims?.["admin"];
      case UserAction.USER_MENU_SHOW:
        return !!authUser;
    }
    return !!authUser;
  };

  const hasRole = (role: Roles | undefined) => {
    if (!role) return false;
    switch (role) {
      case Roles.SIGNED:
        return !!authUser;
      case Roles.ADMIN:
        return !!token?.claims?.["admin"];
      case Roles.EDITOR:
        return !!token?.claims?.["editor"];
      default:
        return true;
    }
  };

  return { hasRight, authUser, hasRole, authLoading };
};
