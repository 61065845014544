import { ShortGrape } from "data/grape";
import { Tag, TagCategory } from "data/tag";
import { useMemo, useState } from "react";
import slugify from "slugify";
import { slugConfig } from "utils/general";
import { ShortGrapeWithId } from "./GrapeSearch";
import didYouMean, { ReturnTypeEnums } from "didyoumean2";

export const generateTagUrl = (tags: Tag[]) => {
  return tags
    .map((tag) => `${tag.id}-${slugify(tag.name, slugConfig)}`)
    .join(",");
};

export const extractTagIdsFromUrl = (url: string) => {
  const regex = /(\d+)-/g;
  let match;
  const tagIds = [];

  while ((match = regex.exec(url)) !== null) {
    tagIds.push(parseInt(match[1], 10));
  }

  return tagIds;
};

export const updateUrlParams = (params: Record<string, string>) => {
  let url = "";
  const paramStrings = [];

  for (const key in params) {
    if (params[key]) {
      paramStrings.push(`${key}=${params[key]}`);
    }
  }

  if (paramStrings.length > 0) {
    url += `?${paramStrings.join("&")}`;
  }

  return url;
};

export const doesGrapeMatchTags = (
  grape: ShortGrape,
  tagIdsByCategory: Map<number, number[]>
): boolean => {
  if (tagIdsByCategory.size === 0) return false;

  const values = Array.from(tagIdsByCategory.values());

  for (const tagIds of values) {
    let categoryMatch = false;

    for (const tagId of tagIds) {
      const arrayIndex = Math.floor(tagId / 32);
      const bitPosition = tagId % 32;

      if (
        grape.tm &&
        arrayIndex < grape.tm.length &&
        grape.tm[arrayIndex] & (1 << bitPosition)
      ) {
        categoryMatch = true;
        break; // Match found in this category
      }
    }

    if (!categoryMatch) return false;
  }

  return true;
};

export const groupTagsByCategory = (
  tags: Tag[],
  tagCategories: TagCategory[]
) => {
  const categoryMap = new Map();

  tags.forEach((tag) => {
    const tagCategory = tagCategories.find(
      (category) => category.id === tag.categoryId
    );
    if (tagCategory) {
      const existingTags = categoryMap.get(tagCategory.id) || [];
      categoryMap.set(tagCategory.id, [...existingTags, tag.id]);
    }
  });

  return categoryMap;
};

export const calculateRipeningTime = (
  ripeningFrom?: number,
  ripeningTo?: number
): number => {
  if (!!ripeningFrom && !!ripeningTo) {
    return (ripeningFrom + ripeningTo) / 2;
  } else if (ripeningFrom) {
    return ripeningFrom;
  } else {
    return 200;
  }
};

export const useAdditionalSearch = (
  grapes: ShortGrapeWithId[] | undefined = [],
  initialSearchTerm = ""
) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  const searchResults = useMemo(() => {
    if (!searchTerm) return grapes; // No search term, return all grapes

    const didYouMeanData = grapes
      .map((grape) => {
        const names = `${grape.name}, ${grape.additionalNames}`
          .replace(" ", ",")
          .split(",")
          .map((s) => s.trim());
        return names.map((name) => ({ index: grape.id, key: name }));
      })
      .flat();

    const suggestions = didYouMean(searchTerm, didYouMeanData, {
      matchPath: ["key"],
      returnType: ReturnTypeEnums.ALL_SORTED_MATCHES,
    }).filter((_, index) => index < 5) as { index: string }[];

    return grapes.filter(
      (grape) =>
        suggestions.some((s) => s.index === grape.id) ||
        grape.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        grape.additionalNames.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [grapes, searchTerm]);

  return { searchResults, searchTerm, setSearchTerm };
};
