// RecommendedGrapes.tsx

import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { useMediaQuery, Theme } from '@mui/material';
import Link from 'components/Link';
import { getImageUrl } from 'utils/general';
import { GrapeRecommendation } from 'data/grape';

interface RecommendedGrapesProps {
  recommendedGrapes: GrapeRecommendation[];
}

const RecommendedGrapes: FC<RecommendedGrapesProps> = ({ recommendedGrapes }) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Grid
      sx={{
        width: `calc(100% - 30px)`,
        margin: {
          xs: '30px 10px 10px 10px',
          lg: '0px 0px 0px 0px',
        },
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        boxSizing: 'border-box',
      }}
      container
      spacing={3}
      component="div"
    >
      <Grid item xs={12}>
        <Typography
          variant={isSmall ? 'h6' : 'h5'}
          noWrap
          component="div"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          További szőlők a katalógusból:
        </Typography>
      </Grid>
      {recommendedGrapes.map((_grape, index) => (
        <Grid item xs={12} md={6} lg={3} key={index}>
          <Card style={{ boxSizing: 'border-box', height: '100%' }}>
            <Link to={`/szolo/${_grape.id}`} style={{ textDecoration: 'none' }}>
              <CardMedia
                image={getImageUrl(`${_grape.id}_600x600`)}
                title={_grape.name ?? ''}
                sx={{
                  height: { xs: 180, sm: 300 },
                  backgroundSize: 'contain',
                  backgroundColor: '#ececec',
                  overflow: 'hidden',
                  position: 'relative',
                }}
              />
            </Link>
            <CardContent>
              <Link to={`/szolo/${_grape.id}`} style={{ textDecoration: 'none' }}>
                <Typography variant="h6" component="div">
                  {_grape.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {_grape.additionalNames}
                </Typography>
              </Link>
            </CardContent>
            <div style={{ display: 'none' }}>{`${_grape.name} szőlő fajta leírása`}</div>
          </Card>
        </Grid>
      ))}
      <Grid
        item
        xs={12}
        sx={{
          textAlign: 'start',
          marginTop: '10px',
          marginBottom: '30px',
        }}
      >
        <Link to="/szolok/uj">Legújabb szőlők</Link>
      </Grid>
    </Grid>
  );
};

export default RecommendedGrapes;
