import React, { FC } from "react";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getTextFieldProps } from "utils/formUtils";
import slugify from "slugify";
import { slugConfig } from "utils/general";

export interface NewListForm {
  name: string;
}

interface NewListDialogProps {
  onFormSave: (data: NewListForm) => void;
  listIds: string[];
}

export const newListFromId = "newGrapeFromId";

const getNewGrapeSchema = (): yup.SchemaOf<NewListForm> => {
  return yup.object({
    name: yup.string().required(),
  });
};

const NewListDialog: FC<NewListDialogProps> = ({ onFormSave, listIds }) => {
  const { register, formState, handleSubmit, watch } = useForm<NewListForm>({
    mode: "onChange",
    resolver: yupResolver(getNewGrapeSchema()),
    defaultValues: {
      name: "",
    },
  });

  const name = watch("name");
  const [extraError, setExtraError] = React.useState("");

  // csak leveszi a hibát
  React.useEffect(() => {
    if (!!extraError) {
      let slug = slugify(name, slugConfig);

      if (!listIds.includes(slug)) {
        setExtraError("");
      }
    }
  }, [extraError, setExtraError, name, listIds]);

  const handleSavePressed = async (data: NewListForm) => {
    let slug = slugify(data.name, slugConfig);

    if (listIds.includes(slug)) {
      setExtraError(`Létezik már ilyen: ${slug}`);
    } else {
      onFormSave(data);
    }
  };

  const { error, helperText, ...other } = getTextFieldProps(
    false,
    "name",
    register,
    formState,
    true
  );

  return (
    <form
      style={{ padding: "20px" }}
      id={newListFromId}
      onSubmit={handleSubmit(handleSavePressed)}
    >
      <TextField
        {...other}
        error={error || !!extraError}
        helperText={helperText || extraError}
        label="Név"
        size="small"
        autoFocus
      />
    </form>
  );
};

export default NewListDialog;
