import { useState, useEffect } from "react";
import { collection, getDocs, Firestore } from "firebase/firestore";
import { db } from "../utils/firebase";

export interface GrapeOld {
  name: string;
  description?: string;
  images?: string[];
  links?: string[];
  ripeningFrom?: number;
  ripeningTo?: number;
  russianName?: string;
  additionalNames?: string;
  seedless?: boolean;
  owners?: { [key: string]: boolean };
}

export const useGetGrapes = () => {
  const [grapes, setGrapes] = useState<GrapeOld[]>([]);

  const getGrapes = async (db: Firestore) => {
    const grapesCol = collection(db, "grapes");
    const grapeSnapshot = await getDocs(grapesCol);
    const grapeList = grapeSnapshot.docs.map((doc) => doc.data());
    return grapeList;
  };
  useEffect(() => {
    getGrapes(db).then((list) => setGrapes(list as GrapeOld[]));
  }, []);

  return grapes as GrapeOld[];
};
