import React, { ReactNode } from "react";
import Grid from "@mui/material/Grid";

interface InnerToolBarProps {
  children: ReactNode;
  sx?: object;
}

const InnerToolBar: React.FC<InnerToolBarProps> = ({ children, sx }) => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        padding: "10px",
        background: "white",
        gap: "20px",
        ...sx
      }}
    >
      {children}
    </Grid>
  );
};

export default InnerToolBar;
