import React, { FC, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Helmet } from "react-helmet";
import EditorRoleManagement from "./EditorRoleManagement";
import MostViewedGrapes from "./MostViewedGrapes";
import DailyVisitsList from "./DailyVisitsList";
import DailyVisitsAggreagate from "./DailyVisitsAggreagate";
import TagsPanel from "./TagsPanel";

const AdminPage: FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: { xs: "800px", lg: "1200px" },
        alignSelf: "center",
        overflow: "hidden",
        borderRadius: "4px",
        background: "white",
        gap: "10px",
        padding: "10px",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      }}
      component="div"
    >
      <Helmet>
        <title>Admin oldal</title>
      </Helmet>

        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab label="Szerkesztő Jog" />
          <Tab label="Címkék" />
          <Tab label="Népszerű Szőlők" />
          <Tab label="Napi statisztika lista" />
          <Tab label="Napi statisztika aggregált" />
        </Tabs>
        <Box
          sx={{  display: "flex", flexDirection: "column", height: "calc(100% - 50px)" }}
        >
          {selectedTab === 0 && <EditorRoleManagement />}
          {selectedTab === 1 && <TagsPanel />}
          {selectedTab === 2 && <MostViewedGrapes />}
          {selectedTab === 3 && <DailyVisitsList />}
          {selectedTab === 4 && <DailyVisitsAggreagate />}
        </Box>
    </Box>
  );
};

export default AdminPage;
