import React, { FC, useEffect } from "react";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Resolver, Controller } from "react-hook-form";
import { BREEDER_CATEGORY_ID, COUNTRY_CATEGORY_ID, TagDocument, useGetTagCategories, useGetTags } from "data/tag";
import { getTextFieldProps } from "utils/formUtils";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";

export interface TagForm extends TagDocument {
  id?: number;
}

interface NewEditTagDialogProps {
  tag?: TagForm;
  onFormSave: (data: TagForm) => void;
}

const getTagSchema = (): yup.SchemaOf<TagDocument> => {
  return yup.object({
    name: yup.string().required(),
    description: yup.string().notRequired(),
    namePostfix: yup.string().notRequired(),
    categoryId: yup.string().required(),
    countryId: yup.number().notRequired(),
  });
};

export const tagsFormId = "tagsFormId";

const NewEditTagDialog: FC<NewEditTagDialogProps> = ({ tag, onFormSave }) => {
  const { register, formState, handleSubmit, reset, control, watch } =
    useForm<TagForm>({
      mode: "onChange",
      resolver: yupResolver(getTagSchema()) as Resolver<TagForm>,
      defaultValues: tag ?? { name: "", description: "" },
    });
  const { data: tagCategories } = useGetTagCategories();
  const { data: countryTags } = useGetTags();

  const categoryId = watch("categoryId");

  useEffect(() => {
    if (tag) {
      reset(tag);
    }
  }, [tag, reset]);

  const handleSavePressed = async (data: TagForm) => {
    let dataToSave = { ...data };
    if (tag?.id) {
      dataToSave.id = tag.id;
    }
    console.log(dataToSave);
    onFormSave(dataToSave);
  };

  const nameFieldProps = getTextFieldProps(false, "name", register, formState);
  const namePostfixFieldProps = getTextFieldProps(false, "namePostfix", register, formState);
  const descriptionFieldProps = getTextFieldProps(
    false,
    "description",
    register,
    formState
  );

  return (
    <form
      style={{ display: "flex", flexDirection: "column", gap: "20px",  marginTop: "20px", width: "100%"}}
      id={tagsFormId}
      onSubmit={handleSubmit(handleSavePressed)}
    >
      <TextField {...nameFieldProps} label="Név" size="small" autoFocus />
      <TextField {...namePostfixFieldProps} label="Postfix" size="small" autoFocus />
      <TextField
        {...descriptionFieldProps}
        label="Leírás"
        size="small"
        multiline
        rows={4}
        error={!!formState.errors.description}
        helperText={formState.errors.description?.message}
      />
      <FormControl fullWidth error={!!formState.errors.categoryId}>
        <InputLabel id="tag-category-label">Kategória</InputLabel>
        <Controller
          name="categoryId"
          control={control}
          render={({ field }) => (
            <Select {...field} labelId="tag-category-label" label="Category">
              {tagCategories?.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>
          {formState.errors.categoryId?.message}
        </FormHelperText>
      </FormControl>
      {categoryId === BREEDER_CATEGORY_ID && (
        <FormControl fullWidth>
          <InputLabel id="country-label">Country</InputLabel>
          <Controller
            name="countryId"
            control={control}
            render={({ field }) => (
              <Select {...field} labelId="country-label" label="Country">
                {countryTags
                  ?.filter((tag) => tag.categoryId === COUNTRY_CATEGORY_ID)
                  .map((countryTag) => (
                    <MenuItem key={countryTag.id} value={countryTag.id}>
                      {countryTag.name}
                    </MenuItem>
                  ))}
              </Select>
            )}
          />
        </FormControl>
      )}
    </form>
  );
};

export default NewEditTagDialog;
