import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const scrollPositions = new Map<string, number>();

export const clearScrollPosition = (path: string) => {
  scrollPositions.delete(path);
};

const useScrollPosition = (scrollToTopIfNoPosition = false, smoothScroll = false) => {
  const location = useLocation();
  const ref = useRef<HTMLElement | null>(null);

  const scrollToPosition = (element: HTMLElement | Window, top: number) => {
    if (smoothScroll) {
      if (element === window) {
        window.scrollTo({ top, behavior: 'smooth' });
      } else {
        element.scrollTo({ top, behavior: 'smooth' });
      }
    } else {
      if (element === window) {
        window.scrollTo(0, top);
      } else {
        (element as HTMLElement).scrollTop = top;
      }
    }
  };

  useEffect(() => {
    const element = ref.current || window;

    const handleScroll = () => {
      const scrollPosition = ref.current
        ? ref.current.scrollTop
        : window.scrollY;
      scrollPositions.set(location.pathname, scrollPosition);
    };

    element.addEventListener("scroll", handleScroll);

    return () => {
      element.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  useEffect(() => {
    const savedPosition = scrollPositions.get(location.pathname);

    setTimeout(() => {
      if (savedPosition !== undefined) {
        scrollToPosition(ref.current || window, savedPosition);
      } else if (scrollToTopIfNoPosition) {
        scrollToPosition(ref.current || window, 0);
      }
    }, 100);
  }, [location.pathname, scrollToTopIfNoPosition]);

  return ref;
}

export default useScrollPosition;
