import React, { useState, FC, useMemo, useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import TextField from "@mui/material/TextField";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import MenuList from "@mui/material/MenuList";
import List from "@mui/material/List";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import isEqual from "lodash/isEqual";

import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  BREEDER_CATEGORY_ID,
  COUNTRY_CATEGORY_ID,
  Tag,
  TagCategory,
} from "data/tag";
import { keyframes } from "@emotion/react";

const glowColor = "rgba(150, 50, 255,";

const glowing = keyframes`
  0% {
    box-shadow: 0 0 5px ${glowColor} 0.4), 0 0 10px ${glowColor} 0.4);
  }
  50% {
    box-shadow: 0 0 20px ${glowColor} 0.8), 0 0 30px ${glowColor} 0.8);
  }
  100% {
    box-shadow: 0 0 5px ${glowColor} 0.4), 0 0 10px ${glowColor} 0.4);
  }
`;

interface TagFilterDropdownProps {
  tags: Tag[];
  tagCategories: TagCategory[];
  selectedTags: number[];
  onTagAdd: (tagId: number) => void;
  shouldStayOpenAfterSelection?: boolean;
  animateBackground?: boolean;
}

const TagFilterDropdown: FC<TagFilterDropdownProps> = ({
  tags,
  selectedTags,
  tagCategories,
  onTagAdd,
  shouldStayOpenAfterSelection = false,
  animateBackground,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState("");
  const [openCategories, setOpenCategories] = useState<string[]>([]);
  const [selectedCountryIds, setSelectedCountryIds] = useState<number[]>([]);

  useEffect(() => {
    const preSelectedCountries = tags
      .filter(
        (tag) =>
          selectedTags.includes(tag.id) &&
          tag.categoryId === COUNTRY_CATEGORY_ID
      )
      .map((tag) => tag.id);

    if (!isEqual(preSelectedCountries, selectedCountryIds)) {
      setSelectedCountryIds(preSelectedCountries);
    }

    const openIds = tagCategories
      .filter(
        (category) =>
          !tags.some(
            (tag) =>
              selectedTags.includes(tag.id) && tag.categoryId === category.id
          )
      )
      .map((category) => category.id);

    if (!isEqual(openIds, openCategories)) {
      setOpenCategories(openIds);
    }
  }, [tags, tagCategories, selectedTags]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTagAdd = (tagId: number) => {
    onTagAdd(tagId);
    if (!shouldStayOpenAfterSelection) handleClose();
  };

  const handleToggleCategory = (categoryId: string) => {
    setOpenCategories((prevOpenCategories) => {
      const isCategoryOpen = prevOpenCategories.includes(categoryId);

      if (isCategoryOpen) {
        return prevOpenCategories.filter((id) => id !== categoryId);
      } else {
        return [...prevOpenCategories, categoryId];
      }
    });
  };

  const filteredTagsByCategory = useMemo(() => {
    const lowerCaseFilter = filter.toLowerCase();
    return tagCategories.map((category) => ({
      ...category,
      tags: tags.filter(
        (tag) =>
          tag.categoryId === category.id &&
          tag.name.toLowerCase().includes(lowerCaseFilter) &&
          (category.id !== BREEDER_CATEGORY_ID ||
            selectedCountryIds.includes(tag?.countryId ?? -1))
      ),
    }));
  }, [tags, tagCategories, filter, selectedCountryIds]);

  return (
    <div>
      <Button
        sx={{
          ...(animateBackground && { animation: `${glowing} 2s infinite` }),
        }}
        aria-controls="tag-filter-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        startIcon={<AddIcon />}
        variant="contained"
        size="small"
      >
        Új címke
      </Button>
      <Menu
        id="tag-filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ overflow: "hidden" }}
      >
        <Box sx={{ overflow: "hidden" }}>
          <TextField
            label="Szűrés címkére..."
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            variant="outlined"
            sx={{ m: 1 }}
          />
          <MenuList sx={{ maxHeight: 350, overflow: "auto" }}>
            {filteredTagsByCategory.map(
              (category) =>
                category.tags.length > 0 && (
                  <Paper key={category.id}>
                    <List>
                      <ListItemButton
                        onClick={() => handleToggleCategory(category.id)}
                      >
                        <ListItemText primary={category.name} />
                        {openCategories.includes(category.id) ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>

                      <Collapse
                        in={openCategories.includes(category.id)}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {category.tags.map((tag) => (
                            <ListItemButton
                              selected={selectedTags.includes(tag.id)}
                              key={tag.id}
                              onClick={() => handleTagAdd(tag.id)}
                            >
                              <ListItemText
                                primary={`${tag.name} ${
                                  tag?.namePostfix ?? ""
                                }`}
                                inset={true}
                              />
                            </ListItemButton>
                          ))}
                        </List>
                      </Collapse>
                    </List>
                  </Paper>
                )
            )}
          </MenuList>
        </Box>
      </Menu>
    </div>
  );
};

export default TagFilterDropdown;
