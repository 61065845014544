import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import { useGetUser } from "data/user";
import GrapeListItem from "./GrapeListItem";
import { useGetShortGrapes, ShortGrape } from "data/grape";

import { createLocationString } from "utils/general";
import { useMeasure } from "react-use";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import ChatIcon from "@mui/icons-material/Chat";
import Button from "@mui/material/Button";
import { Helmet } from "react-helmet";
import {
  useCanStartNewConversation,
  useCreateConversation,
} from "data/messages-realtime";
import { Roles, useRights } from "hooks/useRights";
import InnerToolBar from "./common/InnerToolBar";
import { useConfirmDialog } from "./common/ConfirmDialog/useConfirmDialog";
import ConfirmDialog from "./common/ConfirmDialog/ConfirmDialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { toast } from "react-toastify";

const UserPage: FC = () => {
  let { id } = useParams<{ id: string }>();
  const { data: user } = useGetUser(id!, true);
  const { data: grapes } = useGetShortGrapes();
  const location = createLocationString(user);
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const { mutateAsync: createConversation } = useCreateConversation();
  const navigate = useNavigate();
  const { hasRole, authUser } = useRights();
  const [dialogProps, setDialogProps, setDialogOpen] = useConfirmDialog();

  const newUserPeriod = new Date();
  newUserPeriod.setDate(newUserPeriod.getDate() - 14);
  const isNewUser = user?.created
    ? new Date(user.created.seconds * 1000) > newUserPeriod
    : false;

  const { canStartNew, isLoading } = useCanStartNewConversation(authUser?.uid);

  const handleNewMessagePressed = async (userId: string, senderId: string) => {
    if (isLoading) {
      return;
    }
    if (!canStartNew && !hasRole(Roles.EDITOR)) {
      toast("Nem indíthatsz ma már új beszélgetést, próbáld meg holnap.");
      return;
    }

    setDialogProps({
      title: "Üzenet küldés",
      actionButtonText: "Üzenetet küldök",
      cancelButtonText: "Mégsem",
      children: (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InfoOutlinedIcon style={{ marginRight: "10px" }} />
          <span>
            <p>A kéretlen üzenetek küldése kitiltással járhat.</p>
            <p>Naponta csak egy üzenet küldést kezdeményethetsz.</p>
            <p>
              Az üzenetet a címzett csak a következő belépéskor fogja látni.
            </p>
          </span>
        </Box>
      ),
      onConfirm: async () => {
        const conversationId = await createConversation({
          data: {
            participantIds: [userId, senderId],
          },
          initiatorUserId: senderId,
        });
        navigate(`/uzenetek/${conversationId}`);
        setDialogOpen(false);
      },
      onCancel: () => setDialogOpen(false),
    });
    setDialogOpen(true);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
      component="div"
    >
      <Box sx={{ width: "100%", overflow: "auto" }}>
        <Helmet>
          <title>{`Gazda: ${user?.displayName ?? ""}`}</title>
        </Helmet>
        <Card
          sx={{
            maxWidth: { xs: "800px" },
            width: "100%",
            background: "white",
            alignSelf: "center",
            margin: "auto",
            position: "relative",
            padding: "10px",
            marginBottom: "20px",
          }}
          ref={ref}
          component="div"
        >
          {user ? (
            <div>
              {authUser && id && (
                <InnerToolBar>
                  {(!user.messagingDisabled || hasRole(Roles.EDITOR)) &&
                    authUser?.uid !== id && (
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ChatIcon />}
                        onClick={() =>
                          id && handleNewMessagePressed(id, authUser?.uid)
                        }
                      >
                        Üzenet küldése
                      </Button>
                    )}
                </InnerToolBar>
              )}
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  marginBottom: "10px",
                  flexDirection: {
                    xs: "column",
                    lg: "row",
                  },
                }}
              >
                <Badge color="error" badgeContent="Új" invisible={!isNewUser}>
                  <Avatar
                    sx={{
                      width: {
                        xs: 100,
                        lg: 150,
                      },
                      height: {
                        xs: 100,
                        lg: 150,
                      },
                    }}
                    alt={user.displayName}
                    src={`${user.photoURL}?type=large`}
                  />
                </Badge>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: { xs: "center", lg: "flex-start" },
                    flexDirection: "column",
                  }}
                >
                  <Typography align="center" variant="h4" component="div">
                    {user.displayName}
                  </Typography>
                  <Typography align="center" variant="body1" component="div">
                    {location.join(", ")}
                  </Typography>

                  {user.contactInfo && (
                    <Typography
                      align="center"
                      style={{ marginTop: "20px" }}
                      variant="body1"
                      component="div"
                    >
                      {`Elérhetőség: ${user.contactInfo}`}
                    </Typography>
                  )}
                </Box>
              </Box>
              {user.aboutMe && (
                <Typography
                  style={{ marginTop: "20px" }}
                  variant="body1"
                  component="div"
                >
                  {user.aboutMe}
                </Typography>
              )}

              {user.myGrapes &&
                grapes &&
                Object.keys(user.myGrapes).length > 0 && (
                  <>
                    <Typography
                      style={{ marginTop: "20px" }}
                      variant="h6"
                      noWrap
                      component="div"
                    >
                      Szőlők ({Object.keys(user.myGrapes).length} féle)
                    </Typography>
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: { xs: "600px" },
                        bgcolor: "background.paper",
                      }}
                    >
                      {Object.keys(user.myGrapes)
                        .map((id) => [id, grapes[id]])
                        .sort((a, b) =>
                          (a[1] as ShortGrape).name.localeCompare(
                            (b[1] as ShortGrape).name,
                            "hu"
                          )
                        )
                        .map(([grapeId, grape]) => (
                          <GrapeListItem
                            grape={grape as ShortGrape}
                            key={grapeId as string}
                            grapeId={grapeId as string}
                            width={width}
                          />
                        ))}
                    </List>
                  </>
                )}
            </div>
          ) : (
            <CircularProgress />
          )}
        </Card>
      </Box>
      <ConfirmDialog {...dialogProps} />
    </Box>
  );
};

export default UserPage;
