import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import {
  doc,
  getDoc,
  Timestamp,
  collection,
  setDoc,
} from "@firebase/firestore";
import { db } from "../utils/firebase";
import { useStateValue } from "utils/StateProvider";
import useSetGrapeHistory, { createDeltaGrape } from "./grapeHistory";

export interface GrapeRecommendation {
  id: string;
  name?: string;
  additionalNames?: string;
}

export interface Grape {
  name: string;
  description?: string;
  images?: string[];
  links?: string[];
  ripeningFrom?: number;
  ripeningTo?: number;
  russianName?: string;
  additionalNames?: string;
  owners?: { [key: string]: boolean };
  created: Timestamp;
  notFinished: boolean;
  shouldEdited: boolean;
  editorNote?: string;
  tags?: number[];
  recommendedGrapes?: GrapeRecommendation[]
}

export interface ShortGrape {
  name: string;
  additionalNames: string;
  ripeningFrom?: number;
  ripeningTo?: number;
  created?: Timestamp;
  tm?: number[]; // Tag mask
  o?: number; // number of owners
}

export interface ShortGrapeList {
  [key: string]: ShortGrape;
}

export interface ShortGrapeToEditedList {
  [key: string]: ShortGrape;
}

export const NEW_GRAPE_DURATION_DAYS = 30;

export const GET_GRAPE = "GET_GRAPE";
export const SHORT_GRAPE_LIST = "SHORT_GRAPE_LIST";
export const SHORT_GRAPETO_EDITED_LIST = "SHORT_GRAPETO_EDITED_LIST";

const fetchGrape = async (id: string): Promise<Grape> => {
  const docRef = doc(db, "grapes", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as Grape;
  } else {
    throw new Error("Grape not found");
  }
};

export const useGetGrape = (id: string): UseQueryResult<Grape, Error> => {
  return useQuery<Grape, Error>({
    queryKey: [GET_GRAPE, id],
    queryFn: () => fetchGrape(id),
  });
};

export const oneHour = 1000 * 60 * 60;

const useSetGrape = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newGrape: Grape) => {
      const grapeRef = doc(collection(db, "grapes"), id);
      return setDoc(grapeRef, newGrape, { merge: true });
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_GRAPE, id] });
    },
  });
};

export const useSetGrapeWithHistory = (grapeId: string) => {
  const { authUser } = useStateValue()!;
  const setGrape = useSetGrape(grapeId);
  const setGrapeHistory = useSetGrapeHistory(grapeId);

  const setGrapeWithLog = async (originalGrape: Grape, newGrape: Grape) => {
    await setGrape.mutateAsync(newGrape);
    await setGrapeHistory.mutateAsync({
      userId: authUser?.uid ?? "",
      userName: authUser?.displayName ?? "",
      date: Timestamp.fromDate(new Date()),
      grape: createDeltaGrape(originalGrape, newGrape),
    });
  };

  return { setGrapeWithLog };
};

const fetchShortGrapes = async (): Promise<ShortGrapeList> => {
  const docRef = doc(db, "pages", "grapeList");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as ShortGrapeList;
  } else {
    throw new Error("Short grape list not found");
  }
};

export const useGetShortGrapes = (
  enabled = true
): UseQueryResult<ShortGrapeList, Error> => {
  return useQuery<ShortGrapeList, Error>({
    queryKey: [SHORT_GRAPE_LIST],
    queryFn: fetchShortGrapes,
    enabled,
    staleTime: oneHour,
  });
};

const fetchGrapesToEdited = async (): Promise<ShortGrapeToEditedList> => {
  const docRef = doc(db, "pages", "grapeEditList");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as ShortGrapeToEditedList;
  } else {
    throw new Error("Grapes to be edited not found");
  }
};

export const useGetGrapesToEdited = (
  enabled = true
): UseQueryResult<ShortGrapeToEditedList, Error> => {
  return useQuery<ShortGrapeToEditedList, Error>({
    queryKey: [SHORT_GRAPETO_EDITED_LIST],
    queryFn: fetchGrapesToEdited,
    enabled,
  });
};
