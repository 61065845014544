import React, { FC } from "react";
import Avatar from "@mui/material/Avatar";
import { getImageUrl } from "utils/general";
import { ShortGrape } from "data/grape";


interface GrapeAvatarProps {
  grape: ShortGrape;
  grapeId: string;
  width?: number;
}

const GrapeAvatar: FC<GrapeAvatarProps> = ({ grape, grapeId, width }) => {
  const [imageUrl, setImageUrl] = React.useState(
    getImageUrl(`${grapeId}_120x120`)
  );

  const onError = () => {
    setImageUrl("/images/bg_s_i.jpg");
  };

  return (
    <Avatar
      sx={{
        width: width ? `${width}px` : { xs: "80px" },
        height: width ? `${width}px` : { xs: "80px" },
        borderRadius: "5%",
        minWidth: "unset",
      }}
      alt="szolo"
      src={imageUrl}
      imgProps={{ onError: onError }}
    />
  );
};

export default GrapeAvatar;
