import React, { useEffect, FC } from "react";
import { useLocation } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";

const logCurrentPage = (pathname: string) => {
  const analytics = getAnalytics();
  const parts = pathname.split("/");
  let screen = parts.pop();
  screen = screen ? screen : "main";
  const screenClass = parts.pop();
  logEvent(analytics, "screen_view", {
    firebase_screen: screen,
    firebase_screen_class: screenClass ? screenClass : screen,
  });
};

const AnalyticsComponent: FC = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    logCurrentPage(pathname);
  }, [pathname]);
  return <div></div>;
};

export default AnalyticsComponent;
