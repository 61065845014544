import { FC, useEffect, useState } from "react";
import List from "@mui/material/List";
import { ShortUserList } from "data/user";
import UserListItem from "components/UserListItem";
import {
  ConversationData,
  UserConversation,
  UserConversations,
  createUserConversationFromData,
  useRemoveConversation,
  useToggleMuteConversation,
  useUpdateUnreadConversations,
} from "data/messages-realtime";
import Badge from "@mui/material/Badge";
import { useActiveConversationId } from "./hooks";
import StatusIconWrapper from "./StatusIconWrapper";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import NotificationsIcon from "@mui/icons-material/Notifications";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import { useNavigate } from "react-router-dom";
import GroupConvListItem from "./GroupConvListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirmDialog } from "components/common/ConfirmDialog/useConfirmDialog";
import ConfirmDialog from "components/common/ConfirmDialog/ConfirmDialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface MessageListProps {
  userId: string;
  selectedConversationId: string | null;
  selectedConversation: ConversationData | null | undefined;
  userConversations: UserConversations | null;
  users?: ShortUserList;
}

interface DisplayUserConversation extends UserConversation {
  id: string;
  isGroup: boolean;
  otherParticipantId: string;
  unread: boolean;
}

const newUserPeriod = new Date();

const ConversationsList: FC<MessageListProps> = ({
  userId,
  selectedConversationId,
  selectedConversation,
  userConversations,
  users,
}) => {
  const [conversations, setConversations] = useState<DisplayUserConversation[]>(
    []
  );
  const { mutateAsync: updateUnread } = useUpdateUnreadConversations();
  const getActiveConversationId = useActiveConversationId();
  const { mutateAsync: toggleMute } = useToggleMuteConversation();
  const navigate = useNavigate();
  const [dialogProps, setDialogProps, setDialogOpen] = useConfirmDialog();
  const { mutateAsync: removeConversation } = useRemoveConversation();

  const handleToggleMute = async (conversationId: string, isMuted: boolean) => {
    setDialogProps({
      title: isMuted ? "Némítás megszüntetése" : "Beszélgetés némítása",
      actionButtonText: isMuted ? "Némítás megszüntetése" : "Némítás",
      cancelButtonText: "Mégsem",
      children: (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InfoOutlinedIcon style={{ marginRight: "10px" }} />
          <p>
            {isMuted
              ? "Szeretnél újra értesítéseket kapni erről a beszélgetésről?"
              : "Biztosan némítani szeretnéd ezt a beszélgetést? Ekkor nem kapsz több értesítést róla, de továbbra is látható marad."}
          </p>
        </Box>
      ),
      onConfirm: () => {
        toggleMute({ userId, conversationId, mute: !isMuted }).then(() =>
          setDialogOpen(false)
        );
      },
      onCancel: () => setDialogOpen(false),
    });
    setDialogOpen(true);
  };

  const handleRemoveConversation = async (conversationId: string) => {
    try {
      setDialogProps({
        title: "Biztos törlöd a beszélgetést?",
        actionButtonText: "Törlés",
        cancelButtonText: "Mégsem",
        children: (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <InfoOutlinedIcon style={{ marginRight: "10px" }} />
            <p>
              Ez a művelet csak a te listádról távolítja el a beszélgetést. A
              többi résztvevő számára továbbra is látható marad, és új üzenetek
              érkezésekor újra megjelenik nálad.
            </p>
          </Box>
        ),
        onConfirm: () => {
          removeConversation({ userId, conversationId }).then(() =>
            setDialogOpen(false)
          );
          navigate("/uzenetek");
        },
        onCancel: () => setDialogOpen(false),
      });
      setDialogOpen(true);
    } catch (error) {
      console.error("Error removing conversation:", error);
    }
  };

  useEffect(() => {
    if (userConversations) {
      let _userConversations = { ...userConversations.conversations };
      if (
        selectedConversation &&
        selectedConversationId &&
        !userConversations?.conversations?.[selectedConversationId]
      ) {
        _userConversations[selectedConversationId] =
          createUserConversationFromData(selectedConversation, false);
      }

      const _conversations: DisplayUserConversation[] = Object.entries(
        _userConversations
      )
        .map(([id, conversation]) => {
          return {
            id,
            ...conversation,
            isGroup: conversation.participantIds?.length > 2,
            unread: (userConversations?.unreadConversationIds ?? []).includes(
              id
            ),
            otherParticipantId:
              conversation.participantIds?.find(
                (participantId) => participantId !== userId
              ) ?? "",
          };
        })
        .sort(
          (a, b) =>
            (b.lastMessageTimestamp as number) -
            (a.lastMessageTimestamp as number)
        );

      setConversations(_conversations);
    }
  }, [userConversations, userId, selectedConversation, selectedConversationId]);

  useEffect(() => {
    if (
      selectedConversationId &&
      userConversations?.unreadConversationIds?.includes(selectedConversationId)
    ) {
      const timer = setTimeout(() => {
        updateUnread({
          userId,
          conversationId: selectedConversationId,
          add: false,
        });
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [selectedConversationId, userId, updateUnread, userConversations]);

  const generateMenuItems = (
    conversationId: string,
    isMuted: boolean,
    otherParticipantId?: string
  ) => {
    const defaultMenuItems = [
      {
        label: "Legyen olvasatlan",
        onClick: () =>
          updateUnread({
            userId,
            conversationId,
            add: true,
          }),
        icon: MarkAsUnreadIcon,
        tooltip: `Átállítom olvasatlanná a beszélgetést.`,
      },
      {
        label: isMuted ? "Némítás törlése" : "Beszélgetés némítása",
        onClick: () => handleToggleMute(conversationId, isMuted),
        icon: isMuted ? NotificationsIcon : NotificationsOffIcon,
        tooltip: isMuted
          ? "Szeretnék újra értesítést kapni erről a beszélgetésről."
          : "Nem szeretnék több értesítést kapni erről a beszélgetésről.",
      },
      {
        label: "Beszélgetés törlése",
        onClick: () => handleRemoveConversation(conversationId),
        icon: DeleteIcon, // Az ikon, amit szeretnél használni
        tooltip: "Töröld ezt a beszélgetést.",
      },
    ];

    const userMenuItems = otherParticipantId
      ? [
          {
            label: "Gazda adatlapja",
            onClick: () => navigate(`/gazda/${otherParticipantId}`),
            icon: RemoveRedEyeIcon,
            tooltip: `Megnézem a gazda adatlapját.`,
          },
        ]
      : [];

    return [...userMenuItems, ...defaultMenuItems];
  };

  return (
    <Box sx={{ height: "100%" }}>
      {users &&
        (conversations.length > 0 ? (
          <List>
            {conversations.map((conversation) => {
              const isGroupChat = conversation.participantIds.length > 2;
              const participantUsers = conversation.participantIds.map(
                (id) => users[id]
              );
              const user = users[conversation.otherParticipantId];
              const menuItems = generateMenuItems(
                conversation.id,
                !!conversation.muted,
                isGroupChat ? undefined : conversation.otherParticipantId
              );

              return (
                <Badge
                  sx={{
                    width: "100%",
                    "& .MuiBadge-badge": {
                      top: 11,
                      right: 16,
                    },
                  }}
                  key={conversation.id}
                  color="error"
                  variant="dot"
                  invisible={
                    !conversation.unread ||
                    getActiveConversationId() === conversation.id
                  }
                >
                  <StatusIconWrapper showMuteIcon={!!conversation.muted}>
                    {isGroupChat ? (
                      <GroupConvListItem
                        key={conversation.id}
                        conversationName={conversation.name ?? "Group Chat"} // Default name if not provided
                        participantUsers={participantUsers}
                        link={`/uzenetek/${conversation.id}`} // Adjust the link as needed
                        selected={selectedConversationId === conversation.id}
                        menuItems={menuItems}
                      />
                    ) : (
                      <UserListItem
                        user={user}
                        link={`/uzenetek/${conversation.id}`}
                        isNewUser={
                          user?.created
                            ? new Date(user.created.seconds * 1000) >
                              newUserPeriod
                            : false
                        }
                        selected={selectedConversationId === conversation.id}
                        menuItems={menuItems}
                      />
                    )}
                  </StatusIconWrapper>
                </Badge>
              );
            })}
          </List>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography variant="h6">Nem érkezett még üzenet.</Typography>
          </Box>
        ))}
      <ConfirmDialog {...dialogProps} />
    </Box>
  );
};

export default ConversationsList;
