import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { GrapeHistory } from "data/grapeHistory";
import { FC } from "react";

interface GrapeHistoryCardProps {
  history: GrapeHistory;
  versionNumber: number;
}

const GrapeHistoryCard: FC<GrapeHistoryCardProps> = ({ history, versionNumber }) => {
  return (
    <Card
      sx={{
        p: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
      key={history.date.toString()}
    >
      <Typography color="text.primary" variant="h6">
        {`#${versionNumber}. ${history.userName}`}
      </Typography>

      <TextField
        value={JSON.stringify(
          history.grape,
          (k, v) => (v === undefined ? "undefined" : v),
          2
        )}
        label="Változások"
        variant="outlined"
        multiline
        fullWidth
        InputProps={{ readOnly: true }}
      />

      <Typography color="text.primary" variant="body2">
        {new Date(history.date.seconds * 1000).toLocaleString("hu")}
      </Typography>
    </Card>
  );
};

export default GrapeHistoryCard;
