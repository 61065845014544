import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Roles } from "hooks/useRights";
import { FC, PropsWithChildren, memo } from "react";
import { getNameByPath, menuNamesAndRoutes } from "./DrawerComponent";
import Tooltip from "@mui/material/Tooltip";
import Link from "components/Link";

const getMenuRecordByPath = (path: string) => {
  const pathRoot = `/${path.split("/")[1]}`;
  const record = menuNamesAndRoutes.find((row) => row.path === pathRoot);
  return record;
};

const DrawerListItem: FC<PropsWithChildren<{
  pathname: string;
  path: string;
  hasRole: (role?: Roles) => boolean;
  isIconView?: boolean;
}>> = memo(({ pathname, path, children, hasRole, isIconView }) => {
  const menuRecord = getMenuRecordByPath(path);
  const menuItemName = getNameByPath(path);

  const isSelected = (path === "/" || pathname === "/posts/segitseg/segitseg")
  ? pathname === path
  : pathname.startsWith(path);

  return (
    <>
      {hasRole(menuRecord?.role) ? (
        <Tooltip title={isIconView ? menuItemName : ""} placement="right">
          <ListItem
            selected={isSelected}
            component={Link}
            to={path}
            sx={{
              color: "#333333",
              padding: isIconView ? "8px 2px" : "8px 16px",
              width: isIconView ? "30px" : "100%",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: isIconView ? "30px" : undefined,
              }}
            >
              {children}
            </ListItemIcon>
            {!isIconView && <ListItemText primary={menuItemName} />}
          </ListItem>
        </Tooltip>
      ) : null}
    </>
  );
});

export default DrawerListItem;
