import React, { useState, useEffect } from "react";
import { get, ref } from "firebase/database";
import { realtime } from "utils/firebase";

const MostViewedGrapes: React.FC = () => {
  const [mostViewed, setMostViewed] = useState<any[]>([]);

  useEffect(() => {
    const grapeRef = ref(realtime, "grapeCount");
    get(grapeRef)
      .then((snapshot) => {
        const data: any = snapshot.val();
        if (data) {
          // Convert object to array and sort by viewCount
          const sortedGrapes = Object.entries(data)
            .map(([key, value]: [string, any]) => ({
              name: key,
              viewCount: value.viewCount,
            }))
            .sort((a, b) => b.viewCount - a.viewCount)
            .slice(0, 50);
          setMostViewed(sortedGrapes);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      {mostViewed.map((grape, index) => (
        <div key={grape.name}>{`${index + 1} ${grape.name} ${grape.viewCount}`}</div>
      ))}
    </div>
  );
};

export default MostViewedGrapes;
