import React, { PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { SxProps } from "@mui/system";

export type CircularProgressOverlayProps = {
  isLoading: boolean;
  circleColor?: string;
  fullWidth?: boolean;
  style?: React.CSSProperties;
};

const CircularProgressOverlay: React.FC<PropsWithChildren<CircularProgressOverlayProps>> = ({
  isLoading,
  children,
  circleColor,
  fullWidth,
  style,
}) => {
  const sx: { [key: string]: SxProps } = {
    container: {
      display: "flex",
      position: "relative",
      flexDirection: fullWidth ? "column" : "row",
    },
    progress: {
      display: "flex",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: "0",
      left: "0",
      background: " white",
      opacity: 0.7,
      zIndex: 10000,
    },
  };

  return (
    <Box
      sx={sx.container}
      style={{ ...style }}
      component="div"
    >
      {children}
      {isLoading && (
        <Box sx={sx.progress} component="div">
          <CircularProgress size={16} style={{ color: circleColor }} />
        </Box>
      )}
    </Box>
  );
};

CircularProgressOverlay.defaultProps = {
  circleColor: "gray",
};

export default CircularProgressOverlay;
