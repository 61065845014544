import React, { FC, MouseEvent } from "react";
import { ButtonProps } from "@mui/material/";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Container from "@mui/material/Container";
import Paper, { PaperProps } from "@mui/material/Paper";
import CircularProgressOverlay from "components/common/CircularProgressOverlay/CircularProgressOverlay";

export interface ConfirmDialogProps extends DialogProps {
  title: string;
  actionButtonText?: string;
  cancelButtonText?: string;
  open: boolean;
  onCancel?: () => void;
  onConfirm?: (e: MouseEvent) => void;
  hideCancel?: boolean;
  formId?: string;
  actionProgress?: boolean;
}
const PaperComponent: FC<PaperProps> = ({ children, ...props }: PaperProps) => {
  return (
    <Paper {...props}>
      <Container>{children}</Container>
    </Paper>
  );
};
const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  children,
  onCancel,
  onConfirm,
  actionButtonText = "Rendben",
  cancelButtonText = "Mégsem",
  hideCancel,
  formId,
  actionProgress,
  ...other
}) => {
  const getConfirmButtonProperties = (): Partial<ButtonProps> => {
    return formId ? { form: formId, type: "submit" } : {};
  };

  return (
    <Dialog fullWidth maxWidth="lg" {...other} onClose={onCancel} PaperComponent={PaperComponent}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
          {children}
      </DialogContent>
      <DialogActions>
        {!hideCancel && (
          <Button
            variant="outlined"
            onClick={onCancel}
            color="info"
            size="small"
          >
            {cancelButtonText}
          </Button>
        )}
        <CircularProgressOverlay
          style={{ marginLeft: "10px" }}
          isLoading={!!actionProgress}
        >
          <Button
            onClick={onConfirm}
            {...getConfirmButtonProperties()}
            variant="contained"
            color="primary"
            size="small"
            autoFocus
          >
            {actionButtonText}
          </Button>
        </CircularProgressOverlay>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
