import React, { FC } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import Link from "./Link";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import { useStateValue } from "utils/StateProvider";
import { useConfirmDialog } from "./common/ConfirmDialog/useConfirmDialog";
import ConfirmDialog from "./common/ConfirmDialog/ConfirmDialog";
import NewListDialog, { NewListForm, newListFromId } from "./NewListDialog";
import slugify from "slugify";
import { collection, doc, setDoc } from "@firebase/firestore";
import { db } from "../utils/firebase";
import { useNavigate } from "react-router";
import { slugConfig } from "utils/general";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Helmet } from "react-helmet";
import { useUserGrapeLists } from "data/user";

const ListsPage: FC = () => {
  const { authUser } = useStateValue()!;
  const userId = authUser?.uid ?? "";
  const [dialogProps, setDialogProps, setDialogOpen] = useConfirmDialog();
  const { data: userGrapeLists } = useUserGrapeLists(userId, !!userId);
  const navigate = useNavigate();

  if (!userGrapeLists || !userId) return <CircularProgress />;

  const handleNewListPressed = () => {
    setDialogProps({
      title: "Új lista",
      formId: newListFromId,
      onConfirm: () => {},
    });
    setDialogOpen(true);
  };

  const handleNewListFormSave = (data: NewListForm) => {
    setDialogProps({ ...dialogProps, actionProgress: true });
    let slug = slugify(data.name, slugConfig);

    const coll = collection(db, "users", userId, "lists");
    const ref = doc(coll, slug);
    setDoc(ref, { name: data.name, grapeArray: [], public: false }).then(() => {
      setDialogProps({ ...dialogProps, actionProgress: false });
      setDialogOpen(false);
      navigate(`/lista/${userId}/${slug}`);
    });
  };
  const listIds = userGrapeLists.map((list) => list.id);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
      }}
      id="lists-root"
      component="div"
    >
      <Helmet>
        <title>Listáim</title>
      </Helmet>
      <Card
        sx={{
          maxWidth: { xs: "800px" },
          width: "100%",
          background: "white",
          alignSelf: "center",
          flex: "1 1 auto",
          position: "relative",
          padding: "10px",
          marginBottom: "20px",
        }}
        component="div"
      >
        <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <Grid
            item
            xs={12}
            sx={{ textAlign: "end", marginTop: "20px", marginRight: "20px", flex: "0 0 auto" }}
          >
            <Button
              onClick={handleNewListPressed}
              color="primary"
              size="small"
              startIcon={<AddIcon />}
              variant="contained"
            >
              Új lista
            </Button>
          </Grid>
          <Typography sx={{flex: "0 0 auto"}} variant="h6" noWrap component="div">
            Listáim
          </Typography>
          <Typography variant="body1" component="div">
            Létrehozhatunk magunknak szőlő listákat. Ez lehet a cserére szánt
            szőlőnk, kívánságaink vagy újonnan beszerzett szőlővesszőink. Ezeket
            más nem látja, csak ha nyivánosra álítjuk és megosztjuk mással a
            lista linkjét.
          </Typography>
          <Box
            sx={{
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              flex: "1 1 auto",
            }}
          >
            <List>
              {userGrapeLists.map((list) => (
                <ListItem
                  key={list.name}
                  component={Link}
                  to={`/lista/${userId}/${list.id}`}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <ListAltIcon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          component="div"
                          variant="body1"
                          color="text.primary"
                        >
                          {list.name}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          component="div"
                          variant="body1"
                          color="text.secondary"
                        >
                          {`${list.grapeArray?.length ?? 0} db. szőlő`}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
          <ConfirmDialog {...dialogProps}>
            <NewListDialog
              onFormSave={handleNewListFormSave}
              listIds={listIds}
            />
          </ConfirmDialog>
        </Box>
      </Card>
    </Box>
  );
};

export default ListsPage;
