import { useEffect, useState } from "react";
import {
  get,
  ref,
  set,
} from "firebase/database";
import { realtime } from "utils/firebase";

type UseViewCounterType = (
  viewType: "blogCount" | "grapeCount",
  slug?: string
) => number | undefined;

export const useViewCounter: UseViewCounterType = (viewType, slug) => {
  const [viewCount, setViewCount] = useState<number>();

  useEffect(() => {
    if (slug) {
      const blogPostRef = ref(realtime, `${viewType}/${slug}`);
      get(blogPostRef)
        .then((snapshot) => {
          let currentViewCount;
          if (snapshot.exists()) {
            currentViewCount = snapshot.val().viewCount || 0;
            currentViewCount++;
          } else {
            currentViewCount = 1;
          }
          set(blogPostRef, { viewCount: currentViewCount });
          setViewCount(currentViewCount);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [slug, viewType]);

  return viewCount;
};

type MostViewedBlogsType = (
  viewType: "blogCount" | "grapeCount",
  limit?: number
) => { slug: string; count: number }[] | undefined;

export const useMostViewed: MostViewedBlogsType = (viewType, limit = 10) => {
  const [mostViewed, setMostViewed] =
    useState<{ slug: string; count: number }[]>();

  useEffect(() => {
    const viewRef = ref(realtime, viewType);
    get(viewRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          let viewData: { slug: string; count: number }[] = [];
          snapshot.forEach((childSnapshot) => {
            viewData.push({
              slug: childSnapshot.key ?? "",
              count: childSnapshot.val().viewCount,
            });
          });
          viewData.sort((a, b) => b.count - a.count);
          viewData = viewData.slice(0, limit);
          setMostViewed(viewData);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [viewType, limit]);

  return mostViewed;
};
