import React from "react";
import Link from "./Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Helmet } from "react-helmet";

function NotFoundPage() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Typography variant="h3" component="h1" gutterBottom>
        404 - Az oldal nem található
      </Typography>
      <Typography style={{marginBottom: "40px"}} variant="body1" component="p" gutterBottom>
        Sajnáljuk, de nincs ilyen oldal.
        Lehet hogy megváltozott az elérhetősége, vagy soha nem is volt.
      </Typography>
      <Button component={Link} to="/" variant="contained" color="primary">
        Vissza a főoldalra
      </Button>
    </Box>
  );
}

export default NotFoundPage;
