import React, { forwardRef, useImperativeHandle } from "react";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, Resolver, useForm } from "react-hook-form";
import { getTextFieldProps } from "utils/formUtils";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import { useSetUser, SetupUser, User } from "data/user";
import { useQueryClient } from "@tanstack/react-query"
;
import useLogErrors from "data/useLogErrors";
import { Helmet } from "react-helmet";
import { counties, countries } from "./SetupPage";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";

interface UserDataFormProps {
  userData: User;
  userId: string;
}

export interface UserDataFormHandles {
  handleSubmit: () => void;
}

const getSetupSchema = (): yup.SchemaOf<SetupUser> => {
  return yup.object({
    country: yup.string().notRequired(),
    county: yup.string().notRequired(),
    city: yup.string().notRequired(),
    aboutMe: yup.string().notRequired(),
    contactInfo: yup.string().notRequired(),
    public: yup.boolean().notRequired(),
    messagingDisabled: yup.boolean().notRequired(),
  });
};

export const setupformId = "setupformId";

const UserDataForm = forwardRef<UserDataFormHandles, UserDataFormProps>(
  ({ userData, userId }, ref) => {
    const { mutateAsync: setUser } = useSetUser(userId);
    const { logErrors } = useLogErrors();

    const { register, formState, handleSubmit, control, watch } =
      useForm<SetupUser>({
        mode: "onChange",
        resolver: yupResolver(getSetupSchema()) as Resolver<SetupUser>,
        defaultValues: {
          country: userData?.country ?? "",
          county: userData?.county ?? "",
          city: userData?.city ?? "",
          aboutMe: userData?.aboutMe ?? "",
          contactInfo: userData?.contactInfo ?? "",
          public: userData?.public,
          messagingDisabled: userData?.messagingDisabled ?? false,
        },
      });
    const country = watch("country");

    const handleFormSubmitPressed = (data: SetupUser) => {
      let dataDefined: SetupUser = { ...data };

      if (data.country === countries[0]) {
        dataDefined.country = "";
        dataDefined.county = "";
      }

      const preservingData: Partial<User> = {};

      if (userData.created) {
        preservingData.created = userData.created;
      }

      const newUser = {
        ...preservingData,
        ...dataDefined,
        displayName: userData.displayName,
        photoURL: userData.photoURL,
        myGrapes: userData.myGrapes ?? {},
      };

      setUser(newUser)
        .then(() => {
          toast("Elmentve");
        })
        .catch((e: any) => {
          logErrors(
            JSON.stringify({ ...e, toString: "" + e }),
            JSON.stringify(
              newUser,
              (k, v) => (v === undefined ? "undefined" : v),
              2
            )
          );
          toast("Nem sikerült a mentés");
        });
    };

    useImperativeHandle(ref, () => ({
      handleSubmit: () => handleSubmit(handleFormSubmitPressed)(),
    }));

    return (
      <form
        id={setupformId}
        onSubmit={handleSubmit(handleFormSubmitPressed)}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Helmet>
          <title>Beállítás</title>
        </Helmet>
        <Typography variant="h6" noWrap component="div">
          Személyes
        </Typography>
        <FormControlLabel
          style={{ marginTop: "8px" }}
          control={
            <Controller
              name="public"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Tooltip title={"Nyilvános"}>
                  <Checkbox
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    inputRef={ref}
                  />
                </Tooltip>
              )}
            />
          }
          label="Nyilvános (más bejelentkezett felhasználó láthat a gazdák listában)"
        />
        <TextField
          {...getTextFieldProps(false, "aboutMe", register, formState, true)}
          label="Magamról"
          multiline
        />
        <Typography variant="h6" noWrap component="div">
          Elérhetőség
        </Typography>
        <Typography variant="body1" component="div">
          Adj meg egy elérhetőséget, ha szeretnéd, hogy mások könnyebben
          elérhessenek. Ez lehet email vagy telefon vagy akármi más. Csak a
          bejelentkezett felhasználók fogják látni. Amennyiben nem adsz meg
          semmit, belső üzenetet tudnak csak küldeni, de arról nem kapsz
          értesítést.
        </Typography>
        <TextField
          {...getTextFieldProps(
            false,
            "contactInfo",
            register,
            formState,
            true
          )}
          label="Elérhetőség"
          placeholder="Pl.: email: abc@mail.hu, tel: +36 20 1234567, stb."
        />
        <FormControlLabel
          style={{ marginTop: "8px" }}
          control={
            <Controller
              name="messagingDisabled"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Tooltip title={"Üzenetek letiltva"}>
                  <Checkbox
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    inputRef={ref}
                  />
                </Tooltip>
              )}
            />
          }
          label="Üzenetek letiltva (ilyenkor más gazdák nem tudnak neked üzenetet küldeni)"
        />
        <Typography variant="h6" noWrap component="div">
          Birtok
        </Typography>
        <Typography variant="body1" component="div">
          Add meg a szőlőid helyét, hogy megismerhesd a környékeden élő
          szőlőtermesztőket.
        </Typography>
        <FormControl component="fieldset">
          <InputLabel id="country-label">Ország</InputLabel>
          <Controller
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  label="Ország"
                  labelId="country-label"
                  autoWidth
                >
                  {countries.map((country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
            name={"country"}
            control={control}
          />
        </FormControl>

        {country === "Magyarország" && (
          <FormControl component="fieldset">
            <InputLabel>Megye</InputLabel>
            <Controller
              render={({ field }) => (
                <Select
                  {...field}
                  label="Megye"
                  labelId="county-label"
                  autoWidth
                >
                  {counties.map((county) => (
                    <MenuItem key={county} value={county}>
                      {county}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name={"county"}
              control={control}
            />
          </FormControl>
        )}
        <TextField
          {...getTextFieldProps(false, "city", register, formState, true)}
          label="Település"
        />
      </form>
    );
  }
);

export default UserDataForm;
