import React, { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import { useGetShortGrapes, ShortGrape } from "data/grape";
import { deleteField } from "@firebase/firestore";

import GrapeListItem from "./GrapeListItem";
import { useStateValue } from "utils/StateProvider";
import { User, useGetUser, useUpdateUser } from "data/user";
import GrapeActionItem from "components/common/GrapeEditItem";
import { TransitionGroup } from "react-transition-group";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import Collapse from "@mui/material/Collapse";
import CircularProgress from "@mui/material/CircularProgress";
import CircularProgressOverlay from "./common/CircularProgressOverlay/CircularProgressOverlay";
import { useMeasure } from "react-use";
import AddGrapeFromSearch from "./common/AddGrapeFromSearch";
import CancelIcon from "@mui/icons-material/Cancel";
import { Helmet } from "react-helmet";

const MyGrapes: FC = () => {
  const { authUser } = useStateValue()!;
  const userId = authUser?.uid ?? "dummy";
  const { data: userData } = useGetUser(userId, !!authUser);
  const [editMode, setEditMode] = React.useState(false);
  const [editedMyGrapes, setEitedMyGrapes] = React.useState<User["myGrapes"]>();
  const { mutateAsync: updateUser, isPending: saveUserLoading } =
    useUpdateUser(userId);

  const { data: grapes } = useGetShortGrapes();
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  const handleEditPressed = () => {
    const myGrapes = userData!.myGrapes ?? {};
    setEitedMyGrapes({ ...myGrapes });
    setEditMode(true);
  };

  const handleSavePressed = async () => {
    if (userData) {
      const updatePayload = { ...userData, myGrapes: { ...editedMyGrapes } };

      Object.keys(userData.myGrapes ?? {}).forEach((key) => {
        if (!(key in (editedMyGrapes ?? {}))) {
          (updatePayload.myGrapes as any)[key] = deleteField();
        }
      });

      await updateUser(updatePayload);
      setEditMode(false);
    }
  };

  if (!(userData && grapes)) return <CircularProgress />;

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
      component="div"
    >
      <Helmet>
        <title>Szőlőim</title>
      </Helmet>
      <Card
        sx={{
          maxWidth: { xs: "800px" },
          width: "100%",
          background: "white",
          alignSelf: "center",
          flex: "1 1 auto",
          position: "relative",
          padding: "10px",
          marginBottom: "20px",
        }}
        component="div"
        ref={ref}
      >
        <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <Typography
            style={{ flex: "0 0 auto" }}
            variant="h6"
            noWrap
            component="div"
          >
            Szőlőim
          </Typography>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "20px",
              marginRight: "20px",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            {!editMode ? (
              <Button
                onClick={handleEditPressed}
                color="primary"
                size="small"
                startIcon={<EditIcon />}
                variant="contained"
              >
                Szerkesztés
              </Button>
            ) : (
              <>
                <Button
                  sx={{ alignSelf: "end" }}
                  color="primary"
                  size="small"
                  startIcon={<CancelIcon />}
                  variant="outlined"
                  onClick={() => setEditMode(false)}
                >
                  Mégsem
                </Button>
                <CircularProgressOverlay
                  style={{ marginTop: "10px", alignSelf: "end" }}
                  isLoading={saveUserLoading}
                >
                  <Button
                    onClick={handleSavePressed}
                    color="primary"
                    size="small"
                    startIcon={<SaveIcon />}
                    variant="contained"
                  >
                    Elment
                  </Button>
                </CircularProgressOverlay>
              </>
            )}
          </Grid>
          <Box
            sx={{
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              flex: "1 1 auto",
            }}
          >
            {!editMode && userData.myGrapes && (
              <List
                sx={{
                  width: "100%",
                  maxWidth: { xs: "600px" },
                  bgcolor: "background.paper",
                }}
              >
                {Object.keys(userData.myGrapes)
                  .map((id) => [id, grapes[id]])
                  .sort((a, b) =>
                    (a[1] as ShortGrape).name.localeCompare(
                      (b[1] as ShortGrape).name,
                      "hu"
                    )
                  )
                  .map(([grapeId, grape]) => (
                    <GrapeListItem
                      grape={grape as ShortGrape}
                      key={grapeId as string}
                      grapeId={grapeId as string}
                      width={width}
                    />
                  ))}
              </List>
            )}

            {editMode && editedMyGrapes && (
              <TransitionGroup component="div">
                {Object.keys(editedMyGrapes)
                  .map((id) => [id, grapes[id]])
                  .sort((a, b) =>
                    (a[1] as ShortGrape).name.localeCompare(
                      (b[1] as ShortGrape).name,
                      "hu"
                    )
                  )
                  .map(([grapeId, grape]) => (
                    <Collapse key={grapeId as string} timeout={300}>
                      <GrapeActionItem
                        grape={grape as ShortGrape}
                        id={grapeId as string}
                        onGrapeAction={(id: string) => {
                          let grapeDelete = { ...editedMyGrapes };
                          delete grapeDelete[id];
                          setEitedMyGrapes({ ...grapeDelete });
                        }}
                      >
                        <DeleteIcon />
                      </GrapeActionItem>
                    </Collapse>
                  ))}
              </TransitionGroup>
            )}

            {editMode && (
              <AddGrapeFromSearch
                onGrapeSelection={(id: string) => {
                  setEitedMyGrapes({ ...editedMyGrapes, [id]: true });
                }}
                grapes={grapes}
              />
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default MyGrapes;
