import { FC } from "react";
import Button from "@mui/material/Button";
import { db } from "../../utils/firebase";
import {
  doc,
  setDoc,
  collectionGroup,
  query,
  getDocs,
} from "@firebase/firestore";
import { UserGrapeList, UserGrapeListElement } from "data/user";

const setList = (grapeList: UserGrapeList, userId: string, listId: string) => {
  const docRef = doc(db, "users", userId, "lists", listId);
  setDoc(docRef, grapeList);
};

/*const getList = async (db: Firestore, userId: string, listId: string) => {
  const docRef = doc(db, "users", userId, "lists", listId);
  const docSnap = await getDoc(docRef);

  return docSnap;
};*/

const getAllList = async () => {
  const lists = query(collectionGroup(db, "lists"));
  const querySnapshot = await getDocs(lists);

  return querySnapshot;
};

const CovertGrapeList: FC = () => {
  const handleConvert = async () => {
    const allList = await getAllList();

    allList.forEach((doc: any) => {
      let grapeArray: UserGrapeListElement[];

      const userId = doc._key.path.segments[6];
      const listId = doc.id;

      //const listRef = await getList(db, userId, listId);

      if (doc) {
        const _list = doc.data() as UserGrapeList;
        // @ts-ignore
        grapeArray = Object.entries(_list.grapes).map(([grapeId, note]) => ({
          grapeId,
          note,
        }));

        setList({ ..._list, grapeArray }, userId, listId);
      }

      console.log(doc._key.path.segments[6], doc.id, " => ", doc.data());
    });
  };

  return (
    <Button
      onClick={handleConvert}
      color="info"
      size="small"
      variant="outlined"
      disabled
    >
      Grape list tömbbé konvertálás
    </Button>
  );
};

export default CovertGrapeList;
