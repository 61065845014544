import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import {
  doc,
  getDoc,
  collection,
  setDoc,
  updateDoc,
  deleteField,
} from "@firebase/firestore";
import { db } from "../utils/firebase";

export interface TagDocument {
  name: string;
  description?: string;
  namePostfix?: string;
  categoryId: string;
  countryId?: number; // Optional country ID for breeder tags
}

export interface TagCategory {
  id: string;
  name: string;
}

export interface Tag extends TagDocument {
  id: number;
}

export const TAGS = "TAGS";
export const BREEDER_CATEGORY_ID = "1";
export const COUNTRY_CATEGORY_ID = "0";

const fetchTags = async (): Promise<Tag[]> => {
  const docRef = doc(db, "pages", "tags");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return Object.entries(docSnap.data()).map(([id, data]) => ({
      id: parseInt(id),
      ...(data as TagDocument),
    }));
  } else {
    throw new Error("Tags not found");
  }
};

export const useGetTags = (): UseQueryResult<Tag[], Error> => {
  return useQuery<Tag[], Error>({
    queryKey: [TAGS],
    queryFn: fetchTags,
  });
};

export const useCreateTag = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newTag: Tag) => {
      const tagsRef = doc(collection(db, "pages"), "tags");
      const { id, ...restOfData } = newTag;
      const tagData = {
        [newTag.id.toString()]: {
          ...restOfData,
        },
      };
      await setDoc(tagsRef, tagData, { merge: true });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TAGS] });
    },
  });
};

export const useDeleteTag = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (tagId: number) => {
      const tagsRef = doc(collection(db, "pages"), "tags");
      await updateDoc(tagsRef, {
        [tagId.toString()]: deleteField(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TAGS] });
    },
  });
};

export const useModifyTag = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (tag: Tag) => {
      const tagsRef = doc(collection(db, "pages"), "tags");
      const tagData = {
        [tag.id.toString()]: {
          name: tag.name,
          description: tag.description,
          namePostfix: tag.namePostfix,
          categoryId: tag.categoryId,
          ...(typeof tag.countryId !== "undefined" && {
            countryId: tag.countryId,
          }),
        },
      };
      await updateDoc(tagsRef, tagData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TAGS] });
    },
  });
};

export const useGetTagCategories = (): UseQueryResult<TagCategory[], Error> => {
  return useQuery<TagCategory[], Error>({
    queryKey: ["TAG_CATEGORIES"],
    queryFn: async (): Promise<TagCategory[]> => {
      const docRef = doc(db, "pages", "tagCategories");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return Object.entries(docSnap.data()).map(([id, data]) => ({
          id,
          ...(data as Omit<TagCategory, "id">),
        }));
      } else {
        throw new Error("Tag categories not found");
      }
    },
  });
};
