import React, { FC } from "react";
import Box from "@mui/material/Box";
import { useSpring, animated } from "react-spring";

interface RipeningChartProps {
  from?: number;
  to?: number;
  background?: string;
  fill?: string;
  small?: boolean;
  disableAnimation?: boolean;
}

const factor = 3;
const months = ["július", "augusztus", "szeptember", "október"];
const monthEnds = [0, 30, 61, 91, 122].map((i) => i * factor);

const textY = 15;
const lineY = 30;
const pale = 3;
const stripeWidth = 12;

const defaultBackground = "rgba(43,31,84,0.54)";
const defaultFill = "white";

const height = 50;

const RipeningChartNew: FC<RipeningChartProps> = ({
  from,
  to,
  background = defaultBackground,
  fill = defaultFill,
  small,
  disableAnimation,
}) => {
  const createFromTo = () => {
    if (from === undefined) return [undefined, undefined];
    const _from = from - 70;
    const _to = (to ?? 0) - 70;
    return to ? [_from, _to] : [_from - 4, _from + 4];
  };

  const [x1, x2] = createFromTo();

  const props = useSpring({
    from: { x2: x1 },
    to: { x2 },
    immediate: disableAnimation,
  });

  const label = "Érési idő: " + (to ? `${from} - ${to} nap` : `${from} nap`);

  return (
    <Box
      sx={{
        flex: 1, 
        display: "flex",
        height: small ? "25px" : "50px", 
      }}
      component="span"
    >
      <svg
        width="100%" 
        height="100%" 
        viewBox={`0 0 ${122 * factor} ${height}`} 
        preserveAspectRatio="none" 
        aria-label={label}
      >
        <title>{label}</title>
        <Box
          sx={{ fill: background }}
          width={122 * factor}
          height={height}
          rx={5}
          component="rect"
        />
        {months.map((month, index) => (
          <React.Fragment key={month}>
            <text
              x={(monthEnds[index] + monthEnds[index + 1]) / 2}
              y={textY}
              textAnchor="middle"
              fill={fill}
              aria-hidden={true}
            >
              {month}
            </text>
            <line
              x1={monthEnds[index + 1]}
              x2={monthEnds[index + 1]}
              y1={0}
              y2={height}
              stroke={fill}
            />
          </React.Fragment>
        ))}
        {from !== undefined && x1 && props.x2 &&(
          <>
            <animated.line
              x1={(x1 - pale) * factor}
              x2={props.x2.to((x2: number) => (x2 + pale) * factor)}
              y1={lineY}
              y2={lineY}
              stroke={fill}
              strokeWidth={stripeWidth}
              style={{ opacity: 0.4 }}
            />
            <animated.line
              x1={x1 * factor}
              x2={props.x2.to((x2: number) => x2 * factor)}
              y1={lineY}
              y2={lineY}
              stroke={fill}
              strokeWidth={stripeWidth}
            />
          </>
        )}
      </svg>
    </Box>
  );
};

export default RipeningChartNew;
