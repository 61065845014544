import { useState } from "react";
import { Event } from "./Timeline";
import { Card, CardContent, Typography } from "@mui/material";
import { Waypoint } from "react-waypoint";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ArticleIcon from "@mui/icons-material/Article";
import { FarmerIconMui, GrapeIconMui } from "components/common/Icons";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Roles, useRights } from "hooks/useRights";
import Link from "components/Link";

type TimelineEventProps = {
  event: Event;
  onEventDelete: (timestamp: string) => void;
};

export const getTimelineProperties = (event: Event) => {
  let title = "";
  let timelineIcon;
  let eventIcon;
  let url;
  const size = "100";

  switch (event.type) {
    case "create-post":
      title = "Blogbejegyzés";
      timelineIcon = <ArticleIcon />;
      eventIcon = <ArticleIcon style={{ fontSize: `${size}px` }} />;
      url = `/posts/${event.parentId}/${event.refId}`;
      break;
    case "post-comment":
      title = "Hozzászólás";
      timelineIcon = <ChatBubbleOutlineIcon />;
      eventIcon = <ArticleIcon style={{ fontSize: `${size}px` }} />;
      url = `/posts/${event.parentId}/${event.refId}#comments`;
      break;
    case "create-grape":
      title = "Szőlőfajta";
      timelineIcon = <GrapeIconMui />;
      eventIcon = <GrapeIconMui style={{ fontSize: `${size}px` }} />;
      url = `szolo/${event.refId}`;
  }
  return { title, timelineIcon, eventIcon, url };
};

const TimelineEvent: React.FC<TimelineEventProps> = ({
  event,
  onEventDelete,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { hasRole } = useRights();

  const onEnterViewport = () => {
    setIsVisible(true);
  };

  const { title, timelineIcon, eventIcon, url } = getTimelineProperties(event);

  return (
    <Waypoint onEnter={onEnterViewport}>
      <div className={`timeline-event ${isVisible ? "visible" : ""}`}>
        <div className="timeline-icon">{timelineIcon}</div>
        <Link style={{flex: "1 1 auto"}} to={url} className="timeline-card-link">
          <Card className="timeline-card">
            <CardContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">{event.title}</Typography>
                {hasRole(Roles.EDITOR) && (
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onEventDelete(event.timestamp);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
              <Typography variant="body2" color="textSecondary">
                {`${title}${event.userName ? `, ${event.userName}` : ""}`}
              </Typography>
              {eventIcon}
              <Typography variant="body1">{event.description}</Typography>
              <Typography variant="body2" color="textSecondary">
                {new Date(Number(event.timestamp)).toLocaleString("hu")}
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </div>
    </Waypoint>
  );
};

export default TimelineEvent;
