import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Timestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "utils/firebase";

export type NotificationType = "message" | "event";

export interface NotificationBase {
  unread: boolean;
  lastUpdatedAt: Timestamp;
}

export interface NotificationDetail extends NotificationBase {
  type: "event";
  eventType: string;
  refId: string;
  parentId: string;
  title: string;
}

export type NotificationWithId = NotificationDetail & { id: string };

const fetchNotifications = async (
  userId: string
): Promise<NotificationWithId[]> => {
  const userDocRef = doc(db, "users", userId, "interactions", "notifications");
  const docSnapshot = await getDoc(userDocRef);

  if (!docSnapshot.exists()) {
    return [];
  }

  const notifications = docSnapshot.data();
  const notificationsList: NotificationWithId[] = [];

  for (const notificationId in notifications) {
    const notification = notifications[notificationId];
    const notificationWithId = { ...notification, id: notificationId };
    notificationsList.push(notificationWithId);
  }

  return notificationsList.sort(
    (a, b) => b.lastUpdatedAt.toMillis() - a.lastUpdatedAt.toMillis()
  );
};

export const useGetNotifications = (userId: string) => {
  return useQuery<NotificationWithId[], Error>({
    queryKey: ["notifications", userId],
    queryFn: () => fetchNotifications(userId),
  });
};

export const useSetNotificationRead = (userId: string) => {
  const queryClient = useQueryClient();

  const updateNotification = useMutation({
    mutationFn: async (notificationId: string) => {
      const userDocRef = doc(
        db,
        "users",
        userId,
        "interactions",
        "notifications"
      );

      await updateDoc(userDocRef, {
        [`${notificationId}.unread`]: false,
      });

      return notificationId;
    },

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["notifications", userId] });
    },
  });

  return updateNotification;
};
