import { useRights } from "hooks/useRights";
import { FC, useEffect, useState } from "react";
import ConversationsList from "./ConversationsList";
import { Theme, useMediaQuery } from "@mui/material";
import ConversationDetail from "./ConversationDetail/ConversationDetail";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import {
  UserConversation,
  useGetConversation,
  useUserConversationsListener,
} from "data/messages-realtime";
import { useGetShortUsers } from "data/user";
import { Helmet } from "react-helmet";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  alignSelf: "center",
  background: "white",
  width: "100%",
  maxWidth: "1200px",
  borderRadius: "4px",
  overflow: "hidden",
  boxShadow:
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
}));

const Conversations = styled("div")({
  overflowY: "auto",
  margin: "0 10px",
});

const ConversationPanel = styled("div")({
  flex: "1 1 auto",
});

const MessagePage: FC = () => {
  const { conversationId: conversationIdFromUrl } = useParams();
  const navigate = useNavigate();
  const { authUser } = useRights();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const [selectedConversationId, setSelectedConversationId] = useState<
    string | null
  >(conversationIdFromUrl || null);
  const { data: selectedConversation, isLoading: selectedConvLoading } =
    useGetConversation(selectedConversationId ?? "");
  const [selectedUserConversation, setSelectedUserConversation] =
    useState<UserConversation | null>(null);
  const { userConversations } = useUserConversationsListener(
    authUser?.uid ?? ""
  );
  const { data: users } = useGetShortUsers();

  useEffect(() => {
    setSelectedConversationId(conversationIdFromUrl || null);
  }, [conversationIdFromUrl]);

  useEffect(() => {
    if (selectedConversationId && userConversations) {
      setSelectedUserConversation(
        userConversations.conversations[selectedConversationId] ?? null
      );
    }
  }, [selectedConversationId, userConversations]);

  const handleClose = () => {
    setSelectedConversationId(null);
    navigate("/uzenetek"); // Navigate back to just /messages when closing the conversation
  };

  return (
    <Container>
      <Helmet>
        <title>Üzenetek</title>
      </Helmet>
      {authUser ? (
        <Conversations style={{ flex: isSmall ? "1 1 auto" : "0 0 500px" }}>
          <ConversationsList
            userId={authUser.uid}
            selectedConversationId={selectedConversationId}
            selectedConversation={selectedConversation}
            userConversations={userConversations}
            users={users}
          />
        </Conversations>
      ) : (
        <LinearProgress />
      )}
      {authUser &&
        selectedConversationId &&
        (isSmall ? (
          <Dialog
            fullScreen
            open={!!selectedConversationId}
            onClose={handleClose}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <AppBar>
                <Toolbar>
                  <span style={{ flexGrow: 1 }}>Beszélgetés</span>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>

              <ConversationDetail
                userId={authUser.uid}
                conversationId={selectedConversationId}
                selectedUserConversation={selectedUserConversation}
                selectedConversation={selectedConversation}
                isSmall={true}
                users={users}
              />
            </Box>
          </Dialog>
        ) : (
          <ConversationPanel>
            <ConversationDetail
              key={selectedConversationId}
              userId={authUser.uid}
              conversationId={selectedConversationId}
              selectedUserConversation={selectedUserConversation}
              selectedConversation={selectedConversation}
              users={users}
            />
          </ConversationPanel>
        ))}
      {!selectedConversationId && !isSmall && (
        <Box
          sx={{
            flex: "1 1 auto",
            background: "#f2f2f2",
            margin: "10px",
            display: "flex", // Enable flex container
            alignItems: "center", // Center vertically
            justifyContent: "center", // Center horizontally
          }}
        >
          <Typography variant="h6">Nincs kiválasztott beszélgetés</Typography>
        </Box>
      )}
    </Container>
  );
};
export default MessagePage;
