import React, { FC, PropsWithChildren } from "react";
import CardMedia from "@mui/material/CardMedia";
import { Grape } from "data/grape";
import "./ImageCarousel.css";

interface ImageCarouselProps {
  imageUrl?: string;
  grape?: Grape;
}

const defaultImage = "/images/bg.jpg";

const ImageCarousel: FC<PropsWithChildren<ImageCarouselProps>> = ({
  grape,
  imageUrl,
  children,
}) => {
  return (
    <CardMedia
      className="printedImage"
      component="picture"
      sx={{
        height: { xs: 280, sm: 600 },
        width: "100%",
        backgroundColor: "#ececec",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div style={{ width: "100%", height: "100%" }}>
        <source media="(max-width: 600px)" srcSet={imageUrl ?? defaultImage} />
        <img
          style={{
            display: "block",
            margin: "0 auto",
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
          src={imageUrl || defaultImage}
          alt={
            grape
              ? `${grape.name}${grape.russianName ? " / " + grape.russianName : ""}`
              : ""
          }
        />
        {children}
      </div>
    </CardMedia>
  );
};

export default ImageCarousel;
