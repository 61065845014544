import { TextFieldProps } from "@mui/material/";
import { FormState, UseFormRegister } from "react-hook-form";
import get from "lodash/get";

export const getTextFieldProps = (
  disabled: boolean,
  name: string,
  register: UseFormRegister<any>,
  formState: FormState<any>,
  touched?: boolean,
  defaultHelperText?: string
): TextFieldProps => {
  const isTouched = touched === undefined ? get(formState.touchedFields, name) : touched;
  const showError = !!isTouched;
  const fieldError = get(formState.errors, name);

  return {
    fullWidth: true,
    color: "primary",
    disabled,
    ...register(name),  // Updated to spread register result
    error: !!fieldError,
    helperText: (showError && fieldError?.message as string) || defaultHelperText,
    autoComplete: "off",
  };
};

export const getHelperText = (error: any) => {
  if (Array.isArray(error)) {
    // Handle the case where error is an array
    return error.map((e) => e?.message).join(', ');
  } else {
    // Handle the case where error is a single object or null
    return error?.message || null;
  }
};

/* export const getCheckboxControllerProps = <T extends Record<string, any>>(
  name: keyof T,
  control: Control<T>,
  tooltip: string,
  disabled: boolean,
  indeterminate?: boolean
): ControllerProps<typeof Checkbox, keyof T> => {
  return {
    name,
    control,

    render: ({ field }) => {
      return (
        <Tooltip title={tooltip}>
          <Checkbox
            {...field}
            style={{ alignSelf: "center" }}
            disabled={disabled}
            color="primary"
            checked={field.value}
            onChange={(e) => field.onChange(e.target.checked)}
            indeterminate={indeterminate}
          />
        </Tooltip>
      );
    },
  };
}; */

export const errorMessage = (
  name: string,
  formState: FormState<any>,
  defaultHelperText = ""
): { error: boolean; helperText: string } => {
  const isTouched = get(formState.touchedFields, name);
  const showError = !!isTouched;
  const fieldError = get(formState.errors, name);

  return {
    error: showError && !!fieldError,
    helperText: (showError && fieldError?.message as string) || defaultHelperText,
  };
};

export const convertToDaysHoursMinutesSeconds = (
  secondsStr: string
): string => {
  let seconds = parseInt(secondsStr);
  if (isNaN(seconds)) {
    return "";
  }

  const days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  let result = "";

  if (days > 0) {
    result += days + " nap, ";
  }

  if (days > 0 || hours > 0) {
    result += hours + " ó, ";
  }

  if (days > 0 || hours > 0 || minutes > 0) {
    result += minutes + " p, ";
  }

  result += seconds + " mp";

  return result;
};
