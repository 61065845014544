import React, { FC } from "react";
import { useGetGrapesToEdited, ShortGrape, NEW_GRAPE_DURATION_DAYS } from "data/grape";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import { useMeasure } from "react-use";
import GrapeListItem from "./GrapeListItem";
import { Helmet } from "react-helmet";

const newGrapeTreshold = new Date();
newGrapeTreshold.setDate(newGrapeTreshold.getDate() - NEW_GRAPE_DURATION_DAYS);

const GrapesToEdited: FC = () => {
  const { data: grapesToEdited } = useGetGrapesToEdited();
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }} component="div">
      <Helmet>
        <title>Szerkesztendő Szőlők</title>
      </Helmet>
      <Card
        sx={{
          maxWidth: { xs: "800px" },
          width: "100%",
          background: "white",
          alignSelf: "center",
          flex: "1 1 auto",
          position: "relative",
          padding: "10px",
          marginBottom: "20px",
        }}
        component="div"
        ref={ref}
      >
        <Typography variant="h6" noWrap component="div">
          Szerkesztendő szőlők
        </Typography>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: "20px",
            marginRight: "20px",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <List
            sx={{
              width: "100%",
              maxWidth: { xs: "600px" },
              bgcolor: "background.paper",
            }}
          >
            {grapesToEdited &&
              Object.keys(grapesToEdited)
                .map((id) => [id, grapesToEdited[id]])
                .sort((a, b) =>
                  (a[1] as ShortGrape).name.localeCompare(
                    (b[1] as ShortGrape).name,
                    "hu"
                  )
                )
                .map(([grapeId, grape]) => (
                  <GrapeListItem
                    grape={grape as ShortGrape}
                    key={grapeId as string}
                    grapeId={grapeId as string}
                    width={width}
                    isNewGrape={
                      (grape as ShortGrape)?.created
                        ? new Date((grape as ShortGrape).created!.seconds * 1000) > newGrapeTreshold
                        : false
                    }
                  />
                ))}
          </List>
        </Grid>
      </Card>
    </Box>
  );
};

export default GrapesToEdited;
