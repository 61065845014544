import React, { FC } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { SxProps } from "@mui/system";
import RipeningChart from "./RipeningChartNew";
import { ShortGrape } from "data/grape";

import { PixelPerColumns } from "./GrapesPage/GrapesPage";
import GrapeAvatar from "./common/GrapeAvatar";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import Link from "./Link";

interface GrapeListItemProps {
  grape: ShortGrape;
  grapeId: string;
  sx?: SxProps;
  note?: string;
  width?: number;
  minWidth?: number;
  disableAnimation?: boolean;
  isNewGrape?: boolean;
  grow?: boolean;
}

const GrapeListItem: FC<GrapeListItemProps> = ({
  grape,
  grapeId,
  sx,
  note,
  width = PixelPerColumns,
  disableAnimation,
  isNewGrape,
  minWidth = 0,
  grow,
}) => {
  return (
    <ListItem
      key={grapeId}
      disablePadding
      sx={{
        ...sx,
        color: "black",
        maxWidth: `${width}px`,
        minWidth: `${minWidth}px`,
        flexGrow: grow ? 1 : 0,
      }}
      component={Link}
      to={`/szolo/${grapeId}`}
    >
      <ListItemButton sx={{ alignItems: { sx: "center" }, gap: "20px" }}>
        <Badge color="error" badgeContent="Új" invisible={!isNewGrape}>
          <GrapeAvatar grape={grape} grapeId={grapeId} />
        </Badge>
        <ListItemText
          primary={
            <Typography
              sx={{ display: "inline" }}
              component="div"
              variant="body1"
              color="text.primary"
            >
              {grape?.name ?? "Törlésre került szőlőfajta"}
            </Typography>
          }
          secondary={
            <>
              {!note && (grape?.additionalNames ?? "")}
              {grape?.ripeningFrom ? (
                <RipeningChart
                  from={grape.ripeningFrom!}
                  to={grape.ripeningTo}
                  fill="white"
                  small
                  disableAnimation={disableAnimation}
                />
              ) : (
                ""
              )}
              {note && note}
            </>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default GrapeListItem;
