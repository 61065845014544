import React from "react";

import IconButton from "@mui/material/IconButton";
import { Badge, Menu, MenuItem, Theme, useMediaQuery } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  NotificationWithId,
  useGetNotifications,
  useSetNotificationRead,
} from "data/notifications";
import Link from "components/Link";

interface NotificationsProps {
  userId: string;
}

const Notifications: React.FC<NotificationsProps> = ({ userId }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { data: notifications } = useGetNotifications(userId);
  const { mutateAsync: setNotificationRead } = useSetNotificationRead(userId);
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function getDisplayParams(notification: NotificationWithId) {
    let url = "";
    let text = "";
    let updateUnreadAfterClick = false;
    switch (notification.type) {
      case "event":
        url = `/posts/${notification.parentId}/${notification.refId}#comments`;
        text = `Új hozzászólás: ${notification.title}`;
        updateUnreadAfterClick = true;
        break;
      default:
        break;
    }
    return { url, text, updateUnreadAfterClick };
  }

  return (
    <div>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge
          badgeContent={
            notifications?.filter((notification) => notification.unread).length
          }
          color="error"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {notifications && notifications.length > 0 ? (
          notifications.map((notification) => {
            const { url, text, updateUnreadAfterClick } =
              getDisplayParams(notification);
            return (
              <MenuItem key={notification.id} onClick={handleClose}>
                <Link
                  to={url}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    width: "100%",
                  }}
                  onClick={() => {
                    if (notification.unread && updateUnreadAfterClick) {
                      setNotificationRead(notification.id);
                      handleClose();
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        maxWidth: isSmall ? "250px" : "600px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {text}
                    </div>
                    <Badge
                      variant="dot"
                      color="error"
                      invisible={!notification.unread}
                      style={{ marginLeft: "15px" }}
                    >
                      <div />
                    </Badge>
                  </div>
                </Link>
              </MenuItem>
            );
          })
        ) : (
          <MenuItem>
            <div style={{ color: "#a0a0a0" }}>Nincs értesítés</div>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default Notifications;
