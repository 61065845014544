import { Navigate } from "react-router-dom";
import { Roles, useRights } from "hooks/useRights";

interface ProtectedComponentProps {
  component: React.ComponentType;
}

 const ProtectedComponent: React.FC<ProtectedComponentProps> = ({ component: Component, ...rest }) => {
  const { hasRole, authLoading } = useRights();
  const isAuthenticated = hasRole(Roles.SIGNED);

  if (authLoading) return <div>töltés ...</div>
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/jogosulatlan" replace />;
}

export default ProtectedComponent