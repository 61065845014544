import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useDailyVisitsData } from "data/dailyVisits";



interface VisitStats {
  registeredCount: number;
  botCount: number;
  anonymousCount: number;
}

const expandableDirectories = ["szolo", "posts"];

const getLastTenDays = () => {
  const dates = [];
  for (let i = 0; i < 10; i++) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    dates.push(date.toISOString().split("T")[0]);
  }
  return dates;
};

const DailyVisitsAggregate = () => {
  const today = new Date().toISOString().split('T')[0];
  const { selectedDate, setSelectedDate, dailyData } = useDailyVisitsData(today);

  const [visitStats, setVisitStats] = useState<VisitStats>();
  const [urlStats, setUrlStats] = useState<
    { path: string; count: number; percentage: string }[]
  >([]);
  const [expandedData, setExpandedData] = useState<{
    [key: string]: { path: string; count: number; percentage: string }[];
  }>({});
  const [expandedDirs, setExpandedDirs] = useState<Set<string>>(new Set());
  const [userAgentStats, setUserAgentStats] = useState<{
    [key: string]: number;
  }>({});

  // Adatok elemzése és statisztikák készítése
  useEffect(() => {
    if (dailyData) {
      let registeredCount = 0;
      let botCount = 0;
      let anonymousCount = 0;

      Object.values(dailyData).forEach((session) => {
        if (session.userId && session.userId !== "") {
          registeredCount++;
        } else if (session.botType) {
          botCount++;
        } else {
          anonymousCount++;
        }
      });

      setVisitStats({
        registeredCount,
        botCount,
        anonymousCount,
      });

      const newUserAgentStats: { [key: string]: number } = {};
      Object.values(dailyData).forEach((session) => {
        const userAgent = session.userAgent || "Unknown";
        newUserAgentStats[userAgent] = (newUserAgentStats[userAgent] || 0) + 1;
      });
      setUserAgentStats(newUserAgentStats);
    }
  }, [dailyData]);

  useEffect(() => {
    if (dailyData) {
      const mainUrlCounts: { [url: string]: number } = {};
      const subUrlCounts: { [url: string]: number } = {};
      let totalUrlVisits = 0;

      Object.values(dailyData).forEach((session) => {
        Object.values(session?.visits ?? {}).forEach((visit) => {
          const urlPath = new URL(visit.url, "https://szoloink.hu").pathname;
          const segments = urlPath.split("/").filter(Boolean);
          const firstDirectory = segments[0] || "root";

          // Count for first-level directories
          mainUrlCounts[firstDirectory] =
            (mainUrlCounts[firstDirectory] || 0) + 1;

          // Separate counting for subdirectories of expanded directories
          if (expandedDirs.has(firstDirectory) && segments.length > 1) {
            const subPath = segments.slice(0, 2).join("/");
            subUrlCounts[subPath] = (subUrlCounts[subPath] || 0) + 1;
          }

          totalUrlVisits++;
        });
      });

      // Update main URL stats
      const newUrlStats = Object.entries(mainUrlCounts)
        .map(([path, count]) => ({
          path,
          count,
          percentage: ((count / totalUrlVisits) * 100).toFixed(2),
        }))
        .sort((a, b) => b.count - a.count);

      setUrlStats(newUrlStats);

      // Update expanded data with subdirectories
      const newExpandedData = { ...expandedData };
      expandedDirs.forEach((dir) => {
        newExpandedData[dir] = Object.entries(subUrlCounts)
          .filter(([path, _]) => path.startsWith(dir + "/"))
          .map(([path, count]) => ({
            path,
            count,
            percentage: ((count / totalUrlVisits) * 100).toFixed(2),
          }))
          .sort((a, b) => b.count - a.count);
      });

      setExpandedData(newExpandedData);
    }
  }, [dailyData, expandedDirs, expandedData]);

  const lastTenDays = getLastTenDays();

  // Dátum kiválasztása
  const handleDateChange = (event: SelectChangeEvent<string>) => {
    setSelectedDate(event.target.value);
  };

  const handleExpand = (path: string) => {
    const newExpandedDirs = new Set(expandedDirs);
    if (expandedDirs.has(path)) {
      newExpandedDirs.delete(path);
    } else {
      newExpandedDirs.add(path);
    }
    setExpandedDirs(newExpandedDirs);
  };

  // Adatok betöltése a kiválasztott napra

  const calcPercentage = (count: number, totalCount: number) =>
    totalCount > 0 ? ((count / totalCount) * 100).toFixed(2) : "0";

  const totalCount =
    (visitStats?.registeredCount ?? 0) +
    (visitStats?.botCount ?? 0) +
    (visitStats?.anonymousCount ?? 0);

  return (
    <Box sx={{height: "100%", overflow: "auto"}}>
      <h1>Daily Visits Aggregate</h1>
      <FormControl>
        <InputLabel id="date-select-label">Válassz egy dátumot</InputLabel>
        <Select
          labelId="date-select-label"
          value={selectedDate}
          label="Válassz egy dátumot"
          onChange={handleDateChange}
        >
          {lastTenDays.map((date) => (
            <MenuItem key={date} value={date}>
              {date}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {visitStats && (
        <Box>
          <p>
            Regisztrált látogatók: {visitStats.registeredCount} (
            {calcPercentage(visitStats.registeredCount, totalCount)}%)
          </p>
          <p>
            Bot látogatók: {visitStats.botCount} (
            {calcPercentage(visitStats.botCount, totalCount)}%)
          </p>
          <p>
            Nem regisztrált látogatók: {visitStats.anonymousCount} (
            {calcPercentage(visitStats.anonymousCount, totalCount)}%)
          </p>
        </Box>
      )}

      {urlStats && (
        <Box>
          <h2>URL Visit Statistics</h2>
          <ul>
            {urlStats.map(({ path, count, percentage }) => (
              <div key={path}>
                <li onClick={() => handleExpand(path)}>
                  {path}: {count} visits ({percentage}%)
                  {expandableDirectories.includes(path) && " (Click to expand)"}
                </li>
                {expandedDirs.has(path) &&
                  expandedData[path]?.map(
                    ({
                      path: subPath,
                      count: subCount,
                      percentage: subPercentage,
                    }) => (
                      <li key={subPath} style={{ marginLeft: "20px" }}>
                        {subPath}: {subCount} visits ({subPercentage}%)
                      </li>
                    )
                  )}
              </div>
            ))}
          </ul>
        </Box>
      )}

      {userAgentStats && (
        <Box>
          <Box>
            <h2>User Agent Statistics</h2>
            <ul>
              {Object.entries(userAgentStats).map(([userAgent, count]) => (
                <li key={userAgent}>
                  {userAgent}: {count}
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DailyVisitsAggregate;
