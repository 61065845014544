import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { useGetShortGrapes, ShortGrape } from "data/grape";

import AddIcon from "@mui/icons-material/Add";
import { UserAction, useRights } from "hooks/useRights";
import { useConfirmDialog } from "../common/ConfirmDialog/useConfirmDialog";
import ConfirmDialog from "../common/ConfirmDialog/ConfirmDialog";
import NewGrapeDialog, { NewGrapeForm, newGrapeFromId } from "./NewGrapeDialog";
import slugify from "slugify";
import { collection, doc, setDoc, Timestamp } from "@firebase/firestore";
import { db } from "../../utils/firebase";
import { useNavigate, useParams } from "react-router";
import Typography from "@mui/material/Typography";
import { useMeasure } from "react-use";
import CircularProgress from "@mui/material/CircularProgress";
import { slugConfig } from "utils/general";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import InnerToolBar from "../common/InnerToolBar";
import GrapeNavigation from "./GrapeNavigation";
import GrapeColumns from "./GrapeColumns";
import useScrollPosition from "hooks/useScrollPosition";

export const PixelPerColumns = 380;
const GRAPE_PER_PAGE = 35;

// változás esetén változtatni kell a funcions-ban a sitemap generálásban is
export const initials = [
  "AÁ",
  "B",
  "C",
  "D",
  "EÉ",
  "F",
  "G",
  "H",
  "IÍ",
  "J",
  "K",
  "L",
  "M",
  "N",
  "OÓÖŐ",
  "PQ",
  "R",
  "S",
  "T",
  "VWUÚÜŰ",
  "ZXY",
];

interface GrapesPageProps {}

interface GrapeWithID {
  grapeId: string;
  grape: ShortGrape;
}

export interface InialWithGrapes {
  initial: string;
  label: string;
  grapesWithId: GrapeWithID[];
  pathInitial: string;
}

const GrapesPage: React.FC<GrapesPageProps> = () => {
  const { data: shortGrapes } = useGetShortGrapes();
  const [initialWithGrapes, setInitialWithGrapes] =
    React.useState<InialWithGrapes[]>();
  const { hasRight } = useRights();
  const [dialogProps, setDialogProps, setDialogOpen] = useConfirmDialog();
  const navigate = useNavigate();
  const { pathInitial } = useParams();
  const [selectedIwg, setSelectedIwg] = React.useState<InialWithGrapes>();
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");

  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const scrollRef = useScrollPosition();

  const columnNumber = Math.max(1, Math.floor(width / PixelPerColumns));

  React.useEffect(() => {
    if (initialWithGrapes) {
      const initial =
        initialWithGrapes.find((iwg) => iwg.pathInitial === pathInitial)
          ?.initial ?? initials[0];
      let title = `${initial} betűvel kezdődő szőlő fajták`;
      if (pathInitial === "uj") {
        title = "Új szőlőfajták";
      }
      setTitle(title);
    }
  }, [pathInitial, initialWithGrapes]);

  React.useEffect(() => {
    if (shortGrapes) {
      const newest35GrapeIds: GrapeWithID[] = Object.entries(shortGrapes)
        .sort(
          (a, b) =>
            (b[1].created?.seconds ?? Number.MIN_SAFE_INTEGER) -
            (a[1].created?.seconds ?? Number.MIN_SAFE_INTEGER)
        )
        .filter((value, index) => index < GRAPE_PER_PAGE)
        .map(([grapeId, grape]) => ({ grapeId, grape }));
      let nameHash = { ...shortGrapes };
      let grapesWithId = Object.entries(shortGrapes)
        .sort((a, b) => a[1].name.localeCompare(b[1].name, "hu"))
        .map(([grapeId, grape]) => ({ grapeId, grape }));

      let initalsWithGrapes: InialWithGrapes[] = [];
      initials.forEach((initial) => {
        initalsWithGrapes.push({
          initial: initial,
          label: initial[0],
          pathInitial: slugify(initial, slugConfig),
          grapesWithId: grapesWithId.filter((grape) => {
            const nameInitial = grape.grape.name.toUpperCase()[0];
            const includes = initial.split("").includes(nameInitial);
            if (includes) {
              delete nameHash[grape.grapeId];
            }
            return includes;
          }),
        });
      });
      if (Object.keys(nameHash).length > 0) {
        initalsWithGrapes.push({
          initial: "Egyéb",
          label: "egy",
          pathInitial: "egyeb",
          grapesWithId: grapesWithId.filter((grape) => nameHash[grape.grapeId]),
        });
      }
      initalsWithGrapes.push({
        initial: "Új",
        label: "Új",
        pathInitial: "uj",
        grapesWithId: newest35GrapeIds,
      });

      setInitialWithGrapes(initalsWithGrapes);
    }
  }, [shortGrapes]);

  React.useEffect(() => {
    if (!initialWithGrapes) return;
    const selectedGrape = initialWithGrapes.find(
      (iwg) => iwg.pathInitial === pathInitial
    );
    if (selectedGrape) {
      setSelectedIwg(selectedGrape);
    } else {
      setSelectedIwg(initialWithGrapes[0]);
    }
  }, [pathInitial, initialWithGrapes]);

  React.useEffect(() => {
    if (selectedIwg) {
      const description = selectedIwg.grapesWithId
        .map(
          ({ grape }) =>
            grape.name +
            (grape.additionalNames ? " - " + grape.additionalNames : "")
        )
        .join(", ");
      setDescription(description);
    }
  }, [selectedIwg]);

  const handleNewGrapeFormSave = (data: NewGrapeForm) => {
    setDialogProps({ ...dialogProps, actionProgress: true });
    let slug = slugify(data.name, slugConfig);

    const coll = collection(db, "grapes");
    const ref = doc(coll, slug);
    setDoc(ref, {
      name: data.name,
      created: new Timestamp(new Date().getTime() / 1000, 0),
      notFinished: true,
    }).then(() => {
      setDialogProps({ ...dialogProps, actionProgress: false });
      setDialogOpen(false);
      navigate(`/szolo/${slug}`);
    });
  };

  const handleNewGrapePressed = () => {
    setDialogProps({
      title: "Új szőlő",
      formId: newGrapeFromId,
      onConfirm: () => {},
    });
    setDialogOpen(true);
  };

  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Helmet>
        <title>{`${title} - Szőlőink közösségi szőlőkatalógus`}</title>
        {description && <meta name="description" content={description} />}
        {title && (
          <link
            rel="canonical"
            href={`https://szoloink.hu/szolok/${
              (pathInitial === "aa" ? "" : pathInitial) ?? ""
            }`}
          />
        )}
      </Helmet>
      <Card
        sx={{
          background: "unset",
          width: "100%",
          alignSelf: "center",
          flex: "1 1 auto",
          maxWidth: `${columnNumber * PixelPerColumns + 60}px`,
          display: "flex",
          flexDirection: "column",
        }}
        ref={ref}
        component="div"
      >
        {hasRight(UserAction.NEW_GRAPE) && (
          <InnerToolBar>
            <Button
              onClick={handleNewGrapePressed}
              color="primary"
              size="small"
              startIcon={<AddIcon />}
              variant="outlined"
            >
              Új szőlő
            </Button>
          </InnerToolBar>
        )}
        {initialWithGrapes && <GrapeNavigation iwgList={initialWithGrapes} />}
        <Grid
          item
          xs={12}
          sx={{ padding: { xs: "5px 15px", md: "10px 15px" } }}
        >
          <Typography variant={width < 900 ? "h6" : "h5"} component="div">
            {title}
          </Typography>
        </Grid>
        <Box
          ref={scrollRef}
          sx={{ overflow: "auto", display: "flex", flexDirection: "column" }}
        >
          {selectedIwg ? (
            <GrapeColumns iwg={selectedIwg} width={width} />
          ) : (
            <CircularProgress />
          )}
        </Box>
        <ConfirmDialog {...dialogProps}>
          <NewGrapeDialog onFormSave={handleNewGrapeFormSave} />
        </ConfirmDialog>
      </Card>
    </div>
  );
};

export default GrapesPage;
