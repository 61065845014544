import { FC, useState, useEffect, useMemo } from "react";
import {
  ConversationData,
  UserConversation,
  useAddMessage,
  useMessagesListener,
} from "data/messages-realtime";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { useNavigate } from "react-router-dom";
import { ShortUserList } from "data/user";
import ConversationHeader from "./ConversationHeader";
import { assignColorsToParticipants } from "../utils";
import CircularProgressOverlay from "components/common/CircularProgressOverlay/CircularProgressOverlay";
import MessageList from "./MessageList";

interface ConversationProps {
  userId: string;
  conversationId: string;
  selectedUserConversation: UserConversation | null | undefined;
  selectedConversation: ConversationData | null | undefined;
  isSmall?: boolean;
  users?: ShortUserList;
}

const ConversationDetail: FC<ConversationProps> = ({
  userId,
  conversationId,
  selectedUserConversation,
  selectedConversation,
  isSmall,
  users,
}) => {
  const { mutateAsync: addMessage, isPending: addMessageLoading } =
    useAddMessage();
  const { messages } = useMessagesListener(conversationId, userId);

  const [messageText, setMessageText] = useState("");

  const navigate = useNavigate();
  const participantIds = selectedUserConversation?.participantIds ?? [];

  const participantColors = useMemo(() => {
    return assignColorsToParticipants(participantIds);
  }, [participantIds]);

  useEffect(() => {
    if (selectedConversation === null) {
      navigate("/404");
    }
  }, [selectedConversation, navigate]);

  const handleAddMessage = async () => {
    if (!messageText.trim()) return;

    try {
      await addMessage({
        senderId: userId,
        messageText,
        conversationId,
      });
      setMessageText("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        height: "100%",
        borxSizing: "border-box",
      }}
    >
      <Box sx={{ marginTop: isSmall ? "60px" : "0px" }}>
        <ConversationHeader
          selectedUserConversation={selectedUserConversation}
          users={users}
          currentUserId={userId}
        />
      </Box>
      <Box
        sx={{
          overflowY: "auto",
          padding: "10px",
          flex: "1 1 auto",
          background: "#f2f2f2",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MessageList
          messages={messages}
          userId={userId}
          users={users}
          participantColors={participantColors}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px 10px 10px 10px",
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Üzenet írása..."
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !addMessageLoading) {
              handleAddMessage();
            }
          }}
        />
        <CircularProgressOverlay isLoading={addMessageLoading}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddMessage}
            sx={{ marginLeft: "10px" }}
          >
            Küldés
          </Button>
        </CircularProgressOverlay>
      </Box>
    </Box>
  );
};

export default ConversationDetail;
