import React, { FC } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import { ShortUser } from "data/user";
import { createLocationString } from "utils/general";
import { To } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Link from "components/Link";

import LazyLoad from "react-lazyload";
import MoreMenu, { MoreMenuItem, moreMenuHoverStyle } from "./MoreMenu";

interface UserListItemProps {
  user: ShortUser;
  isNewUser: boolean;
  link: To;
  menuItems?: MoreMenuItem[];
  selected?: boolean;
}

const UserListItem: FC<UserListItemProps> = ({
  user,
  isNewUser,
  link,
  menuItems,
  selected,
}) => {
  const location = createLocationString(user);

  return (
    <ListItem
      key={user?.name}
      sx={{
        ...moreMenuHoverStyle,
      }}
      secondaryAction={
        menuItems &&
        menuItems.length > 0 && (
          <MoreMenu menuItems={menuItems} selected={!!selected} />
        )
      }
    >
      <ListItemButton selected={selected}>
        <Link
          to={link}
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "inherit",
            width: "100%",
          }}
        >
          <ListItemAvatar>
            <Badge color="error" badgeContent="Új" invisible={!isNewUser}>
              <LazyLoad height={40} offset={100} once placeholder={<Avatar />}>
                <Avatar alt={user?.name} src={user?.photoURL} />
              </LazyLoad>
            </Badge>
          </ListItemAvatar>
          <ListItemText
            primary={
              <div style={{ display: "flex", gap: "5px" }}>
                <Typography
                  sx={{ display: "inline" }}
                  component="div"
                  variant="body1"
                  color="text.primary"
                >
                  {`${user?.name ?? "Anonymus"}${
                    user?.grapeCount
                      ? " (" + user.grapeCount + " féle szőlő)"
                      : ""
                  }`}
                </Typography>
                {user?.contactInfo && <MailOutlineIcon color="action" />}
              </div>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {location.join(", ")}
                </Typography>
              </React.Fragment>
            }
          />
        </Link>
      </ListItemButton>
    </ListItem>
  );
};

export default React.memo(UserListItem);
