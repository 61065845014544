import React, { FC, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { GrapeHistory } from "data/grapeHistory";
import { useNavigate } from "react-router-dom";

interface VersionSelectorProps {
  grapeHistory: GrapeHistory[];
  grapeId: string;
  initialVersionOne?: string;
  initialVersionTwo?: string;
}

const VersionSelector: FC<VersionSelectorProps> = ({
  grapeHistory,
  grapeId,
  initialVersionOne,
  initialVersionTwo,
}) => {
  const [versionOne, setVersionOne] = useState(
    initialVersionOne ?? grapeHistory.length.toString()
  );
  const [versionTwo, setVersionTwo] = useState(initialVersionTwo ?? "");
  const navigate = useNavigate();

  const handleVersionChange = (
    event: SelectChangeEvent<string>,
    versionSetter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const selectedVersion = `${event.target.value}`;
    versionSetter(selectedVersion);
  };

  useEffect(() => {
    if (!initialVersionTwo) {
      const lastVersion = grapeHistory[0];
      let defaultVersionTwo = "";

      for (let i = 1; i < grapeHistory.length; i++) {
        const currentVersion = grapeHistory[i];
        const timeDiff = lastVersion.date.seconds - currentVersion.date.seconds;
        const daysDiff = timeDiff / (3600 * 24);

        if (lastVersion.userName !== currentVersion.userName || daysDiff > 3) {
          defaultVersionTwo = (grapeHistory.length - i).toString();
          break;
        }
      }

      setVersionTwo(defaultVersionTwo ?? "1");
    }
  }, [grapeHistory, initialVersionTwo]);

  const handleCompareClick = () => {
    if (versionOne && versionTwo && versionOne !== versionTwo) {
      // Navigate to the comparison page with versionOne and versionTwo as URL params or queries
      navigate(`/szolo/${grapeId}/compare/${versionOne}/${versionTwo}`);
    } else {
      // Handle error or alert the user to select two different versions
      alert("Please select two different versions to compare.");
    }
  };

  return (
    <Box
      sx={{
        flexDirection: { xs: "column", sm: "row" },
        display: "flex",
        gap: 2,
        alignItems: "center",
      }}
    >
      <FormControl fullWidth>
        <Select
          value={versionOne}
          onChange={(event) => handleVersionChange(event, setVersionOne)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value="" disabled>
            Select Version 1
          </MenuItem>
          {grapeHistory.map((item, i) => (
            <MenuItem
              value={grapeHistory.length - i}
              key={item.date.toString()}
            >
              {`#${grapeHistory.length - i}: ${item.userName} - ${new Date(
                item.date.seconds * 1000
              ).toLocaleString("hu")}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <Select
          value={versionTwo}
          onChange={(event) => handleVersionChange(event, setVersionTwo)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value="" disabled>
            Select Version 2
          </MenuItem>
          {grapeHistory.map((item, i) => (
            <MenuItem
              value={grapeHistory.length - i}
              key={item.date.toString()}
            >
              {`#${grapeHistory.length - i}: ${item.userName} - ${new Date(
                item.date.seconds * 1000
              ).toLocaleString("hu")}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        sx={{ minWidth: "unset" }}
        variant="outlined"
        size="small"
        onClick={handleCompareClick}
      >
        Összehasonlít
      </Button>
    </Box>
  );
};

export default VersionSelector;
