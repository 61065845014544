import Link from "components/Link";
import { Popover, IconButton, Box } from "@mui/material";
import { ReactNode, useState, MouseEvent, PropsWithChildren } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { useGetShortGrapes } from "data/grape";

import GrapeListItem from "components/GrapeListItem";

interface CustomLinkProps {
  href?: string;
  children: ReactNode & ReactNode[];
}

interface GrapeInfoProps {
  grapeId: string;
}

interface CustomImageProps {
  src?: string;
  alt?: string;
}

export const CustomImage: React.FC<CustomImageProps> = ({ src, alt }) => (
  <Box
    component="img"
    src={src}
    alt={alt}
    loading="lazy"
    sx={{ maxWidth: "100%", height: "auto" }}
  />
);

const GrapeInfo: React.FC<GrapeInfoProps> = ({ grapeId }) => {
  const { data: shortGrapes } = useGetShortGrapes();
  const shortGrape = shortGrapes?.[grapeId];

  return shortGrape ? (
    <GrapeListItem grape={shortGrape} grapeId={grapeId} width={400} />
  ) : (
    <div></div>
  );
};

const YoutubeEmbed: React.FC<
  PropsWithChildren<Pick<CustomLinkProps, "href">>
> = ({ href, children, ...props }) => {
  const youtubeVideoRegex =
    /(?:http(?:s)?:\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)(.+)/g;
  const match = href ? youtubeVideoRegex.exec(href) : null;
  if (match?.[1]) {
    return (
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          maxWidth: { xs: "100%", md: "768px" },
          height: { xs: "56.25vw", md: "430px" },
          margin: "auto",
        }}
      >
        <iframe
          title={`Youtube Embed - ${match[1]}`}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          src={`https://www.youtube.com/embed/${match[1]}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Box>
    );
  }
  return (
    <DefaultLink href={href} {...props}>
      {children}
    </DefaultLink>
  );
};

const GrapePopover: React.FC<PropsWithChildren<GrapeInfoProps>> = ({
  grapeId,
  children,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <span>
      {children}
      <IconButton
        style={{ padding: "0px 0px 0px 2px" }}
        aria-label="info about grape"
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
      >
        <InfoIcon />
      </IconButton>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <GrapeInfo grapeId={grapeId} />
      </Popover>
    </span>
  );
};

export const CustomLink: React.FC<CustomLinkProps> = ({
  href,
  children,
  ...props
}) => {
  const grapeLinkRegex = /\/szolo\/([^/]+)/;
  const grapeIdMatch = href?.match(grapeLinkRegex);

  if (href?.startsWith("/")) {
    const link = (
      <Link to={href} {...props}>
        {children}
      </Link>
    );

    if (grapeIdMatch) {
      return <GrapePopover grapeId={grapeIdMatch[1]}>{link}</GrapePopover>;
    }

    return link;
  } else if (href?.includes("youtube.com") || href?.includes("youtu.be")) {
    return (
      <YoutubeEmbed href={href} {...props}>
        {children}
      </YoutubeEmbed>
    );
  } else {
    return (
      <DefaultLink href={href} {...props}>
        {children}
      </DefaultLink>
    );
  }
};

interface DefaultLinkProps {
  href?: string;
  children: ReactNode;
  [key: string]: any; // This allows for additional props to be passed
}

const DefaultLink: React.FC<DefaultLinkProps> = ({
  href,
  children,
  ...props
}) => (
  <a href={href} target="_blank" rel="nofollow noopener noreferrer" {...props}>
    {children}
  </a>
);
