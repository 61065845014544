import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useLocation, useNavigate } from "react-router-dom";
import {
  calculateRipeningTime,
  doesGrapeMatchTags,
  updateUrlParams,
} from "./utility";
import { ShortGrape, ShortGrapeList } from "data/grape";

export type SortOptionType =
  | "nepszeru-elol"
  | "nev-az"
  | "nev-za"
  | "legujabb"
  | "nepszeru-hatul"
  | "eresi-ido";

type SortOptionProps = {
  currentSort: SortOptionType;
};

export const filterAndSortGrapes = (
  grapes: ShortGrapeList | undefined,
  sortOption: SortOptionType,
  groupedTagsByCategory: Map<any, any>
) => {
  let filtered = Object.entries(grapes ?? {}).map(([id, grape]) => ({
    ...grape,
    id,
  }));

  if (groupedTagsByCategory.size > 0) {
    filtered = filtered.filter((grape) =>
      doesGrapeMatchTags(grape, groupedTagsByCategory)
    );
  }
  switch (sortOption) {
    case "nev-az":
      filtered.sort((a, b) => a.name.localeCompare(b.name));
      break;
    case "nev-za":
      filtered.sort((a, b) => b.name.localeCompare(a.name));
      break;
    case "legujabb":
      filtered.sort(
        (a, b) => (b.created?.seconds ?? 0) - (a.created?.seconds ?? 0)
      );
      break;
    case "nepszeru-hatul":
      filtered.sort((a, b) => (a.o ?? 0) - (b.o ?? 0));
      break;
    case "eresi-ido": // Új rendezési logika
      filtered.sort((a, b) => {
        const aRipeningAvg = calculateRipeningTime(
          a.ripeningFrom,
          a.ripeningTo
        );
        const bRipeningAvg = calculateRipeningTime(
          b.ripeningFrom,
          b.ripeningTo
        );
        return aRipeningAvg - bRipeningAvg;
      });
      break;
    case "nepszeru-elol":
    default:
      filtered.sort((a, b) => (b.o ?? 0) - (a.o ?? 0));
      break;
  }
  return filtered;
};

const SortOption: React.FC<SortOptionProps> = ({ currentSort }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleSortOptionChange = (e: SelectChangeEvent<SortOptionType>) => {
    const newSortOption = e.target.value as SortOptionType;
    const searchParams = new URLSearchParams(location.search);
    const params: Record<string, string> = {};

    if (searchParams.get("cimkek")) {
      params["cimkek"] = searchParams.get("cimkek") ?? "";
    }

    if (newSortOption !== "nepszeru-elol") {
      params["rendezes"] = newSortOption;
    }

    const url = location.pathname + updateUrlParams(params);
    navigate(url);
  };

  return (
    <FormControl
      variant="outlined"
      style={{ minWidth: 150, marginBottom: "1rem" }}
    >
      <InputLabel>Rendezés</InputLabel>
      <Select
        value={currentSort}
        onChange={handleSortOptionChange}
        label="Rendezés"
      >
        <MenuItem value="nepszeru-elol">Népszerű elől</MenuItem>
        <MenuItem value="nev-az">Név szerint (A-Z)</MenuItem>
        <MenuItem value="nev-za">Név szerint (Z-A)</MenuItem>
        <MenuItem value="legujabb">Újonnan hozzáadott</MenuItem>
        <MenuItem value="nepszeru-hatul">Népszerű hátul</MenuItem>
        <MenuItem value="eresi-ido">Érési idő</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SortOption;
