import React, { FC } from "react";
import { ShortGrape } from "data/grape";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import GrapeAvatar from "./common/GrapeAvatar";
import Link from "./Link";

interface GreapeSearchResultProps {
  grape: ShortGrape;
  width?: number;
  id: string;
}

const imageWidth = 50;
const gaps = 20;

const GreapeSearchResult: FC<GreapeSearchResultProps> = ({
  grape,
  id,
  width = 250,
}) => {
  return (
    <ListItem
      disablePadding
      component={Link}
      to={`/szolo/${id}`}
      sx={{
        maxWidth: `${width}px`,
        margin: { xs: "1px", sm: "5px" },
        width: { xs: "unset", sm: "100%" },
      }}
    >
      <ListItemButton
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "normal",
          gap: "10px",
        }}
      >
        <Box sx={{ display: { xs: "none", sm: "flex" } }} component="div">
          <GrapeAvatar grape={grape} grapeId={id} width={imageWidth} />
        </Box>
        <Box sx={{ display: { xs: "flex", sm: "none" } }} component="div">
          <GrapeAvatar grape={grape} grapeId={id} width={25} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
          component="div"
        >
          <Typography
            sx={{
              maxWidth: `${width - imageWidth - gaps}px`,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            variant="body1"
            noWrap
            component="div"
          >
            {grape.name}
          </Typography>
          <Typography
            sx={{
              maxWidth: `${width - imageWidth - gaps}px`,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              display: { xs: "none", sm: "flex" },
            }}
            variant="caption"
            color="gray"
            component="div"
          >
            {grape.additionalNames}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export default GreapeSearchResult;
