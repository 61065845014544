import React, { FC } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import CovertGrapeList from "./CovertGrapeList";
import Sitemap from "./Sitemap";
import CreateDateForGrapes from "./CreateDateForGrapes";
import Stat from "./Stat";
import MostViewedGrapes from "./MostViewedGrapes";

const DevPage: FC = () => {
  const [email, setEmail] = React.useState("");
  const [enabled, setEnabled] = React.useState(true);

  const handleAddAdminRole = () => {
    const functions = getFunctions();
    const addAdminRole = httpsCallable(functions, "addAdminRole");

    addAdminRole({ email, enabled })
      .then((response) => {
        console.log(response.data);
        //@ts-ignore
        if (response.data?.errorInfo) {
          //@ts-ignore
          toast.error(response.data?.errorInfo.message);
        } else {
          //@ts-ignore
          toast(response.data.message);
        }
      })
      .catch((e) => toast.error(e.message));
  };

  return (
    <div style={{ margin: "50px" }}>
      <CovertGrapeList />

      <Typography
        sx={{ marginTop: "20px", marginBottom: "10px" }}
        variant="h6"
        noWrap
        component="div"
      >
        Admin jog adása / elvétele
      </Typography>
      <Box sx={{ display: "flex", gap: "20px" }}>
        <Checkbox
          checked={enabled}
          onChange={(event) => setEnabled(event.target.checked)}
        />
        <TextField
          id="outlined-name"
          label="email cím vagy uid"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <Button onClick={handleAddAdminRole} size="small" variant="contained">
          {!enabled && "Ne "} Legyen admin
        </Button>
      </Box>
      <CreateDateForGrapes />
      <Stat />
      <Typography
        sx={{ marginTop: "20px", marginBottom: "10px" }}
        variant="h6"
        noWrap
        component="div"
      >
        Legnézettebb szőlők
      </Typography>

      <MostViewedGrapes />
    </div>
  );
};

export default DevPage;
