import React from "react";
import { useGetShortGrapes } from "data/grape";

import Flexsearch from "flexsearch";
import didYouMean, { ReturnTypeEnums } from "didyoumean2";

let index = new Flexsearch.Index({
  charset: "latin:advanced",
  tokenize: "reverse",
  cache: true,
});

let didYouMeanData: { index: string; key: string }[];

const maxSuggestion = 3;

export const useSearch = (minTermLength = 1) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const { data: grapes, isLoading } = useGetShortGrapes(!!searchTerm);
  const [results, setResults] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (grapes) {
      index = new Flexsearch.Index({
        tokenize: "forward",
        cache: true,
      });
      Object.entries(grapes).forEach(([key, grape]) => {
        index.add(key, `${grape.name} ${grape.additionalNames}`);
      });
      didYouMeanData = Object.entries(grapes)
        .map(([key, grape]) => {
          const names = `${grape.name}, ${grape.additionalNames}`
            .replace(" ", ",")
            .split(",")
            .map((s) => s.trim());
          return names.map((name) => ({ index: key, key: name }));
        })
        .flat();
    }
  }, [grapes]);

  React.useEffect(() => {
    if (grapes && searchTerm.length > minTermLength) {
      let res = [...(index.search(searchTerm, 5) as string[])];
      //console.log("res", JSON.stringify(res));
      const suggestions = didYouMean(searchTerm, didYouMeanData, {
        matchPath: ["key"],
        returnType: ReturnTypeEnums.ALL_SORTED_MATCHES,
      }) as { index: string }[];
      // console.log("suggestions", JSON.stringify(suggestions));
      (suggestions ? suggestions : []).forEach((s, index) => {
        if (res.includes(s.index) || index > maxSuggestion) return false;
        res.push(s.index);
        return true;
      });
      setResults(res);
    } else {
      setResults([]);
    }
  }, [searchTerm, grapes, minTermLength]);

  return { searchTerm, setSearchTerm, grapes, results, isLoading };
};
