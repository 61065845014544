import { useMutation, useQueryClient } from "@tanstack/react-query";
import { doc, setDoc, collection, Timestamp } from "@firebase/firestore";
import { db } from "../utils/firebase";
import { useStateValue } from "utils/StateProvider";

export interface Error {
  created: Timestamp;
  exception: string;
  json: string;
  userName: string;
}

const ERROR = "ERROR";

const useSetErrors = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (error: Error) => {
      const ref = doc(collection(db, "errors"));
      return setDoc(ref, error);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ERROR] });
    },
  });
};

const useLogErrors = () => {
  const { authUser } = useStateValue()!;
  const created = Timestamp.fromDate(new Date());
  const setErrors = useSetErrors();

  const logErrors = (exception: string, json: string) => {
    setErrors.mutateAsync({
      created,
      userName: authUser?.displayName ?? "",
      exception,
      json,
    });
  };

  return { logErrors };
};

export default useLogErrors;
