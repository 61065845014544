import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getDocs,
  collection,
  query,
  where,
  doc,
  updateDoc,
  deleteField,
} from "firebase/firestore";

import { db } from "utils/firebase";

export type EventType = "create-grape" | "create-post" | "post-comment";

export interface EventDetail {
  type: EventType;
  refId?: string;
  parentId?: string;
  title: string;
  description: string;
  userName?: string;
}

export type EventDay = {
  [timestamp: string]: EventDetail;
};

interface FetchEventsParams {
  fromDay: number;
  toDay: number;
}

const fetchEventsForElapsedDaysRange = async ({
  fromDay,
  toDay,
}: FetchEventsParams): Promise<EventDay[]> => {
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - fromDay);
  const endDate = new Date(today);
  endDate.setDate(today.getDate() - toDay);

  const startKey = startDate.toISOString().split("T")[0];
  const endKey = endDate.toISOString().split("T")[0];

  const eventsCollection = collection(db, "events");
  const querySnapshot = await getDocs(
    query(
      eventsCollection,
      where("__name__", ">=", endKey),
      where("__name__", "<=", startKey)
    )
  );

  const eventList: EventDay[] = [];

  querySnapshot.docs.forEach((docSnapshot) => {
    eventList.push(docSnapshot.data() as EventDay);
  });

  return eventList;
};

export const useGetEventsForElapsedDaysRange = ({
  fromDay,
  toDay,
}: FetchEventsParams) => {
  return useQuery<EventDay[], Error>({
    queryKey: ["events", { fromDay, toDay }],
    queryFn: () => fetchEventsForElapsedDaysRange({ fromDay, toDay }),
  });
};

export const deleteEvent = async (timestamp: number): Promise<void> => {
  const date = new Date(timestamp);
  const dateKey = date.toISOString().split("T")[0];

  const eventDocRef = doc(db, "events", dateKey);

  const payload = {
    [timestamp]: deleteField(),
  };

  await updateDoc(eventDocRef, payload);
};

export const useDeleteEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteEvent,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["events"] });
    },
  });
};
