import React, { FC, memo, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import MessageItem from "./MessageItem";
import { Messages } from "data/messages-realtime";
import { ShortUserList } from "data/user";
import { ParticipantColors } from "../utils";

interface MessageListProps {
  messages: Messages;
  userId: string;
  users?: ShortUserList;
  participantColors: ParticipantColors;
}

const MessageList: FC<MessageListProps> = ({
  messages,
  userId,
  users,
  participantColors,
}) => {
  const endOfMessagesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <Box
      sx={{
        overflowY: "auto",
        padding: "10px",
        flex: "1 1 auto",
        background: "#f2f2f2",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {Object.entries(messages).map(([messageId, messageData]) => (
        <MessageItem
          key={messageId}
          messageData={messageData}
          userId={userId}
          users={users}
          participantColors={participantColors}
        />
      ))}
      <div ref={endOfMessagesRef} />
    </Box>
  );
};

export default memo(MessageList);
