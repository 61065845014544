import React, { FC } from "react";

import { useGetGrapes } from "hooks/useGetGrapes";

const Stat: FC = () => {
  const grapes = useGetGrapes();
  const grapeList = grapes
    .map((g) => ({
      name: g.name,
      count: Object.entries(g?.owners ?? {}).length,
    }))
    .sort((a, b) => b.count - a.count);
  const first20Grape = grapeList.slice(0, 50);

  return (
    <div style={{ marginTop: "40px" }}>
      {first20Grape.map((g, index) => (
        <div key={g.name}>{`${index + 1}. ${g.name} - ${g.count}`}</div>
      ))}
    </div>
  );
};

export default Stat;
