import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getDatabase,
  ref,
  push,
  set,
  serverTimestamp,
  get,
  onValue,
  off,
  ref as firebaseRef,
  update,
} from "firebase/database";
import { useEffect, useState } from "react";

interface NewMessageData {
  conversationId: string;
  senderId: string;
  messageText: string;
}

export interface ConversationData {
  participants: { [key: string]: boolean };
  name?: string;
  isBotMessage?: boolean;
}

interface NewConversationData {
  participantIds: string[];
  name?: string;
  isBotMessage?: boolean;
}

export interface MessageData {
  senderId: string;
  timestamp: number; // or Date if you prefer to convert it immediately after fetching
  message: string;
}

export interface Messages {
  [messageId: string]: MessageData;
}

export interface UserConversation {
  lastMessageTimestamp: number | object; // Accepts both number and serverTimestamp() marker
  name?: string;
  participantIds: string[];
  isReadOnly?: boolean;
  isRichText?: boolean;
  muted?: boolean;
}

export interface UserConversations {
  unreadConversationIds?: string[];
  lastInitiatedConversationTime?: number;
  conversations: { [conversationId: string]: UserConversation };
}

const createConversation = async ({
  data,
  initiatorUserId,
}: {
  data: NewConversationData;
  initiatorUserId: string;
}): Promise<string> => {
  const database = getDatabase();
  const { participantIds, ...restOfData } = data;
  const participants: { [key: string]: boolean } = participantIds.reduce(
    (obj, id) => {
      obj[id] = true;
      return obj;
    },
    {} as ConversationData["participants"]
  );

  // Check if there's an existing conversation between these participants
  const existingConversationId = await findExistingConversation(
    database,
    participants
  );
  if (existingConversationId) {
    return existingConversationId; // Return existing conversation ID if found
  }

  // If no existing conversation, create a new one
  const conversationRef = ref(database, "conversations");
  const newConversationRef = await push(conversationRef);
  const newConversationId = newConversationRef.key as string;

  const newConversationData: ConversationData = { ...restOfData, participants };

  await set(newConversationRef, newConversationData);

  const userConversationPath = `userConversations/${initiatorUserId}/lastInitiatedConversationTime`;
  await update(ref(database), { [userConversationPath]: serverTimestamp() });

  return newConversationId;
};

// Helper function to find existing conversation
const findExistingConversation = async (
  database: ReturnType<typeof getDatabase>,
  participants: { [key: string]: boolean }
): Promise<string | null> => {
  const participantIds = Object.keys(participants).sort();

  const userConversationsRef = ref(
    database,
    `userConversations/${participantIds[0]}/conversations`
  );
  const snapshot = await get(userConversationsRef);

  if (!snapshot.exists()) {
    return null;
  }

  let existingConversationId: string | null = null;
  snapshot.forEach((childSnapshot) => {
    const conversationData = childSnapshot.val();
    const conversationParticipants = conversationData.participantIds || [];

    if (arraysMatch(conversationParticipants.sort(), participantIds)) {
      existingConversationId = childSnapshot.key;
      return true; // Stop iterating
    }
    return false;
  });

  return existingConversationId;
};

// Helper function to check if two arrays are the same
const arraysMatch = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

export const useCreateConversation = () => {
  return useMutation({ mutationFn: createConversation });
};

export const createUserConversationFromData = (
  conversationData: ConversationData,
  toDatabase: boolean
): UserConversation => {
  const participantIds = Object.keys(conversationData.participants);
  let nameObj = {};

  if (conversationData.name) {
    nameObj = { name: conversationData.name };
  }

  return {
    lastMessageTimestamp: toDatabase ? serverTimestamp() : Date.now(),
    ...nameObj,
    participantIds,
  };
};

const addMessage = async (data: NewMessageData): Promise<void> => {
  const { conversationId, senderId, messageText } = data;
  const database = getDatabase();

  // Add new message to messages node
  const messagesRef = ref(database, `messages/${conversationId}`);
  const newMessageRef = push(messagesRef);
  await set(newMessageRef, {
    senderId,
    timestamp: serverTimestamp(),
    message: messageText,
  });

  // Get the participants of the conversation
  const conversationParticipantsRef = ref(
    database,
    `conversations/${conversationId}/participants`
  );
  const participantsSnapshot = await get(conversationParticipantsRef);

  if (participantsSnapshot.exists()) {
    const participants = participantsSnapshot.val();
    const participantIds = Object.keys(participants);

    for (const userId of participantIds) {
      const userConversationRef = ref(
        database,
        `userConversations/${userId}/conversations/${conversationId}`
      );
      const userConversationSnapshot = await get(userConversationRef);

      // If it's the first message, create userConversations records
      if (!userConversationSnapshot.exists()) {
        if (!userConversationSnapshot.exists()) {
          const conversationData = await fetchConversation(conversationId);
          if (conversationData) {
            // Ensure conversationData is not null
            const userConversationData = createUserConversationFromData(
              conversationData,
              true
            );
            await set(userConversationRef, userConversationData);
          }
        }
      } else {
        // Update lastMessageTimestamp for existing conversations
        await update(userConversationRef, {
          lastMessageTimestamp: serverTimestamp(),
        });
      }

      // Update unreadConversationIds for each participant except the sender
      if (userId !== senderId) {
        const userUnreadConversationsRef = ref(
          database,
          `userConversations/${userId}/unreadConversationIds`
        );

        const unreadSnapshot = await get(userUnreadConversationsRef);
        let unreadConversationIds = unreadSnapshot.exists()
          ? unreadSnapshot.val()
          : [];

        if (!unreadConversationIds.includes(conversationId)) {
          unreadConversationIds.push(conversationId);
          await set(userUnreadConversationsRef, unreadConversationIds);
        }
      }
    }
  }
};

export const useAddMessage = () => {
  return useMutation({ mutationFn: addMessage });
};

export const useMessagesListener = (conversationId: string, userId: string) => {
  const [messages, setMessages] = useState<Messages>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const database = getDatabase();
    const participantsRef = ref(
      database,
      `conversations/${conversationId}/participants`
    );

    get(participantsRef).then((snapshot) => {
      if (snapshot.exists() && snapshot.hasChild(userId)) {
        // A felhasználó részt vesz a beszélgetésben
        const messagesRef = firebaseRef(database, `messages/${conversationId}`);

        const unsubscribe = onValue(
          messagesRef,
          (snapshot) => {
            setLoading(false);
            if (snapshot.exists()) {
              setMessages(snapshot.val());
            } else {
              setMessages({});
            }
          },
          (error) => {
            setError(error);
            setLoading(false);
          }
        );

        return () => off(messagesRef, "value", unsubscribe);
      } else {
        // A felhasználó nem résztvevője a beszélgetésnek
        setLoading(false);
        setError(new Error("Nincs hozzáférés a beszélgetéshez"));
      }
    });
  }, [userId, conversationId]);

  return { messages, loading, error };
};

export const useUnreadMessagesListener = (userId: string) => {
  const [unreadConversationIds, setUnreadConversationIds] = useState<string[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const database = getDatabase();
    const unreadConversationsRef = firebaseRef(
      database,
      `userConversations/${userId}/unreadConversationIds`
    );

    const unsubscribe = onValue(
      unreadConversationsRef,
      (snapshot) => {
        setLoading(false);
        if (snapshot.exists()) {
          setUnreadConversationIds(snapshot.val() || []);
        } else {
          setUnreadConversationIds([]);
        }
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );

    return () => off(unreadConversationsRef, "value", unsubscribe);
  }, [userId]);

  return { unreadConversationIds, loading, error };
};

// Function to fetch user's conversations
const fetchUserConversations = async (
  userId: string
): Promise<UserConversations["conversations"] | null> => {
  const database = getDatabase();
  const userConversationsRef = ref(
    database,
    `userConversations/${userId}/conversations`
  );
  const snapshot = await get(userConversationsRef);

  if (!snapshot.exists()) {
    return null;
  }

  return snapshot.val();
};

// React Query hook to get user's conversations
export const useGetUserConversations = (userId: string) => {
  return useQuery({
    queryKey: ["userConversations", userId],
    queryFn: () => fetchUserConversations(userId),

    enabled: !!userId, // Only run query if userId is provided
  });
};

export const useUserConversationsListener = (userId: string) => {
  const [userConversations, setUserConversations] =
    useState<UserConversations | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const database = getDatabase();
    const userConversationsRef = firebaseRef(
      database,
      `userConversations/${userId}`
    );

    const unsubscribe = onValue(
      userConversationsRef,
      (snapshot) => {
        setLoading(false);
        if (snapshot.exists()) {
          setUserConversations(snapshot.val());
        } else {
          setUserConversations(null);
        }
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );

    return () => off(userConversationsRef, "value", unsubscribe);
  }, [userId]);

  return { userConversations, loading, error };
};

// Function to fetch a specific conversation
const fetchConversation = async (
  conversationId: string
): Promise<ConversationData | null> => {
  const database = getDatabase();
  const conversationRef = ref(database, `conversations/${conversationId}`);
  const snapshot = await get(conversationRef);

  if (!snapshot.exists()) {
    return null;
  }

  return snapshot.val() as ConversationData;
};

// React Query hook to get a specific conversation
export const useGetConversation = (conversationId: string) => {
  return useQuery({
    queryKey: ["conversation", conversationId],
    queryFn: () => fetchConversation(conversationId),

    enabled: !!conversationId, // Only run query if conversationId is provided
  });
};

const updateUnreadConversations = async ({
  userId,
  conversationId,
  add,
}: {
  userId: string;
  conversationId: string;
  add: boolean;
}): Promise<void> => {
  const database = getDatabase();
  const userUnreadConversationsRef = ref(
    database,
    `userConversations/${userId}/unreadConversationIds`
  );
  const unreadSnapshot = await get(userUnreadConversationsRef);
  let unreadConversationIds = unreadSnapshot.exists()
    ? unreadSnapshot.val()
    : [];

  if (add) {
    if (!unreadConversationIds.includes(conversationId)) {
      unreadConversationIds.push(conversationId);
    }
  } else {
    unreadConversationIds = unreadConversationIds.filter(
      (id: string) => id !== conversationId
    );
  }

  await set(userUnreadConversationsRef, unreadConversationIds);
};

export const useUpdateUnreadConversations = () => {
  return useMutation({ mutationFn: updateUnreadConversations });
};

const toggleMuteConversation = async ({
  userId,
  conversationId,
  mute,
}: {
  userId: string;
  conversationId: string;
  mute: boolean;
}): Promise<void> => {
  const database = getDatabase();
  const userConversationRef = ref(
    database,
    `userConversations/${userId}/conversations/${conversationId}/muted`
  );
  await set(userConversationRef, mute);
};

export const useToggleMuteConversation = () => {
  return useMutation({ mutationFn: toggleMuteConversation });
};

interface RemoveConversationParams {
  userId: string;
  conversationId: string;
}

const removeConversation = async ({
  userId,
  conversationId,
}: RemoveConversationParams) => {
  const database = getDatabase();
  const userConversationRef = ref(
    database,
    `userConversations/${userId}/conversations/${conversationId}`
  );

  await set(userConversationRef, null);
};

export const useRemoveConversation = () => {
  return useMutation({ mutationFn: removeConversation });
};

export const useCanStartNewConversation = (userId?: string) => {
  const [canStartNew, setCanStartNew] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkIfCanStartNewConversation = async () => {
      if (!userId) {
        setCanStartNew(false);
        setIsLoading(false);
        return;
      }

      const database = getDatabase();
      const lastInitiatedRef = ref(
        database,
        `userConversations/${userId}/lastInitiatedConversationTime`
      );

      try {
        const snapshot = await get(lastInitiatedRef);
        if (snapshot.exists()) {
          const lastInitiatedTime = new Date(snapshot.val());
          const today = new Date();

          setCanStartNew(
            lastInitiatedTime.getDate() !== today.getDate() ||
              lastInitiatedTime.getMonth() !== today.getMonth() ||
              lastInitiatedTime.getFullYear() !== today.getFullYear()
          );
        } else {
          setCanStartNew(true);
        }
      } catch (error) {
        console.error(
          "Error checking if user can start new conversation",
          error
        );
        setCanStartNew(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkIfCanStartNewConversation();
  }, [userId]);

  return { canStartNew, isLoading };
};
