import React, { useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import SessionDetails from "./SessionDetails";
import { Theme, useMediaQuery } from "@mui/material";
import { Visit, useDailyVisitsData } from "data/dailyVisits";

const getLastTenDays = () => {
  const dates = [];
  for (let i = 0; i < 10; i++) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    dates.push(date.toISOString().split("T")[0]);
  }
  return dates;
};

const getLatestVisitTime = (visits: { [key: string]: Visit }) => {
  return Object.values(visits).reduce((latest, visit) => {
    return visit.timestamp > latest ? visit.timestamp : latest;
  }, "");
};

const DailyVisitsList = () => {
  const today = new Date().toISOString().split("T")[0];
  const [searchQuery, setSearchQuery] = useState("");
  const { selectedDate, setSelectedDate, dailyData } =
    useDailyVisitsData(today);
  const lastTenDays = getLastTenDays();
  const [selectedSession, setSelectedSession] = useState<string | null>(null);
  const isSmallDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const handleDateChange = (event: SelectChangeEvent<string>) => {
    setSelectedDate(event.target.value);
  };

  const handleListItemClick = (sessionId: string) => {
    setSelectedSession(sessionId);
  };

  const sortedDailyData = React.useMemo(() => {
    return dailyData
      ? Object.entries(dailyData)
          .map(([sessionId, sessionData]) => ({
            sessionId,
            sessionData,
            latestVisit: getLatestVisitTime(sessionData.visits || {}),
          }))
          .filter(({ sessionData }) => {
            if (!searchQuery) return true;

            return (
              (sessionData?.name ?? "")
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              Object.values(sessionData?.visits ?? {}).some((visit) =>
                visit.url.toLowerCase().includes(searchQuery.toLowerCase())
              )
            );
          })
          .sort((a, b) => b.latestVisit.localeCompare(a.latestVisit))
      : [];
  }, [dailyData, searchQuery]);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", height: "100%"}}>
      <Box
        sx={{
          flex: "0 0 30%",
          padding: "10px",
          display: isSmallDevice && selectedSession ? "none" : undefined,
          overflow: "auto",
        }}
      >
        <TextField
          label="Search visits"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginBottom: "20px" }}
        />
        <FormControl>
          <InputLabel id="date-select-label">Válassz egy dátumot</InputLabel>
          <Select
            labelId="date-select-label"
            value={selectedDate}
            label="Válassz egy dátumot"
            onChange={handleDateChange}
          >
            {lastTenDays.map((date) => (
              <MenuItem key={date} value={date}>
                {date}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {sortedDailyData.length > 0 ? (
          <List dense>
            {sortedDailyData.map(({ sessionId, sessionData }) => (
              <ListItemButton
                key={sessionId}
                selected={selectedSession === sessionId}
                onClick={() => handleListItemClick(sessionId)}
              >
                <ListItemText
                  primary={sessionData.name || "Anonymus"}
                  secondary={`${
                    Object.keys(sessionData?.visits ?? {}).length
                  } visits`}
                />
              </ListItemButton>
            ))}
          </List>
        ) : (
          <p>No data available for this date.</p>
        )}
      </Box>

      <Box
        sx={{
          flex: isSmallDevice ? "1" : "0 0 70%",
          padding: "10px",
          background: "#efefef",
          display: isSmallDevice && !selectedSession ? "none" : undefined,
          overflow: "auto",
        }}
      >
        {dailyData && (
          <SessionDetails
            sessionInfo={selectedSession ? dailyData[selectedSession] : null}
            isSmallDevice={isSmallDevice}
            onClose={() => setSelectedSession(null)}
          />
        )}
      </Box>
    </Box>
  );
};

export default DailyVisitsList;
