import React, { useEffect, useState } from "react";

import "./Timeline.css";
import {
  EventDetail,
  useDeleteEvent,
  useGetEventsForElapsedDaysRange,
} from "data/events";
import TimelineEvent, { getTimelineProperties } from "./TimelineEvent";
import ConfirmDialog from "components/common/ConfirmDialog/ConfirmDialog";
import { useConfirmDialog } from "components/common/ConfirmDialog/useConfirmDialog";
import CircularProgress from "@mui/material/CircularProgress";

export interface Event extends EventDetail {
  timestamp: string;
}
type EventWithUrl = Event & { url: string };

const Timeline: React.FC = () => {
  const {
    data: eventDays,
    isLoading,
  } = useGetEventsForElapsedDaysRange({ fromDay: 0, toDay: 14 });
  const [events, setEvents] = useState<Event[]>([]);
  const [dialogProps, setDialogProps, setDialogOpen] = useConfirmDialog();
  const { mutateAsync: deleteEvent } = useDeleteEvent();

  useEffect(() => {
    if (eventDays) {
      const _events: Event[] = eventDays
        .map((eventDay) =>
          Object.keys(eventDay).map((key) => ({
            timestamp: key,
            ...eventDay[key],
          }))
        )
        .flat()
        .sort((a, b) => Number(b.timestamp) - Number(a.timestamp));
  
      // Augment events with URLs
      const eventsWithUrls:  EventWithUrl[] = _events.map((event) => ({
        ...event,
        url: getTimelineProperties(event).url,
      }));
  
      // Filter out older events with the same URL
      const uniqueByUrlEvents:  EventWithUrl[] = eventsWithUrls.reduce(
        (acc: EventWithUrl[], event) => {
          const existingEvent = acc.find((e) => e.url === event.url);
          if (!existingEvent) {
            acc.push(event);
          }
          return acc;
        },
        []
      );
  
      setEvents(uniqueByUrlEvents);
    }
  }, [eventDays]);

  const handleDeletePressed = (timestamp: string) => {
    setDialogProps({
      title: "Megerősítés",
      children: <div>Biztos törölni akarod az eseményt?</div>,
      actionButtonText: "Törlöm",
      onConfirm: () => {
        const timestampNumber = parseInt(timestamp, 10);
        deleteEvent(timestampNumber).then(() => {
          setDialogOpen(false);
        });
      },
    });
    setDialogOpen(true);
  };

  if (isLoading) return <CircularProgress size={60} />;

  return (
    <div className="timeline-container">
      <div className="timeline-line" />
      <div className="timeline-events">
        {events.map((event, index) => (
          <TimelineEvent
            key={event.timestamp}
            event={event}
            onEventDelete={handleDeletePressed}
          />
        ))}
      </div>
      <ConfirmDialog {...dialogProps} />
    </div>
  );
};

export default Timeline;
