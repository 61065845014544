// DrawerComponent.js
import React, { FC } from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import SettingsIcon from "@mui/icons-material/Settings";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HomeIcon from "@mui/icons-material/Home";
import { FarmerIconMui, GrapeIconMui } from "../common/Icons";
import ArticleIcon from "@mui/icons-material/Article";
import Badge from "@mui/material/Badge";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import DrawerListItem from "./DrawerListItem";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { Roles } from "hooks/useRights";

export const menuNamesAndRoutes = [
  { name: "Szőlők", path: "/szolok", role: Roles.VIEW },
  { name: "Gazdák", path: "/gazdak", role: Roles.SIGNED },
  { name: "Beállítás", path: "/beallitas", role: Roles.SIGNED },
  { name: "Segítség", path: "/posts/segitseg/segitseg", role: Roles.VIEW },
  { name: "Cikkek", path: "/posts", role: Roles.VIEW },
  { name: "Szőlőim", path: "/szoloim", role: Roles.SIGNED },
  { name: "Gazda", path: "/gazda", role: Roles.SIGNED },
  { name: "Szőlő", path: "/szolo", role: Roles.VIEW },
  { name: "Listáim", path: "/listaim", role: Roles.SIGNED },
  { name: "Üzenetek", path: "/uzenetek", role: Roles.SIGNED },
  { name: "Szőlő lista", path: "/lista", role: Roles.VIEW },
  { name: "Főoldal", path: "/", role: Roles.VIEW },
  { name: "Keresés", path: "/szolo-kereses", role: Roles.VIEW },
  { name: "Admin", path: "/admin", role: Roles.ADMIN },
  { name: "Szerkesztendő", path: "/szolo-szerkesztendo", role: Roles.EDITOR },
];

export const getNameByPath = (path: string) => {
  let record = menuNamesAndRoutes.find((row) => row.path === path);

  if (!record) {
    const pathRoot = `/${path.split("/")[1]}`;
    record = menuNamesAndRoutes.find((row) => row.path === pathRoot);
  }

  return record ? record.name : "";
};

interface DrawerComponentProps {
  pathname: string;
  hasRole: (role: Roles | undefined) => boolean;
  isIconView?: boolean;
}

const DrawerComponent: FC<DrawerComponentProps> = ({
  pathname,
  hasRole,
  isIconView,
}) => (
  <div>
    <List>
      <DrawerListItem
        pathname={pathname}
        path="/"
        hasRole={hasRole}
        isIconView={isIconView}
      >
        <HomeIcon />
      </DrawerListItem>
      <DrawerListItem
        pathname={pathname}
        path="/szolo-kereses"
        hasRole={hasRole}
        isIconView={isIconView}
      >
        <SearchIcon />
      </DrawerListItem>
      <DrawerListItem
        pathname={pathname}
        path="/posts"
        hasRole={hasRole}
        isIconView={isIconView}
      >
        <Badge color="error" badgeContent="új">
          <ArticleIcon />
        </Badge>
      </DrawerListItem>
      <DrawerListItem
        pathname={pathname}
        path="/szolok"
        hasRole={hasRole}
        isIconView={isIconView}
      >
        <GrapeIconMui />
      </DrawerListItem>
      <DrawerListItem
        pathname={pathname}
        path="/gazdak"
        hasRole={hasRole}
        isIconView={isIconView}
      >
        <FarmerIconMui />
      </DrawerListItem>
      <DrawerListItem
        pathname={pathname}
        path="/uzenetek"
        hasRole={hasRole}
        isIconView={isIconView}
      >
        <ChatBubbleOutlineIcon />
      </DrawerListItem>
      <DrawerListItem
        pathname={pathname}
        path="/posts/segitseg/segitseg"
        hasRole={hasRole}
        isIconView={isIconView}
      >
        <HelpOutlineIcon />
      </DrawerListItem>
    </List>
    <Divider />
    <List>
      <DrawerListItem
        pathname={pathname}
        path="/beallitas"
        hasRole={hasRole}
        isIconView={isIconView}
      >
        <SettingsIcon />
      </DrawerListItem>
      <DrawerListItem
        pathname={pathname}
        path="/szoloim"
        hasRole={hasRole}
        isIconView={isIconView}
      >
        <ListAltIcon />
      </DrawerListItem>
      <DrawerListItem
        pathname={pathname}
        path="/listaim"
        hasRole={hasRole}
        isIconView={isIconView}
      >
        <FormatListNumberedIcon />
      </DrawerListItem>
    </List>
    {hasRole(Roles.EDITOR) && <Divider />}
    <List>
      <DrawerListItem
        pathname={pathname}
        path="/szolo-szerkesztendo"
        hasRole={hasRole}
        isIconView={isIconView}
      >
        <EditIcon />
      </DrawerListItem>
      <DrawerListItem
        pathname={pathname}
        path="/admin"
        hasRole={hasRole}
        isIconView={isIconView}
      >
        <AdminPanelSettingsIcon />
      </DrawerListItem>
    </List>
  </div>
);

export default DrawerComponent;
