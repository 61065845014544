import React, { FC, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Link from "components/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Zoom from "@mui/material/Zoom";
import { useConfirmDialog } from "../common/ConfirmDialog/useConfirmDialog";
import ConfirmDialog from "../common/ConfirmDialog/ConfirmDialog";
import GrapeEditPanel, {
  GrapeEditForm,
  grapeEditformId,
} from "../GrapeEditPanel/GrapeEditPanel";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "@firebase/storage";
import { fakePunycodeDecode, getImageUrl } from "utils/general";
import {
  useGetGrape,
  GET_GRAPE,
  Grape,
  useSetGrapeWithHistory,
  GrapeRecommendation,
} from "data/grape";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { Roles, UserAction, useRights } from "hooks/useRights";
import ImageCarousel from "../common/ImageCarousel";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import { useGetShortUsers } from "data/user";
import { Timestamp } from "firebase/firestore";
import { Helmet } from "react-helmet";

import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import gfm from "remark-gfm";
import { CustomImage, CustomLink } from "../Post/MarkdownRender";
import { useViewCounter } from "data/counter";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Chip from "@mui/material/Chip";
import { useGetTags } from "data/tag";
import RipeningChartNew from "../RipeningChartNew";
import { useMediaQuery, Theme } from "@mui/material";
import { generateTagUrl } from "../GrapeSearch/utility";
import Skeleton from "@mui/material/Skeleton";
import useScrollPosition from "hooks/useScrollPosition";
import RecommendedGrapes from "./RecommendedGrapes";

const reloadImage = (
  delaySec: number,
  setLoading: (loading: boolean) => void,
  queryClient: QueryClient
) => {
  setTimeout(() => {
    queryClient.invalidateQueries({ queryKey: [GET_GRAPE] });
    setLoading(false);
  }, delaySec * 1000);
};

const DESC_WIDTH = 350;

const GrapePage: FC = () => {
  const { id: grapeId } = useParams<{ id: string }>();
  const [originalGrape, setOriginalGrape] = React.useState<Grape>();
  const { data: grape, isLoading } = useGetGrape(grapeId!);
  const queryClient = useQueryClient();
  const { setGrapeWithLog } = useSetGrapeWithHistory(grapeId!);
  const [loading, setLoading] = React.useState(false);
  const [dialogProps, setDialogProps, setDialogOpen] = useConfirmDialog();
  const { hasRight, hasRole } = useRights();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [imageUrl, setImageUrl] = React.useState<string | null>(null);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const { data: users, isLoading: isUsersLoading } = useGetShortUsers(!!anchorEl && hasRole(Roles.SIGNED));
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const navigate = useNavigate();
  const viewCount = useViewCounter("grapeCount", grapeId);
  const { data: tags } = useGetTags();
  const scrollRef = useScrollPosition(true, true); 

  const tagsMap = useMemo(() => {
    const map = new Map();
    tags?.forEach((tag) => {
      map.set(tag.id, tag);
    });
    return map;
  }, [tags]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  React.useEffect(() => {
    if (grape) {
      setTitle(
        `${
          grape.name +
          (grape.additionalNames ? " - " + grape.additionalNames : "") +
          (grape.russianName ? " (" + grape.russianName + ")" : "")
        } fajta leírása - Szőlőink közösségi szőlő katalógus`
      );
      setOriginalGrape(grape);
      const { ripeningFrom: from, ripeningTo: to } = grape;
      const ripeningTime = from
        ? to
          ? `${from} - ${to} nap`
          : `${from} nap`
        : "";
      const description = `${grape.name}, ${
        grape.description
      }, Érési idő: ${ripeningTime}, További nevek: ${
        grape.additionalNames ?? ""
      } , ${
        grape.russianName ?? ""
      }, Szőlő leírása, közösségi szőlő katalógus}`;
      setDescription(description);
    }
  }, [grape]);

  React.useEffect(() => {
    if (!isLoading && !grape) {
      navigate("/404");
    }
  }, [grape, isLoading, navigate]);

  //@ts-ignore
  const handleImageSelected = (e, grape: GrapeOld, _setGrapeWithLog) => {
    if (e.target.files[0]) {
      const storage = getStorage();
      const image = e.target.files[0];
      const storageRef = ref(storage, `grapes/${grapeId!}`);
      uploadBytes(storageRef, image).then((snapshot) => {
        setLoading(true);
        reloadImage(10, setLoading, queryClient);
        const newGrape = { ...grape, images: [grapeId] };
        _setGrapeWithLog(originalGrape!, newGrape);
      });
    }
  };

  React.useEffect(() => {
    setImageUrl(getImageUrl(`${grapeId}_600x600`));
    if (grapeId) {
      const imageName = grapeId;
      const fetchImageUrl = async () => {
        try {
          const storageRef = ref(
            getStorage(),
            `/grapes/thumbs/${imageName}_600x600`
          );
          const url = await getDownloadURL(storageRef);
          setImageUrl(url);
        } catch (error) {
          console.log("Error getting the image URL:", error);
        }
      };

      fetchImageUrl();
    }
  }, [grapeId]);

  const handleEditFormSave = (data: GrapeEditForm, recommendedGrapes: GrapeRecommendation[]) => {
    let { links, ...rest } = data;
    let dataToSave: Grape = {
      ...rest,
      owners: originalGrape!.owners ? originalGrape!.owners : {},
      created:
        originalGrape!.created ??
        new Timestamp(new Date("2022-01-08").getTime() / 1000, 0),
    };
    if (grape!.images) {
      dataToSave.images = grape!.images;
    }
    if (grape?.notFinished && !dataToSave.notFinished) {
      dataToSave.created = new Timestamp(new Date().getTime() / 1000, 0);
    }
    if (data.links && data.links.length > 0) {
      dataToSave["links"] = data.links?.map((lo) => lo.url);
    }

    setGrapeWithLog(originalGrape!, { ...dataToSave, recommendedGrapes }).then(() => {
      setDialogOpen(false);
    });
  };

  const handleEditPressed = () => {
    setDialogProps({
      title: "Szőlő szerkesztése",
      formId: grapeEditformId,
      children: (
        <GrapeEditPanel grape={grape!} grapeId={grapeId ?? ""} onFormSave={handleEditFormSave} />
      ),
      onConfirm: () => {},
      fullScreen: true,
    });
    setDialogOpen(true);
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const TextSkeleton = ({
    width,
    height = 32,
  }: {
    width?: number;
    height?: number;
  }) => <Skeleton variant="text" width={width} height={height} />;

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
      component="div"
    >
      <Box ref={scrollRef} sx={{ width: "100%", overflow: "auto" }}>
        <Helmet>
          <title>{title}</title>
          {description && <meta name="description" content={description} />}
        </Helmet>
        <Card
          sx={{
            maxWidth: { xs: "800px", lg: "1200px" },
            width: "100%",
            background: "white",
            margin: "auto",
            position: "relative",
          }}
          component="div"
        >
          <>
            <main>
              <Grid
                container
                sx={{
                  minHeight: "60px",
                  padding: "20px 20px 0px 20px",
                }}
              >
                <Grid item xs={12}>
                  {!grape ? (
                    <TextSkeleton width={300} height={32} />
                  ) : (
                    <Typography
                      variant={isSmall ? "h6" : "h5"}
                      noWrap
                      component="div"
                      sx={{
                        width: "100%",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {`${grape?.name}${
                        grape?.russianName ? " / " + grape?.russianName : ""
                      }`}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  {!grape ? (
                    <TextSkeleton width={500} height={24} />
                  ) : (
                    grape?.additionalNames && (
                      <Typography
                        variant="body1"
                        color="GrayText"
                        noWrap
                        component="div"
                        sx={{
                          width: "100%",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {grape?.additionalNames}
                      </Typography>
                    )
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    flexWrap: "wrap",
                  }}
                >
                  <VisibilityOutlinedIcon />
                  <Typography
                    variant="body1"
                    color="GrayText"
                    noWrap
                    component="div"
                  >
                    {viewCount}
                  </Typography>
                  {(grape?.tags ?? []).map((tagId) => {
                    const tag = tagsMap.get(tagId);

                    return tag ? (
                      <Chip
                        key={tagId}
                        label={tag?.name ?? "töltés..."}
                        component={Link}
                        to={`/szolo-kereses/?cimkek=${generateTagUrl([tag])}`}
                        clickable
                        color="primary"
                        variant="outlined"
                        sx={{
                          fontSize: "0.75rem",
                          height: "24px",
                          margin: "0px",
                          padding: "0 1px",
                        }}
                      />
                    ) : (
                      <></>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={10}
                sx={{
                  m: "20px",
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                }}
              >
                <Box sx={{ flex: "1 1 auto" }} component="div">
                  <ImageCarousel
                    grape={grape}
                    imageUrl={imageUrl ?? undefined}
                    key={"" + loading}
                  >
                    <>
                      {!loading && hasRight(UserAction.GRAPE_IMAGE_UPLOAD) && (
                        <Zoom in={true}>
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: 16,
                              right: 16,
                            }}
                            component="div"
                          >
                            <label htmlFor="icon-button-file">
                              <input
                                hidden
                                onChange={(e) =>
                                  handleImageSelected(e, grape, setGrapeWithLog)
                                }
                                accept="image/*"
                                id="icon-button-file"
                                type="file"
                              />
                              <Button
                                sx={{
                                  height: "60px",
                                  borderRadius: "50%",
                                  width: "60px",
                                }}
                                variant="contained"
                                component="div"
                              >
                                <AddIcon />
                              </Button>
                            </label>
                          </Box>
                        </Zoom>
                      )}
                      {loading && (
                        <Box
                          sx={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          component="div"
                        >
                          <CircularProgress size={80} />
                        </Box>
                      )}
                    </>
                  </ImageCarousel>
                </Box>

                <Grid
                  sx={{
                    flex: { lg: `0 0 ${DESC_WIDTH}px` },
                    margin: {
                      xs: "30px 10px 10px 10px",
                      lg: "0px 30px 10px 30px",
                    },
                    justifyContent: "flex-start",
                    alignContent: "flex-start",
                  }}
                  container
                  gap={1}
                  component="div"
                >
                  <Grid item xs={12}>
                    <Typography
                      color="text.primary"
                      sx={{ display: "inline" }}
                      variant="h6"
                      noWrap
                      component="div"
                    >
                      {`Érés:`}
                    </Typography>
                      <RipeningChartNew
                        from={grape?.ripeningFrom}
                        to={grape?.ripeningTo}
                        fill="white"
                      />
                  </Grid>

                  <Grid sx={{ width: "100%" }}>
                    <Typography
                      color="text.primary"
                      variant="h6"
                      noWrap
                      component="div"
                    >
                      {`Leírás:`}
                    </Typography>
                    {!grape ? (
                      <Skeleton variant="rectangular" height={400} />
                    ) : (
                      <ReactMarkdown
                        components={{
                          a: CustomLink,
                          img: CustomImage,
                        }}
                        remarkPlugins={[gfm]}
                      >
                        {grape?.description ?? ""}
                      </ReactMarkdown>
                    )}
                  </Grid>

                  <Grid sx={{ width: "100%" }}>
                    <Typography
                      color="text.primary"
                      variant="h6"
                      noWrap
                      component="div"
                    >
                      {`Külső hivatkozások:`}
                    </Typography>

                    {!grape ? (
                      <>
                        <TextSkeleton />
                        <TextSkeleton />
                      </>
                    ) : (
                      (grape?.links ?? []).map((link) => (
                        <Box
                          component="div"
                          sx={{
                            textOverflow: "ellipsis",
                            width: {
                              xs: "100%",
                              lg: DESC_WIDTH - 40,
                            },
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                          key={link}
                        >
                          <Typography
                            color="#007BFF"
                            onClick={() => openInNewTab(link)}
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            variant="body1"
                            noWrap
                            component="div"
                          >
                            {fakePunycodeDecode(link)}
                          </Typography>
                        </Box>
                      ))
                    )}
                  </Grid>

                  <Grid sx={{ width: "100%" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        color="text.secondary"
                        variant="body1"
                        component="div"
                      >
                        {`${
                          grape?.owners ? Object.keys(grape?.owners).length : 0
                        } gazdának van ilyen szőlője`}
                      </Typography>
                      {grape?.owners &&
                        Object.keys(grape.owners).length > 0 && (
                          <>
                            <IconButton
                              color="primary"
                              aria-label="gazdák listája"
                              onClick={handleClick}
                            >
                              <InfoIcon />
                            </IconButton>
                            <Popover
                              onClose={() => setOpen(false)}
                              open={open}
                              anchorEl={anchorEl}
                            >
                              <Box
                                sx={{
                                  border: 1,
                                  p: 1,
                                  bgcolor: "background.paper",
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                }}
                              >
                                {hasRight(UserAction.USER_MENU_SHOW) ? (
                                  isUsersLoading ? (
                                    <CircularProgress size={15} />
                                  ) : users ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {Object.keys(grape.owners).map((id) => (
                                        <Link to={`/gazda/${id}`} key={id}>
                                          {users[id]?.name ?? ""}
                                        </Link>
                                      ))}
                                    </div>
                                  ) : (
                                    <div>Nincsenek felhasználók</div>
                                  )
                                ) : (
                                  <div>A gazdák megtekintéséhez be kell jelentkezni</div>
                                )}
                              </Box>
                            </Popover>
                          </>
                        )}
                    </div>
                  </Grid>
                  {hasRight(UserAction.GRAPE_EDIT) && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        marginTop: "20px",
                        gap: "15px",
                      }}
                    >
                      <Link to={`tortenet`}>Szerk. történet</Link>
                      <Button
                        onClick={handleEditPressed}
                        color="primary"
                        size="small"
                        startIcon={<EditIcon />}
                        variant="outlined"
                      >
                        Szerkesztés
                      </Button>
                    </Grid>
                  )}
                </Grid>

                <ConfirmDialog {...dialogProps} />
              </Grid>
            </main>
            {grape?.recommendedGrapes && grape.recommendedGrapes.length > 0 && (
              <RecommendedGrapes recommendedGrapes={grape.recommendedGrapes} />
            )}
          </>
          {isLoading && <LinearProgress />}

          {!isLoading && !grape && (
            <Grid
              item
              xs={12}
              sx={{ textAlign: "start", margin: "20px" }}
            >{`${grapeId} - nem található`}</Grid>
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default GrapePage;
