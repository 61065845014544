import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const emulator = false;

let firebaseConfig = {
  apiKey: "AIzaSyC2uP8h_tSKk_AQPK-0HNDv47UqqS9brqA",
  authDomain: "szoloink.hu",
  databaseURL: "https://szoloim-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "szoloim",
  storageBucket: "szoloim.appspot.com",
  messagingSenderId: "13769290166",
  appId: "1:13769290166:web:93c2972ef5258f8bbdd117",
  measurementId: "G-BYNVJD6Z1Z",
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage();
const realtime = getDatabase(firebaseApp);
const auth = getAuth();

if (emulator) {
  connectFirestoreEmulator(db, "localhost", 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(getFunctions(firebaseApp), "localhost", 5001);
}

export { db, storage, firebaseApp, realtime, analytics };
