import React from 'react';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import { useSearch } from "hooks/useSearch";
import GrapeActionItem from "components/common/GrapeEditItem";

interface GrapeLinkSelectionMenuProps {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  onLinkSelect: (grapeId: string, grapeName: string) => void;
  searchTerm: string;
}

const GrapeLinkSelectionMenu: React.FC<GrapeLinkSelectionMenuProps> = ({ 
  anchorEl, 
  setAnchorEl, 
  onLinkSelect, 
  searchTerm 
}) => {
  const menuOpen = Boolean(anchorEl);
  const { setSearchTerm, grapes, results} = useSearch();

  React.useEffect(() => {
    setSearchTerm(searchTerm);
  }, [searchTerm, setSearchTerm]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={() => setAnchorEl(null)}
    >
      {grapes && results.map((grapeId) => (
        <MenuItem
          key={grapeId}
          onClick={() => onLinkSelect(grapeId , grapes[grapeId].name)}
        >
          <GrapeActionItem
            grape={grapes[grapeId]}
            id={grapeId}
            onGrapeAction={() => {}}
          >
            <AddIcon />
          </GrapeActionItem>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default GrapeLinkSelectionMenu;
