import React, { useContext, FC, useState, useMemo, PropsWithChildren } from "react";
import { onValue, ref } from "firebase/database";
import { realtime } from "utils/firebase";
import {
  getAuth,
  IdTokenResult,
  onAuthStateChanged,
  User,
} from "firebase/auth";

interface ContextValue {
  authUser: User | null | undefined;
  token: IdTokenResult | undefined;
  authLoading: boolean;
}

export const StateContext = React.createContext<ContextValue | undefined>(
  undefined
);

const auth = getAuth();

export const StateProvider: FC<PropsWithChildren> = ({ children }) => {
  const [authUser, setAuthUser] = useState<User | null>();
  const [token, setToken] = useState<IdTokenResult>();
  const [authLoading, setAuthLoading] = useState(true);

  React.useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, (user) => {
      if (user) {
        const metadataRef = ref(
          realtime,
          "metadata/" + user.uid + "/refreshTime"
        );
        onValue(metadataRef, (snapshot) => {
          user.getIdToken(true).then(() => {
            user.getIdTokenResult().then((token) => {
              setToken(token);
              setAuthLoading(false);
            });
          });
        });
        setAuthUser(user);
      } else {
        setAuthUser(user);
        setAuthLoading(false);
      }
    });

    return () => unregisterAuthObserver();
  }, []);

  const value = useMemo(
    () => ({ authUser, token, authLoading }),
    [authUser, token, authLoading]
  );

  return (
    <StateContext.Provider value={value}>{children}</StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext);
