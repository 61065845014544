import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import {
  query,
  collection,
  getDocs,
  doc,
  setDoc,
  Timestamp,
} from "@firebase/firestore";
import { db } from "../utils/firebase";
import { Grape } from "./grape";
import isEqual from "lodash/isEqual";

export interface GrapeHistory {
  userName: string;
  userId: string;
  date: Timestamp;
  grape: Partial<Grape>;
}

export const GRAPE_HISTORY = "GRAPE_HISTORY";

const fetchGrapeHistory = async (grapeId: string): Promise<GrapeHistory[]> => {
  const q = query(collection(db, "grapes", grapeId, "history"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => doc.data() as GrapeHistory);
};

export const useGrapeHistoryQuery = (
  grapeId: string
): UseQueryResult<GrapeHistory[], Error> => {
  return useQuery<GrapeHistory[], Error>({
    queryKey: [grapeId, GRAPE_HISTORY],
    queryFn: () => fetchGrapeHistory(grapeId),
  });
};

type Key = keyof Partial<Grape>;

const simpleProperties: Key[] = [
  "name",
  "images",
  "ripeningFrom",
  "ripeningTo",
  "russianName",
  "additionalNames",
  "description",
  "notFinished",
  "shouldEdited",
  "editorNote",
  "tags",
];

export const createDeltaGrape = (
  original: Grape,
  current: Grape
): Partial<Grape> => {
  let delta: GrapeHistory["grape"] = {};
  simpleProperties.forEach((prop) => {
    if (current[prop] && !isEqual(original?.[prop], current[prop])) {
      delta[prop] = current[prop] as any;
    }
  });

  (current.links || []).forEach((link, index) => {
    if (link !== original.links?.[index]) {
      if (!delta.links) {
        delta.links = [link];
      } else {
        delta.links.push(link);
      }
    }
  });

  return delta;
};

const useSetGrapeHistory = (grapeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (grapeHistory: GrapeHistory) => {
      const timestamp = Math.round(new Date().getTime() / 1000);
      const ref = doc(
        collection(db, "grapes", grapeId, "history"),
        `${timestamp}`
      );
      return setDoc(ref, grapeHistory);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [grapeId, GRAPE_HISTORY] });
    },
  });
};

export default useSetGrapeHistory;
