import * as yup from "yup";

const initYupMessages = () => {
  yup.setLocale({
    mixed: {
      required: "Kötelező",
      notType: function notType({ type }: { type: string }) {
        switch (type) {
          case "number":
            return "Csak szám lehet";
          case "string":
            return "Csak szöveg adható meg";
          default:
            return "Nem megfelelő típus";
        }
      },
    },
    string: {
      min: ({ min }: { min: number }) => `Minimális hosszúság: ${min}`,
      max: ({ max }: { max: number }) => `Maximális hosszúság: ${max}`,
      url: "Nem érvényes hivatkozás",
    },
    number: {
      min: ({ min }: { min: number }) => `Legalább ${min} legyen`,
      max: ({ max }: { max: number }) => `Legfeljebb ${max} lehet`,
      integer: "Csak egész szám lehet",
    },
  });
};

export default initYupMessages;
