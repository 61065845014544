import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { filterPostsByTag, useGetPosts } from "data/posts";
import { ListPostItem } from "./ListPostItem";

const TaggedPostsList: React.FC = () => {
  const { tag } = useParams();
  const { data: posts, isLoading, error } = useGetPosts({});
  const navigate = useNavigate();

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const filteredPosts = isLoading ? [] : filterPostsByTag(posts!, tag!);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
      component="div"
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          padding: "10px",
          background: "white",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="back"
          onClick={() => navigate(-1)} // go back to the previous page
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          {`Kiválasztott címke: ${tag}`}
        </Typography>
      </Grid>
      <Box sx={{ overflow: "auto", display: "flex", flexDirection: "column" }} >
        {isLoading
          ? "Loading..."
          : filteredPosts.map((post) => (
              <ListPostItem key={post.slug} post={post} />
            ))}
      </Box>
    </Box>
  );
};

export default TaggedPostsList;
