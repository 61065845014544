import { clearScrollPosition } from 'hooks/useScrollPosition';
import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

const Link: React.FC<RouterLinkProps> = (props) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    clearScrollPosition(props.to as string);  
    if (props.onClick) {
      props.onClick(event);
    }
  };

  return <RouterLink {...props} onClick={handleClick} />;
};

export default Link;
