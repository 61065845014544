import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import {
  doc,
  getDoc,
  Timestamp,
  setDoc,
  collection,
  query,
  getDocs,
} from "@firebase/firestore";
import { db } from "../utils/firebase";
import { oneHour } from "./grape";

export interface User {
  displayName: string;
  country?: string;
  county?: string;
  city?: string;
  aboutMe?: string;
  myGrapes?: { [key: string]: boolean };
  editor?: boolean;
  admin?: boolean;
  public?: boolean;
  messagingDisabled?: boolean;
  photoURL: string;
  created?: Timestamp;
  contactInfo?: string;
}

export type SetupUser = Pick<
  User,
  | "country"
  | "county"
  | "city"
  | "aboutMe"
  | "public"
  | "contactInfo"
  | "messagingDisabled"
>;

export interface ShortUser
  extends Pick<
    User,
    | "aboutMe"
    | "country"
    | "county"
    | "city"
    | "photoURL"
    | "created"
    | "contactInfo"
    | "messagingDisabled"
  > {
  name: string;
  disabled?: boolean;
  grapeCount: number;
}

export interface ShortUserList {
  [key: string]: ShortUser;
}

export interface Grapes {
  [key: string]: string | undefined;
}
export interface UserGrapeListElement {
  grapeId: string;
  note: string | undefined;
}

export interface UserGrapeList {
  description: string;
  grapeArray: UserGrapeListElement[];
  public: boolean;
  name: string;
}

export const GET_USER = "GET_USER";
export const SHORT_USERS = "SHORT_USERS";
export const GRAPE_LIST = "GRAPE_LIST";
export const GRAPE_LISTS = "GRAPE_LISTS";

const fetchUser = async (id: string): Promise<User> => {
  const docRef = doc(db, "users", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as User;
  } else {
    throw new Error("User not found");
  }
};

export const useGetUser = (
  id: string,
  enabled: boolean
): UseQueryResult<User, Error> => {
  return useQuery<User, Error>({
    queryKey: [GET_USER, id],
    queryFn: () => fetchUser(id),
    enabled,
  });
};

export const useSetUser = (
  id: string
): UseMutationResult<void, unknown, User> => {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, User>({
    mutationFn: async (userData) => {
      const userRef = doc(db, "users", id);
      await setDoc(userRef, userData, { merge: true });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_USER, id],
      });
    },
  });
};

export const useUpdateUser = (userId: string) => {
  const queryClient = useQueryClient();

  const updateUser = async (userData: Partial<User>) => {
    const userRef = doc(db, "users", userId);
    await setDoc(userRef, userData, { merge: true });
  };

  return useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_USER, userId] });
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [SHORT_USERS] });
      }, 5000);
    },
  });
};

const fetchShortUsers = async (): Promise<ShortUserList> => {
  const docRef = doc(db, "pages", "userList");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as ShortUserList;
  } else {
    throw new Error("Short users data not found");
  }
};

export const useGetShortUsers = (
  enabled = true
): UseQueryResult<ShortUserList, Error> => {
  return useQuery<ShortUserList, Error>({
    queryKey: [SHORT_USERS],
    queryFn: fetchShortUsers,
    enabled,
    staleTime: oneHour,
  });
};

const fetchGrapeList = async (
  userId: string,
  listId: string
): Promise<UserGrapeList> => {
  const docRef = doc(db, "users", userId, "lists", listId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as UserGrapeList;
  } else {
    throw new Error("Grape list not found");
  }
};

export const useGetList = (
  userId: string,
  listId: string
): UseQueryResult<UserGrapeList, Error> => {
  return useQuery<UserGrapeList, Error>({
    queryKey: [GRAPE_LIST, userId, listId],
    queryFn: () => fetchGrapeList(userId, listId),
  });
};

export const useSetGrapeList = (
  userId: string,
  listId: string
): UseMutationResult<void, unknown, UserGrapeList> => {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, UserGrapeList>({
    mutationFn: async (grapeListData) => {
      const grapeListRef = doc(
        collection(db, "users", userId, "lists"),
        listId
      );
      await setDoc(grapeListRef, grapeListData, { merge: true });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GRAPE_LIST, userId, listId],
      });
    },
  });
};

export interface GrapeListWithID extends UserGrapeList {
  id: string;
}

const fetchGrapeLists = async (userId: string): Promise<GrapeListWithID[]> => {
  const q = query(collection(db, "users", userId, "lists"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...(doc.data() as UserGrapeList),
  }));
};

export const useUserGrapeLists = (
  userId: string,
  enabled: boolean
): UseQueryResult<GrapeListWithID[], Error> => {
  return useQuery<GrapeListWithID[], Error>({
    queryKey: [GRAPE_LISTS, userId],
    queryFn: () => fetchGrapeLists(userId),
    enabled,
  });
};
