import {
  UserConversation,
  useGetUserConversations,
  useUnreadMessagesListener,
} from "data/messages-realtime";
import { FC, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Link from "components/Link";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { Theme, useMediaQuery } from "@mui/material";
import { useGetShortUsers } from "data/user";
import { useActiveConversationId } from "components/MessagePage/hooks";

interface MessageIconProps {
  userId: string;
}

interface DisplayUserConversation extends UserConversation {
  id: string;
  label: string;
}

const getOtherParticipantId = (
  conversation: UserConversation,
  userId: string
) => {
  return conversation.participantIds?.find(
    (participantId) => participantId !== userId
  );
};

const MessageIcon: FC<MessageIconProps> = ({ userId }) => {
  const { unreadConversationIds } = useUnreadMessagesListener(userId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: userConversations } = useGetUserConversations(
    anchorEl ? userId : ""
  );
  const [conversations, setConversations] = useState<DisplayUserConversation[]>(
    []
  );
  const { data: users } = useGetShortUsers(!!anchorEl);

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const getActiveConversationId = useActiveConversationId();

  useEffect(() => {
    if (userConversations && users) {
      const _conversations: DisplayUserConversation[] = Object.entries(
        userConversations
      ).map(([id, conversation]) => {
        return {
          id,
          label:
            conversation.name ??
            users[getOtherParticipantId(conversation, userId) ?? ""]?.name ??
            "Anonymus",
          ...conversation,
        };
      });
      setConversations(_conversations);
    }
  }, [userConversations, userId, users]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const unreadConversationCount = unreadConversationIds.filter(
    (id) => id !== getActiveConversationId()
  ).length;

  const unreadConversations =
    conversations?.filter(
      (conversation) =>
        unreadConversationIds.includes(conversation.id) &&
        conversation.id !== getActiveConversationId()
    ) ?? [];

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={unreadConversationCount} color="error">
          <ChatBubbleOutlineIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {unreadConversations && unreadConversations.length > 0 ? (
          unreadConversations.map((conversation) => {
            return (
              <MenuItem key={conversation.id} onClick={handleClose}>
                <Link
                  to={`/uzenetek/${conversation.id}`}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        maxWidth: isSmall ? "250px" : "600px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {conversation.label}
                    </div>
                    <Badge
                      variant="dot"
                      color="error"
                      invisible={
                        !(unreadConversationIds ?? []).includes(conversation.id)
                      }
                      style={{ marginLeft: "15px" }}
                    >
                      <div />
                    </Badge>
                  </div>
                </Link>
              </MenuItem>
            );
          })
        ) : (
          <MenuItem>
            <div style={{ color: "#a0a0a0" }}>Nincs olvasatlan üzenet</div>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default MessageIcon;
