import React, { FC, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { useStateValue } from "utils/StateProvider";
import { useGetUser } from "data/user";
import UserDataForm, { UserDataFormHandles } from "./UserDataForm";
import InnerToolBar from "components/common/InnerToolBar";

export const countries = [
  "nincs megadva",
  "Magyarország",
  "Szlovákia",
  "Románia",
  "Ukrajna",
  "Szerbia",
  "Németország",
  "Ausztria",
  "Egyesült Királyság",
  "Csehország",
];

export const counties = [
  "Budapest",
  "Bács-Kiskun",
  "Baranya",
  "Békés",
  "Borsod-Abaúj-Zemplén",
  "Csongrád-Csanád",
  "Fejér",
  "Győr-Moson-Sopron",
  "Hajdú-Bihar",
  "Heves",
  "Jász-Nagykun-Szolnok",
  "Komárom-Esztergom",
  "Nógrád",
  "Pest",
  "Somogy",
  "Szabolcs-Szatmár-Bereg",
  "Tolna",
  "Vas",
  "Veszprém",
  "Zala",
];

const SetupPage: FC = () => {
  const { authUser: user } = useStateValue()!;
  const userId = user?.uid ?? "dummy";
  const { data: userData } = useGetUser(userId, !!user);
  const formRef = useRef<UserDataFormHandles>(null);

  const handleSaveButtonClick = () => {
    formRef.current?.handleSubmit();
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
      component="div"
    >
      <Box sx={{ flex: "1 1 auto", overflow: "auto" }}>
        <Card
          sx={{
            maxWidth: { xs: "800px" },
            width: "100%",
            background: "white",
            margin: "auto",
            marginBottom: "20px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
          component="div"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              padding: "30px",
              height: "100%",
              overflow: "auto",
              flex: "1 1 auto",
            }}
          >
            {userData ? (
              <UserDataForm userData={userData} userId={userId} ref={formRef} />
            ) : (
              <CircularProgress />
            )}
          </Box>
          <InnerToolBar sx={{ marginBottom: "40px" }}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={handleSaveButtonClick}
              fullWidth
            >
              Elment
            </Button>
          </InnerToolBar>
        </Card>
      </Box>
    </Box>
  );
};

export default SetupPage;
