// SessionDetails.js
import React, { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SessionInfo } from "data/dailyVisits";

interface SessionDetailsProps {
  sessionInfo: SessionInfo | null;
  isSmallDevice: boolean;
  onClose: () => void;
}

const SessionDetails: FC<SessionDetailsProps> = ({
  sessionInfo,
  isSmallDevice,
  onClose,
}) => {
  if (!sessionInfo) return null;

  const sortedVisits = sessionInfo.visits
    ? Object.entries(sessionInfo.visits).sort((a, b) =>
        b[1].timestamp.localeCompare(a[1].timestamp)
      ) // Sort by timestamp
    : [];

  return (
    <Box sx={{position: "relative"}}>
      {isSmallDevice && (
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <Typography variant="h6">Session Details</Typography>
      <Typography>Name: {sessionInfo.name || "Anonymus"}</Typography>
      <Typography>User ID: {sessionInfo.userId || "N/A"}</Typography>
      <Typography>Device Type: {sessionInfo.deviceType}</Typography>
      <Typography>Screen Resolution: {sessionInfo.screenResolution}</Typography>
      <Typography noWrap={false} sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>User Agent: {sessionInfo.userAgent}</Typography>
      <Typography>Referrer: {sessionInfo.referrer}</Typography>
      <Typography>Referrer Page: {sessionInfo.referrerPage}</Typography>

      <Typography variant="h6" sx={{ mt: 2 }}>
        Visit History
      </Typography>
      <List dense>
        {sortedVisits.map(([visitId, visit]) => (
          <ListItem key={visitId}>
            <ListItemText
              primary={visit.url}
              secondary={new Date(visit.timestamp).toLocaleString()}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SessionDetails;
