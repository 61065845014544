import { FC, useRef } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import { useSearch } from "hooks/useSearch";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import GrapeActionItem from "components/common/GrapeEditItem";
import { ShortGrapeList } from "data/grape";


interface AddGrapeFromSearchProps {
  grapes: ShortGrapeList;
  onGrapeSelection: (grapeId: string) => void;
}

const AddGrapeFromSearch: FC<AddGrapeFromSearchProps> = ({
  grapes,
  onGrapeSelection,
}) => {
  const { searchTerm, setSearchTerm, results } = useSearch();
  const termFieldRef = useRef<HTMLInputElement>();

  const handleGrapeSelection = (grapeId: string) => {
    onGrapeSelection(grapeId);
    setSearchTerm("");
  };

  return (
    <div style={{ background: "#eeeeee", padding: "10px", marginTop: "20px" }}>
      <Typography
        style={{ marginBottom: "10px" }}
        variant="h6"
        noWrap
        component="div"
      >
        Szőlő hozzáadás
      </Typography>
      <TextField
        label="Szőlő név"
        size="small"
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value as string)}
        inputRef={termFieldRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setSearchTerm("");
                  if (termFieldRef.current) {
                    termFieldRef.current.focus();
                  }
                }}
                edge="end"
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {results.length > 0 && (
        <Box
          sx={{
            width: "100%",
            maxWidth: { xs: "400px", sm: "600px" },
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
          }}
          component="div"
        >
          {results
            .filter((id, index) => index < 8)
            .map((grapeId: string) => (
              <GrapeActionItem
                key={grapeId}
                grape={grapes[grapeId]}
                id={grapeId}
                onGrapeAction={(id: string) => handleGrapeSelection(id)}
              >
                <AddIcon />
              </GrapeActionItem>
            ))}
        </Box>
      )}
    </div>
  );
};

export default AddGrapeFromSearch;
