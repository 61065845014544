import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { FC, ReactNode } from "react";

interface StatusIconWrapperProps {
  showMuteIcon: boolean;
  children: ReactNode;
}

const StatusIconWrapper: FC<StatusIconWrapperProps> = ({
  showMuteIcon,
  children,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "-5 px",
      }}
    >
      <Box sx={{ width: "5px", overflow: "visible" }}>
        {showMuteIcon && (
          <Tooltip
            title={"A beszélgetés némítva van, nem érkezik értesítés róla"}
          >
            <NotificationsOffIcon
              sx={{ fontSize: 20, color: "gray", cursor: "pointer" }}
            />
          </Tooltip>
        )}
      </Box>
      {children}
    </div>
  );
};

export default StatusIconWrapper;
