import { useEffect, useState } from "react";
import {
  getAuth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  linkWithRedirect,
  unlink,
  getRedirectResult,
  signInWithRedirect,
  User,
} from "firebase/auth";

const PENDING_LINK_EMAIL = "pending-link-email";

export const useAuth = () => {
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [authLoading, setAuthLoading] = useState(true);
  const auth = getAuth();

  const handleFacebookLogin = () => {
    const facebookProvider = new FacebookAuthProvider();
    if (!authUser) {
      signInWithRedirect(auth, facebookProvider);
    }
  };

  const handleGoogleLogin = () => {
    const googleProvider = new GoogleAuthProvider();
    if (!authUser) {
      signInWithRedirect(auth, googleProvider);
    }
  };

  const handleLinkWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    if (authUser) {
      console.log("provider data", authUser.providerData);
      await linkWithRedirect(authUser, provider);
    }
  };

  const handleUnlinkFromFacebook = async () => {
    if (authUser) {
      await unlink(authUser, "facebook.com");
    }
  };

  const handleAuthRedirectResult = async () => {
    const pendingLinkEmail = localStorage.getItem(PENDING_LINK_EMAIL);
    if (auth.currentUser) {
      if (
        pendingLinkEmail &&
        auth.currentUser.email === pendingLinkEmail &&
        auth.currentUser.providerData.some(
          (provider) => provider.providerId === "google.com"
        )
      ) {
        // Link accounts here
        const provider = new FacebookAuthProvider();
        provider.setCustomParameters({
          login_hint: pendingLinkEmail,
        });
        localStorage.removeItem(PENDING_LINK_EMAIL);
        linkWithRedirect(auth.currentUser, provider);
      }
    } else {
      try {
        const result = await getRedirectResult(auth);
        console.log("Redirect Results", result);
      } catch (error: any) {
        console.log("!! error", JSON.stringify(error));
        if (error.code === "auth/account-exists-with-different-credential") {
          localStorage.setItem(PENDING_LINK_EMAIL, error.customData.email);
          handleGoogleLogin();
        }
      }
    }
  };

  useEffect(() => {
    if (!authLoading) {
      handleAuthRedirectResult();
    }
  }, [authLoading]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setAuthUser(user);
      setAuthLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return {
    authUser,
    authLoading,
    handleFacebookLogin,
    handleGoogleLogin,
    handleLinkWithFacebook,
    handleUnlinkFromFacebook,
  };
};

export default useAuth;
