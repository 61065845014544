import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getDocs,
  collection,
  query,
  where,
  doc,
  Timestamp,
  deleteDoc,
  orderBy,
  setDoc,
} from "firebase/firestore";
import { db } from "utils/firebase";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

export interface Post {
  id: string;
  title: string;
  content: string;
  summary: string;
  slug: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  publishedAt?: Timestamp | null;
  authorId: string;
  authorName: string;
  category: string;
  imageURL?: string;
  tags?: string[];
  state: "new" | "draft" | "approved" | "pending" | "rejected";
  rejectionReason?: string;
}

type CreatePostParams = Post;

async function createPost(data: CreatePostParams): Promise<void> {
  await setDoc(doc(db, "posts", data.id), {
    ...data,
  });
}

export function useCreatePost() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, CreatePostParams>({
    mutationFn: createPost,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
  });
}

async function createDraftPost(data: CreatePostParams): Promise<void> {
  await setDoc(doc(db, "draftPosts", data.id), {
    ...data,
  });
}

export function useCreateDraftPost() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, CreatePostParams>({
    mutationFn: createDraftPost,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["draftPosts"] });
    },
  });
}

interface FetchPostsParams {
  selectedCategory?: string;
}

const fetchPosts = async ({
  selectedCategory,
}: FetchPostsParams): Promise<Post[]> => {
  const postsCollection = collection(db, "posts");
  let postSnapshot;

  if (selectedCategory) {
    postSnapshot = await getDocs(
      query(
        postsCollection,
        where("category", "==", selectedCategory),
        orderBy("createdAt", "desc")
      )
    );
  } else {
    postSnapshot = await getDocs(
      query(postsCollection, orderBy("createdAt", "desc"))
    );
  }

  const postList: Post[] = postSnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  })) as Post[];

  return postList;
};

interface FetchDraftPostsParams {
  authorId?: string;
}

const fetchDraftPosts = async ({
  authorId,
}: FetchDraftPostsParams): Promise<Post[]> => {
  const postsCollection = collection(db, "draftPosts");
  let postSnapshot;

  if (authorId) {
    postSnapshot = await getDocs(
      query(
        postsCollection,
        where("authorId", "==", authorId),
        orderBy("createdAt", "desc")
      )
    );
  } else {
    postSnapshot = await getDocs(
      query(postsCollection, orderBy("createdAt", "desc"))
    );
  }

  const postList: Post[] = postSnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  })) as Post[];

  return postList;
};

export const useGetPosts = ({ selectedCategory }: FetchPostsParams) => {
  return useQuery<Post[], Error>({
    queryKey: ["posts", selectedCategory],
    queryFn: () => fetchPosts({ selectedCategory }),
  });
};

export const useGetDraftPosts = ({ authorId }: FetchDraftPostsParams) => {
  return useQuery<Post[], Error>({
    queryKey: ["draftPosts", { authorId }],
    queryFn: () => fetchDraftPosts({ authorId }),

    enabled: authorId !== "",
  });
};

const fetchPendingPosts = async (): Promise<Post[]> => {
  const postsCollection = collection(db, "draftPosts");
  let postSnapshot;

  postSnapshot = await getDocs(
    query(
      postsCollection,
      where("state", "==", "pending"),
      orderBy("createdAt", "desc")
    )
  );

  const postList: Post[] = postSnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  })) as Post[];

  return postList;
};

export const useGetPendingPosts = ({ isEditor }: { isEditor: boolean }) => {
  return useQuery<Post[], Error>({
    queryKey: ["pendingPosts"],
    queryFn: () => fetchPendingPosts(),
    enabled: isEditor,
  });
};

export function useDeleteDraftPost() {
  const queryClient = useQueryClient();

  async function deletePost(postId: string): Promise<void> {
    const postRef = doc(db, "draftPosts", postId);
    await deleteDoc(postRef);
  }

  return useMutation<void, Error, string>({
    mutationFn: deletePost,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["draftPosts"] });
    },
  });
}

export function useDeletePost() {
  const queryClient = useQueryClient();

  async function deletePost(postId: string): Promise<void> {
    const postRef = doc(db, "posts", postId);
    await deleteDoc(postRef);
  }

  return useMutation<void, Error, string>({
    mutationFn: deletePost,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
  });
}

export const generateTagList = (posts: Post[]): string[] => {
  const uniqueTags = new Set<string>();

  posts.forEach((post: Post) => {
    const tagsArray = post?.tags ?? []; // Split tags string into array
    tagsArray.forEach((tag) => uniqueTags.add(tag.trim())); // Trim and add each tag to the Set
  });

  return Array.from(uniqueTags);
};

export const filterPostsByTag = (posts: Post[], tag: string): Post[] => {
  return posts.filter((post) => (post?.tags ?? []).includes(tag));
};

async function fetchPost(slug: string): Promise<Post | undefined> {
  const postQuery = query(collection(db, "posts"), where("slug", "==", slug));
  const postSnapshot = await getDocs(postQuery);
  if (!postSnapshot.empty) {
    const postData = postSnapshot.docs[0].data();
    return {
      ...postData,
      id: postSnapshot.docs[0].id,
    } as Post;
  }
  //throw new Error("Post not found");
}

async function fetchDraftPost(slug: string): Promise<Post | undefined> {
  const postQuery = query(
    collection(db, "draftPosts"),
    where("slug", "==", slug)
  );
  const postSnapshot = await getDocs(postQuery);
  if (!postSnapshot.empty) {
    const postData = postSnapshot.docs[0].data();
    return {
      ...postData,
      id: postSnapshot.docs[0].id,
    } as Post;
  }
  //throw new Error("Post draft not found");
}

export function useGetPost(slug?: string) {
  return useQuery<Post | undefined, Error>({
    queryKey: ["post", slug],
    queryFn: () => fetchPost(slug ?? ""),

    enabled: !!slug, // only run the query if slug is defined
  });
}

export function useGetDraftPost(slug?: string) {
  return useQuery<Post | undefined, Error>({
    queryKey: ["draftPost", slug],
    queryFn: () => fetchDraftPost(slug ?? ""),

    enabled: !!slug, // only run the query if slug is defined
  });
}

export type PostFormInputs = Omit<
  Post,
  "id" | "createdAt" | "updatedAt" | "authorName" | "authorId"
>;

async function updatePost({
  postId,
  data,
}: {
  postId: string;
  data: PostFormInputs;
}): Promise<void> {
  const postRef = doc(db, "posts", postId);
  await setDoc(postRef, data, { merge: true });
}

export function useUpdatePost() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, { postId: string; data: PostFormInputs }>({
    mutationFn: updatePost,

    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ["posts"] }); // invalidate all queries with key "posts"
      queryClient.invalidateQueries({ queryKey: ["post", variables.postId] }); // invalidate query for the specific post
    },
  });
}

async function updateDraftPost({
  postId,
  data,
}: {
  postId: string;
  data: PostFormInputs;
}): Promise<void> {
  const postRef = doc(db, "draftPosts", postId);
  await setDoc(postRef, data, { merge: true });
}

export function useUpdateDraftPost() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, { postId: string; data: PostFormInputs }>({
    mutationFn: updateDraftPost,

    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ["draftPosts"] }); // invalidate all queries with key "posts"
      queryClient.invalidateQueries({
        queryKey: ["draftPost", variables.postId],
      }); // invalidate query for the specific post
    },
  });
}

const storage = getStorage(undefined, "gs://szoloim");

const getImages = async (postId: string): Promise<string[]> => {
  const listRef = ref(storage, `/post/${postId}`);

  const res = await listAll(listRef);
  const imageUrls: string[] = [];

  for (let item of res.items) {
    const url = await getDownloadURL(item);
    imageUrls.push(url);
  }

  return imageUrls;
};

export const useGetPostImages = (postId: string) => {
  return useQuery({
    queryKey: ["postImages", postId],
    queryFn: () => getImages(postId),
  });
};

const deleteImage = async (path: string) => {
  const storage = getStorage(undefined, "gs://szoloim");
  const storageRef = ref(storage, path);

  return deleteObject(storageRef);
};

// Create a custom hook
export const useDeleteImage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteImage,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["postImages"] });
      console.log("Image deleted successfully");
    },
    onError: (error) => {
      console.log("An error occurred during image deletion", error);
    },
  });
};

export async function checkSlugExistence(slug: string, id?: string) {
  const postsCollection = collection(db, "posts");
  const draftPostsCollection = collection(db, "draftPosts");

  const slugQueryPosts = query(postsCollection, where("slug", "==", slug));
  const slugQueryDraftPosts = query(
    draftPostsCollection,
    where("slug", "==", slug)
  );

  const [querySnapshotPosts, querySnapshotDraftPosts] = await Promise.all([
    getDocs(slugQueryPosts),
    getDocs(slugQueryDraftPosts),
  ]);
  if (!id) {
    return !(querySnapshotPosts.empty && querySnapshotDraftPosts.empty);
  }
  if (
    (!querySnapshotPosts.empty && querySnapshotPosts.docs[0].id !== id) ||
    (!querySnapshotDraftPosts.empty &&
      querySnapshotDraftPosts.docs[0].id !== id)
  ) {
    return true;
  }
  return false;
}
