import React, { FC } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Link, To } from "react-router-dom";
import MoreMenu, { MoreMenuItem, moreMenuHoverStyle } from "../MoreMenu";
import { ShortUser } from "data/user";

interface GroupConvListItemProps {
  conversationName: string;
  participantUsers: ShortUser[]; // Array of users in the group conversation
  link: To;
  menuItems?: MoreMenuItem[];
  selected?: boolean;
}

const GroupConvListItem: FC<GroupConvListItemProps> = ({
  conversationName,
  participantUsers,
  link,
  menuItems,
  selected,
}) => {
  return (
    <ListItem
      sx={{
        ...moreMenuHoverStyle,
      }}
      secondaryAction={
        menuItems &&
        menuItems.length > 0 && (
          <MoreMenu menuItems={menuItems} selected={!!selected} />
        )
      }
    >
      <ListItemButton selected={selected}>
        <Link
          to={link}
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "inherit",
            width: "100%",
          }}
        >
          <ListItemAvatar>
            <AvatarGroup max={4}>
              {participantUsers.slice(0, 4).map((user) => (
                <Avatar key={user.name} alt={user.name} src={user.photoURL} />
              ))}
            </AvatarGroup>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                sx={{ display: "inline",  marginLeft: "10px"}}
                component="div"
                variant="body1"
                color="text.primary"
              >
                {conversationName}
              </Typography>
            }
          />
        </Link>
      </ListItemButton>
    </ListItem>
  );
};

export default React.memo(GroupConvListItem);