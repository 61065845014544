import React from "react";
import UserListItem from "./UserListItem";
import List from "@mui/material/List";
import { ShortUser, useGetShortUsers } from "data/user";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { UserFilter } from "App";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { counties } from "./Setup/SetupPage";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";

interface UserEntry {
  id: string;
  user: ShortUser;
}
interface UsersPageProps {
  filters: UserFilter;
  setFilters: React.Dispatch<React.SetStateAction<UserFilter>>;
  county: string;
  setCounty: React.Dispatch<React.SetStateAction<string>>;
}

const UsersPage: React.FC<UsersPageProps> = ({
  filters,
  setFilters,
  county,
  setCounty,
}) => {
  const [filteredData, setFilteredData] = React.useState<UserEntry[]>([]);
  const { data } = useGetShortUsers();

  React.useEffect(() => {
    if (data) {
      setFilteredData(
        Object.entries(data)
          .filter(
            ([id, user]) =>
              !user.disabled &&
              (!filters.location ||
                user.aboutMe ||
                user.city ||
                user.country) &&
              (!filters.grapes || user.grapeCount) &&
              (!filters.contactInfo || user.contactInfo) &&
              (county === "all" || user.county === county)
          )
          .map(([id, user]) => ({ id, user }))
          .sort((a, b) => {
            if (!b.user?.created && !a.user?.created) {
              return a.user.name.localeCompare(b.user.name);
            }
            return (
              (b.user?.created?.seconds ?? Number.MIN_SAFE_INTEGER) -
              (a.user?.created?.seconds ?? Number.MIN_SAFE_INTEGER)
            );
          })
      );
    }
  }, [filters, county, data]);

  const handleCountyChange = (event: SelectChangeEvent) => {
    setCounty(event.target.value as string);
  };

  const newUserPeriod = new Date();
  newUserPeriod.setDate(newUserPeriod.getDate() - 14);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: { xs: "800px" },
        alignSelf: "center",
        overflow: "hidden",
        borderRadius: "4px",
        background: "white",
        gap: "10px",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      }}
      component="div"
    >
      <Helmet>
        <title>Gazdák</title>
      </Helmet>
      <Box
        sx={{
          background: "white",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Tooltip
          style={{ margin: "10px" }}
          title="Csak a lakcímmel rendelkező gazdákat jelenítse meg"
        >
          <FormControlLabel
            disabled={county !== "all"}
            control={
              <Checkbox
                onChange={(e) =>
                  setFilters((prevFilter) => ({
                    ...prevFilter,
                    location: e.target.checked,
                  }))
                }
                checked={filters.location}
              />
            }
            label="Helyadatokkal"
          />
        </Tooltip>
        <Tooltip
          style={{ margin: "10px" }}
          title="Csak a szőlővel rendelkező gazdáskat jelenítse meg"
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  setFilters((prevFilter) => ({
                    ...prevFilter,
                    grapes: e.target.checked,
                  }))
                }
                checked={filters.grapes}
              />
            }
            label="Van szőlője"
          />
        </Tooltip>
        <Tooltip
          style={{ margin: "10px" }}
          title="Csak az elérhetőséggel rendelkező gazdákat jelenítse meg"
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  setFilters((prevFilter) => ({
                    ...prevFilter,
                    contactInfo: e.target.checked,
                  }))
                }
                checked={filters.contactInfo}
              />
            }
            label="Van elérhetősége"
          />
        </Tooltip>
        <Tooltip style={{ margin: "10px" }} title="Szűrés megyére">
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="county-select-label">Megye</InputLabel>
            <Select
              labelId="county-select-label"
              id="county-select"
              value={county}
              label="Megye"
              onChange={handleCountyChange}
            >
              <MenuItem value={"all"}>minden</MenuItem>
              {counties.map((county) => (
                <MenuItem value={county} key={county}>
                  {county}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Tooltip>
      </Box>
      <Box sx={{ overflow: "auto", display: "flex", flexDirection: "column" }}>
        {filteredData ? (
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {filteredData.map(({ id, user }) => (
              <UserListItem
                user={user}
                key={id}
                link={`/gazda/${id}`}
                isNewUser={
                  user?.created
                    ? new Date(user.created.seconds * 1000) > newUserPeriod
                    : false
                }
              />
            ))}
          </List>
        ) : (
          <div>töltés...</div>
        )}
      </Box>
      <Box sx={{ m: "15px" }}>{`${filteredData.length} gazda`}</Box>
    </Box>
  );
};

export default UsersPage;
