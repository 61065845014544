import React, { FC } from "react";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getTextFieldProps } from "utils/formUtils";
import slugify from "slugify";
import { doc, getDoc } from "@firebase/firestore";
import { db } from "../../utils/firebase";
import { slugConfig } from "utils/general";

export interface NewGrapeForm {
  name: string;
}

interface NewGrapeDialogProps {
  onFormSave: (data: NewGrapeForm) => void;
}

export const newGrapeFromId = "newGrapeFromId";

const getNewGrapeSchema = (): yup.SchemaOf<NewGrapeForm> => {
  return yup.object({
    name: yup.string().required(),
  });
};

const NewGrapeDialog: FC<NewGrapeDialogProps> = ({ onFormSave }) => {
  const { register, formState, handleSubmit, watch } = useForm<NewGrapeForm>({
    mode: "onChange",
    resolver: yupResolver(getNewGrapeSchema()),
    defaultValues: {
      name: "",
    },
  });

  const name = watch("name");
  const [extraError, setExtraError] = React.useState("");

  // csak leveszi a hibát
  React.useEffect(() => {
    if (!!extraError) {
      let slug = slugify(name, slugConfig);
      const docRef = doc(db, "grapes", slug);
      getDoc(docRef).then((snap) => {
        if (!snap.exists()) {
          setExtraError("");
        }
      });
    }
  }, [extraError, setExtraError, name]);

  const handleSavePressed = async (data: NewGrapeForm) => {
    let slug = slugify(data.name, slugConfig);
    const docRef = doc(db, "grapes", slug);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setExtraError(`Létezik már ilyen: ${slug}`);
    } else {
      onFormSave(data);
    }
  };

  const { error, helperText, ...other } = getTextFieldProps(
    false,
    "name",
    register,
    formState,
    true
  );

  return (
    <form
      style={{ padding: "20px" }}
      id={newGrapeFromId}
      onSubmit={handleSubmit(handleSavePressed)}
    >
      <TextField
        {...other}
        error={error || !!extraError}
        helperText={helperText || extraError}
        label="Név"
        size="small"
        autoFocus
      />
    </form>
  );
};

export default NewGrapeDialog;
