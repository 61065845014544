export const colors: string[] = [
    "#ffffcc", // Halvány sárga
    "#b3cde3", // Pasztell kék
    "#ccebc5", // Pasztell zöld
    "#fbb4ae", // Pasztell rózsaszín
    "#decbe4", // Pasztell lila
    "#fed9a6", // Pasztell narancs
    "#e5d8bd", // Pasztell bézs
    "#fddaec", // Halvány rózsaszín
    "#f2f2f2", // Nagyon világos szürke
    "#d1e2f3"  // Halvány kék
  ];

export interface ParticipantColors {
    [userId: string]: string;
  }

export const assignColorsToParticipants = (participantIds: string[]) => {
    const participantColors: ParticipantColors = {};
    participantIds.forEach((id, index) => {
      participantColors[id] = colors[index % colors.length];
    });
    return participantColors;
  };