import React, { memo, useRef } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import { MessageData } from "data/messages-realtime";
import { ParticipantColors } from "../utils";
import { ShortUserList } from "data/user";

interface MessageItemProps {
  messageData: MessageData;
  userId: string;
  users?: ShortUserList;
  participantColors: ParticipantColors;
}

const MessageItem: React.FC<MessageItemProps> = ({
  messageData,
  userId,
  users,
  participantColors,
}) => {
  return (
    <>
      <Box
        sx={{
          alignSelf:
            messageData.senderId === userId ? "flex-end" : "flex-start",
          color: "grey.500",
          fontSize: "0.8rem",
        }}
      >
        {messageData.senderId === userId
          ? `${new Date(messageData.timestamp).toLocaleString("hu")}`
          : `${users?.[messageData.senderId].name} - ${new Date(
              messageData.timestamp
            ).toLocaleString("hu")}`}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection:
            messageData.senderId === userId ? "row-reverse" : "row",
          alignSelf:
            messageData.senderId === userId ? "flex-end" : "flex-start",
          alignItems: "center",
        }}
      >
        {
          <Avatar
            src={users?.[messageData.senderId]?.photoURL}
            sx={{ width: 24, height: 24 }}
          />
        }
        <Paper
          sx={{
            padding: 1,
            margin: 1,
            wordBreak: "break-word",
            color: messageData.senderId === userId ? "white" : "black",
            backgroundColor:
              messageData.senderId === userId
                ? "primary.light"
                : participantColors[messageData.senderId],
          }}
        >
          {messageData.message}
        </Paper>
      </Box>
    </>
  );
};

export default memo(MessageItem);
