import React, { FC, PropsWithChildren } from "react";
import { ShortGrape } from "data/grape";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import GrapeAvatar from "../common/GrapeAvatar";

interface GrapeEditItemProps {
  grape: ShortGrape;
  id: string;
  onGrapeAction: (id: string, event?: React.MouseEvent<HTMLElement>) => void;
}

const GrapeActionItem: FC<PropsWithChildren<GrapeEditItemProps>> = ({
  grape,
  onGrapeAction,
  id,
  children,
}) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "5px",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: { xs: "none", sm: "flex" } }} component="div">
        <GrapeAvatar grape={grape} grapeId={id} width={25} />
      </Box>
      <Box sx={{ flex: "1 1 auto" }}>{grape.name}</Box>
      <IconButton onClick={(event) => onGrapeAction(id, event)}>
        {children}
      </IconButton>
    </div>
  );
};

export default GrapeActionItem;
