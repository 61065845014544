import React, { FC, useRef } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import { ShortGrape, ShortGrapeList } from "data/grape";

import GrapeListItem from "./GrapeListItem";
import Menu from "@mui/material/Menu";
import GreapeSearchResult from "./GreapeSearchResult";
import Timeline from "./Timeline/Timeline";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface GrapeMenu {
  grape: ShortGrape;
  id: string;
}

interface MainPageProps {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  grapes: ShortGrapeList | undefined;
  results: string[];
  isLoading: boolean;
}

const MainPage: FC<MainPageProps> = ({
  searchTerm,
  setSearchTerm,
  grapes,
  results,
  isLoading,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLElement) | null
  >(null);
  const open = Boolean(anchorEl);
  const grapeRef = React.useRef<GrapeMenu | undefined>();
  const searchFieldRef = useRef<HTMLInputElement>();

  React.useEffect(() => {
    document.title = `Közösségi szőlő katalógus - Keresés`;
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
      component="div"
    >
      <Box sx={{ width: "100%", overflow: "auto" }}>
        <Card
          sx={{
            maxWidth: { xs: "800px", lg: "1200px" },
            width: "100%",
            background: "white",
            alignSelf: "center",
            margin: "auto",
            position: "relative",
            padding: "10px",
          }}
          component="div"
        >
          {isLoading && <LinearProgress />}
          <Typography variant="h4" noWrap component="div">
            Szőlőink
          </Typography>
          <Typography
            style={{ marginTop: "20px", marginBottom: "10px" }}
            variant="h6"
            noWrap
            component="div"
          >
            Gyorskeresés:
          </Typography>
          <TextField
            label="Szőlő név"
            value={searchTerm}
            size="small"
            onChange={(event) => setSearchTerm(event.target.value as string)}
            inputRef={searchFieldRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setSearchTerm("");
                      if (searchFieldRef.current) {
                        searchFieldRef.current.focus();
                      }
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {grapes && results.length > 0 && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: { xs: "2px", sm: "5px", lg: "10px" },
                flexWrap: "wrap",
              }}
              component="div"
            >
              {results
                .filter((id, index) => index < 8)
                .map((id) => [id, grapes[id]])
                .map(([grapeId, grape]) => (
                  <GreapeSearchResult
                    key={grapeId as string}
                    grape={grape as ShortGrape}
                    id={grapeId as string}
                  />
                ))}
            </Box>
          )}
          <Typography
            variant="body1"
            component="div"
            style={{ marginTop: "20px" }}
          >
            Üdvözöljük a közösségi szőlő katalóguson!
          </Typography>
          <Typography variant="body1" component="div">
            Ez az oldal azért jött létre, hogy összegyűjtse és megossza a hazai
            és különleges külföldi szőlőfajtákról szóló információkat. Egyre
            többen fedezik fel a világ különböző tájairól származó különleges
            fajtákat - legyenek azok ukrán, orosz, moldáv vagy éppen magyar
            nemesítésűek. Célunk, hogy segítsünk eligazodni a rengeteg
            szőlőfajta között, és közösen gyarapítsuk tudásunkat. Itt a
            szőlősgazdák megoszthatják lakhelyüket és a szőlőfajtáikat,
            üzenhetnek egymásnak, elősegítve a helyi közösségek kialakulását.
            Legyen szó tapasztalatcseréről, szaporítóanyag megosztásáról vagy
            akár új fajták felfedezéséről, együtt többre jutunk.
          </Typography>
          <Typography variant="body1" component="div">
            Csatlakozzon hozzánk, és fedezzük fel együtt a szőlők sokszínű
            világát!
          </Typography>
          <Typography
            style={{ marginTop: "20px", marginBottom: "10px" }}
            variant="h6"
            noWrap
            component="div"
          >
            Eseményfolyam:
          </Typography>
          <Timeline />
        </Card>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {open && (
            <GrapeListItem
              grape={grapeRef.current!.grape}
              grapeId={grapeRef.current!.id}
              sx={{ minWidth: { xs: "300px", sm: "500px" } }}
            />
          )}
        </Menu>
      </Box>
    </Box>
  );
};

export default MainPage;
