import React, { FC, useState } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { getTextFieldProps } from "utils/formUtils";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, Resolver, useFieldArray, useForm } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { TransitionGroup } from "react-transition-group";
import { Grape, GrapeRecommendation } from "data/grape";
import { FormControlLabel, FormControl } from "@mui/material";
import { UserAction, useRights } from "hooks/useRights";
import { useBlocker } from "utils/routerHooks";
import { Transition } from "history";
import GrapeLinkSelectionMenu from "./GrapeLinkSelectionMenu";
import { GrapeIconMui } from "components/common/Icons";
import { useGetTagCategories, useGetTags } from "data/tag";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { useGetShortGrapes, ShortGrapeList } from "data/grape";
import seedrandom from "seedrandom";

import TagFilterDropdown from "components/GrapeSearch/TagFilterDropdown";

interface GrapeEditPanelProps {
  grape: Grape;
  grapeId: string;
  onFormSave: (data: GrapeEditForm, recommendedGrapes: GrapeRecommendation[]) => void;
}

const computeRecommendedGrapes = (
  grapeId: string,
  shortGrapes: ShortGrapeList
): GrapeRecommendation[] => {
  const grapesArray = Object.entries(shortGrapes).sort(([a], [b]) =>
    a.localeCompare(b)
  );
  const rng = seedrandom(grapeId);

  const recommended: GrapeRecommendation[] = [];
  let attempts = 0;

  while (recommended.length < 8 && attempts < 100) {
    attempts++;
    let randIndex;
    randIndex = Math.floor(rng() * grapesArray.length);

    const grapeEntry = grapesArray[randIndex];
    if (
      grapeEntry[0] !== grapeId &&
      !recommended.some((rec) => rec.id === grapeEntry[0])
    ) {
      recommended.push({
        name: grapeEntry[1].name,
        additionalNames: grapeEntry[1].additionalNames,
        id: grapeEntry[0],
      });
    }
  }

  return recommended;
};


export interface GrapeEditForm
  extends Pick<
    Grape,
    | "name"
    | "description"
    | "ripeningFrom"
    | "ripeningTo"
    | "russianName"
    | "additionalNames"
    | "notFinished"
    | "shouldEdited"
    | "editorNote"
    | "tags"
  > {
  links?: { url: string }[];
}

export const grapeEditformId = "grapeEditformId";

const getGrapeSchema = (): yup.SchemaOf<GrapeEditForm> => {
  return yup.object({
    name: yup.string().required(),
    description: yup.string().notRequired(),
    russianName: yup.string().notRequired(),
    additionalNames: yup.string().notRequired(),
    seedless: yup.boolean(),
    ripeningFrom: yup
      .number()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .when("ripeningTo", (ripeningTo, schema: any) => {
        if (ripeningTo)
          return schema.max(ripeningTo, `legfeljebb ${ripeningTo} lehet`);
        return schema;
      })
      .min(80)
      .max(180)
      .notRequired()
      .when("ripeningTo", (ripeningTo, schema: any) => {
        if (ripeningTo) return schema.required();
        return schema;
      }),
    ripeningTo: yup
      .number()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .min(80)
      .max(180)
      .notRequired(),
    links: yup
      .array()
      .of(yup.object({ url: yup.string().url().required() }))
      .notRequired(),
    notFinished: yup.boolean().required(),
    shouldEdited: yup.boolean().required(),
    editorNote: yup.string(),
    tags: yup.array().of(yup.number()).notRequired(),
  });
};

const GrapeEditPanel: FC<GrapeEditPanelProps> = ({ grape, onFormSave, grapeId }) => {
  const { register, formState, handleSubmit, control, setValue, getValues } =
    useForm<GrapeEditForm>({
      mode: "onChange",
      resolver: yupResolver(getGrapeSchema()) as Resolver<GrapeEditForm>,
      defaultValues: {
        name: grape.name,
        description: grape.description,
        russianName: grape.russianName,
        additionalNames: grape.additionalNames,
        ripeningFrom: grape.ripeningFrom,
        ripeningTo: grape.ripeningTo,
        links: grape.links?.map((link) => ({ url: link })),
        notFinished: grape?.notFinished ?? false,
        shouldEdited: grape?.shouldEdited ?? false,
        editorNote: grape?.editorNote ?? "",
        tags: grape?.tags ?? [],
      },
    });
  const { hasRight } = useRights();
  const { data: shortGrapes } = useGetShortGrapes();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "links",
  });
  const [selectedText, setSelectedText] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [isFormDirty, setIsFormDirty] = useState(true);
  const [selectionRange, setSelectionRange] = useState({ start: 0, end: 0 });
  const { data: availableTags } = useGetTags();
  const { data: tagCategories } = useGetTagCategories();

  const [searchTerm, setSearchTerm] = useState("");

  useBlocker((tx: Transition) => {
    if (isFormDirty) {
      const confirmResult = window.confirm(
        "A mentetlen változások elvesznek. Biztos el akar váltani az oldalról?"
      );
      if (confirmResult) {
        setIsFormDirty(false);
        tx.retry();
      }
      return confirmResult;
    }
    return true;
  }, isFormDirty);

  const handleSearchSelected = (grapeId: string, grapeName: string) => {
    const currentDescription = getValues("description") ?? "";
    const newText =
      currentDescription.substring(0, selectionRange.start) +
      `[${grapeName}](/szolo/${grapeId})` +
      currentDescription.substring(selectionRange.end);

    setValue("description", newText);
    setAnchorEl(null);
  };

  const handleFormSubmit = (data: GrapeEditForm) => {
    const recommendedGrapes = computeRecommendedGrapes(
      grapeId,
      shortGrapes ?? {}
    );
  
    onFormSave(data, recommendedGrapes);
  };

  return (
    <form id={grapeEditformId} onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container gap={2} sx={{ width: "570px" }}>
        <Typography variant="h5" noWrap component="div">
          {grape.name}
        </Typography>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Controller
              name="tags"
              control={control}
              render={({ field: { onChange, value } }) => {
                const grapeTags = value ?? [];
                return (
                  <FormControl variant="outlined" fullWidth >
                    <InputLabel htmlFor="tag-input">Címkék</InputLabel>
                    <OutlinedInput
                      id="tag-input"
                      label="Címkék"
                      notched
                      fullWidth
                      startAdornment={
                        <Box
                          sx={{
                            display: 'flex', 
                            flexWrap: 'wrap',
                            minWidth: "100%", 
                            gap: 1,
                            padding: "10px",
                            alignContent: "flex-start",
                            minHeight: "40px", 
                          }}
                        >
                          {grapeTags.map((tagId) => {
                            const tag = (availableTags ?? []).find(
                              (t) => t.id === tagId
                            );
                            return (
                              <Chip
                                key={tagId}
                                label={tag?.name || "Unknown Tag"}
                                onDelete={() => {
                                  const updatedTags = grapeTags.filter(
                                    (id) => id !== tagId
                                  );
                                  onChange(updatedTags);
                                }}
                                variant="filled"
                                size="medium"
                              />
                            );
                          })}
                          <TagFilterDropdown
                            tags={availableTags ?? []}
                            tagCategories={tagCategories ?? []}
                            selectedTags={grapeTags}
                            onTagAdd={(tagId) => {
                              if (!grapeTags.includes(tagId)) {
                                onChange([...grapeTags, tagId]);
                              }
                            }}
                            shouldStayOpenAfterSelection={true}
                          />
                        </Box>
                      }
                    />
                  </FormControl>
                );
              }}
            />
          </Grid>

        </Grid>
        <Grid item xs={12}>
          <TextField
            {...getTextFieldProps(false, "name", register, formState, true)}
            required
            label="Név"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...getTextFieldProps(
              false,
              "russianName",
              register,
              formState,
              true
            )}
            label="Orosz név"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...getTextFieldProps(
              false,
              "additionalNames",
              register,
              formState,
              true
            )}
            label="További nevek (vesszővel elválasztva)"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" noWrap component="div">
            Érési idő nap
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            {...getTextFieldProps(
              false,
              "ripeningFrom",
              register,
              formState,
              true
            )}
            label="Tól"
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            {...getTextFieldProps(
              false,
              "ripeningTo",
              register,
              formState,
              true
            )}
            label="Ig"
          />
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: "-20px" }}>
          <Typography variant="h6" noWrap component="div">
            Külső hivatkozások
          </Typography>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <TransitionGroup component={"div"}>
            {fields.map((field, index) => {
              return (
                <Collapse
                  key={field.id}
                  timeout={300}
                  sx={{ marginTop: "16px" }}
                >
                  <Grid container key={field.id}>
                    <Grid item xs={11}>
                      <TextField
                        defaultValue={field.url}
                        {...getTextFieldProps(
                          false,
                          `links[${index}].url`,
                          register,
                          formState,
                          true
                        )}
                        label="Hivatkozás weboldalra"
                      />
                    </Grid>
                    <Grid item xs={1} sx={{ alignSelf: "center" }}>
                      <IconButton
                        onClick={() => {
                          remove(index);
                        }}
                        color="info"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Collapse>
              );
            })}
          </TransitionGroup>
          <Button
            startIcon={<AddIcon />}
            onClick={() => append({ url: "" })}
            color="info"
            size="small"
            variant="outlined"
            sx={{ marginTop: "10px" }}
          >
            Hivatkozás hozzáadása
          </Button>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  setSearchTerm(selectedText);
                  setAnchorEl(event.currentTarget);
                }}
                disabled={!selectedText || !!anchorEl}
              >
                <GrapeIconMui />
              </IconButton>
            </div>
            <TextField
              {...getTextFieldProps(
                false,
                "description",
                register,
                formState,
                true
              )}
              multiline
              minRows={4}
              maxRows={8}
              label="Leírás"
              onSelect={(event: any) => {
                const { selectionStart, selectionEnd } = event.target;
                setSelectionRange({ start: selectionStart, end: selectionEnd });
                const selectedText = event.target.value.slice(
                  selectionStart,
                  selectionEnd
                );
                setSelectedText(selectedText);
              }}
            />
          </div>
        </Grid>
        <GrapeLinkSelectionMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          onLinkSelect={handleSearchSelected}
          searchTerm={searchTerm}
        />
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Controller
                name="shouldEdited"
                control={control}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Checkbox
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    inputRef={ref}
                  />
                )}
              />
            }
            label="Szerkesztésre szorulhat"
          />
        </Grid>
        {hasRight(UserAction.NEW_GRAPE) ? (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Controller
                  name="notFinished"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Checkbox
                      onBlur={onBlur}
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
              }
              label="Nem publikált"
            />
          </Grid>
        ) : (
          <input
            hidden
            defaultValue={"" + grape?.shouldEdited ?? false}
            {...register("notFinished")}
          />
        )}
        <Grid item xs={12}>
          <TextField
            {...getTextFieldProps(
              false,
              "editorNote",
              register,
              formState,
              true
            )}
            multiline
            minRows={4}
            maxRows={8}
            label="Szerkesztő megjegyzése"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default GrapeEditPanel;
