import React, { useState } from "react";
import { ConfirmDialogProps } from "./ConfirmDialog";

export const useDialog = (
  initialMode = false
): [boolean, (open: boolean) => void, () => void] => {
  const [openDialog, setOpenDialog] = useState(initialMode);
  const toggle = () => setOpenDialog(!openDialog);
  return [openDialog, setOpenDialog, toggle];
};

type DialogRef = Omit<ConfirmDialogProps, "open">;

export const useConfirmDialog = (
  initialOpen = false
): [
  ConfirmDialogProps,
  (props: DialogRef) => void,
  (open: boolean) => void
] => {
  const [open, setOpenDialog] = useDialog(initialOpen);
  const [dialogProps, setDialogProps] = React.useState<DialogRef>({
    title: "",
  });
  const setProps = (prop: DialogRef) => {
    setDialogProps(prop);
  };
  const onCancel = () => setOpenDialog(false);
  const onConfirm = () => setOpenDialog(false);

  return [
    { onCancel, onConfirm, ...dialogProps, open },
    setProps,
    setOpenDialog,
  ];
};
