import { User as FullUser } from "data/user";

export const fakePunycodeDecode = (url: string): string =>
  url.replace("xn--csemegeszlk-6bcb.hu", "csemegeszőlők.hu");

export const getImageUrl = (name: string) => {
  return `https://firebasestorage.googleapis.com/v0/b/szoloim.appspot.com/o/grapes%2Fthumbs%2F${name}?alt=media&token=b96d73f0-4617-45bc-b566-cbf715da5cbe`;
};

type UserWithLocation = Pick<FullUser, "city" | "country" | "county">;

const locationKeys: (keyof UserWithLocation)[] = ["country", "county", "city"];

export const createLocationString = (user: UserWithLocation | undefined) => {
  const location: string[] = [];
  locationKeys.forEach((key: keyof UserWithLocation) => {
    if (user && !!user[key]) {
      if (key === "county") {
        location.push(`${user[key]!} megye`);
      } else {
        location.push(user[key]! as string);
      }
    }
  });
  return location;
};

export const slugConfig = {
  lower: true,
  strict: true,
  locale: "hu",
};
