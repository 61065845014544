import React, { FC } from "react";
import "./styles.css";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import UsersPage from "./components/UsersPage";
import GrapesPage from "./components/GrapesPage/GrapesPage";
import MenuAndAppbar from "./components/MenuAndAppbar/MenuAndAppbar";
import GrapePage from "./components/GrapePage/GrapePage";
import UserPage from "./components/UserPage";
import initYupMessages from "utils/initYupMessages";
import { StateProvider } from "utils/StateProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SetupPage from "components/Setup/SetupPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MainPage from "components/MainPage";
import MyGrapes from "components/MyGrapes";
import ListsPage from "components/ListsPage";
import { useSearch } from "hooks/useSearch";
import DevPage from "components/DevPage/DevPage";
import AdminPage from "components/AdminPage/AdminPage";
import { Roles, useRights } from "hooks/useRights";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  ThemeOptions,
  StyledEngineProvider,
} from "@mui/material/styles";
import GrapesToEdited from "components/GrapesToEdited";
import GrapeHistory from "components/GrapeHistory/GrapeHistory";
import AnalyticsComponent from "components/AnalyticsComponent";

import TaggedPostsList from "components/Post/TaggedPostList";
import NotFoundPage from "components/NotFoundPage";
import ProtectedComponent from "components/common/ProtectedComponent";
import UnauthorizedPage from "components/UnauthorizedPage";
import MessagePage from "components/MessagePage/MessagePage";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import GrapeSearch from "components/GrapeSearch/GrapeSearch";

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#522f96",
    },
    text: {
      primary: "rgba(43,31,84,0.87)",
      secondary: "rgba(43,31,84,0.54)",
      disabled: "rgba(43,31,84,0.38)",
    },
  },
};

export interface UserFilter {
  location: boolean;
  grapes: boolean;
  contactInfo: boolean;
}

let theme = createTheme(themeOptions);
theme = responsiveFontSizes(theme);

initYupMessages();

const queryClient = new QueryClient();
const ListPage = React.lazy(() => import("./components/ListPage/ListPage"));
const PostEditorPage = React.lazy(
  () => import("./components/Post/PostEditorPage")
);
const PostDetail = React.lazy(() => import("./components/Post/PostDetail"));
const PostList = React.lazy(() => import("./components/Post/PostList"));
const CompareGrapeHistory = React.lazy(
  () => import("./components/GrapeHistory/CompareGrapeHistory")
);

const MyRoutes: FC = () => {
  const { hasRole } = useRights();
  const searchParams = useSearch();
  const [userFilters, setUserFilters] = React.useState<UserFilter>({
    location: true,
    contactInfo: false,
    grapes: false,
  });
  const [county, setCounty] = React.useState("all");

  return (
    <BrowserRouter>
      <MenuAndAppbar>
        <Routes>
          {hasRole(Roles.SIGNED) && (
            <Route
              path="/gazdak"
              element={
                <UsersPage
                  filters={userFilters}
                  setFilters={setUserFilters}
                  county={county}
                  setCounty={setCounty}
                />
              }
            />
          )}
          <Route path="/szolok/" element={<GrapesPage />}>
            <Route path=":pathInitial/" element={<GrapesPage />} />
          </Route>
          <Route
            path="/posts/"
            element={
              <React.Suspense fallback={<div>...töltés</div>}>
                <PostList />
              </React.Suspense>
            }
          />
          <Route path="/szolo-kereses/" element={<GrapeSearch />} />
          <Route path="/posts/tag/:tag" element={<TaggedPostsList />} />
          <Route
            path="/posts/edit/:slug"
            element={
              <React.Suspense fallback={<div>...töltés</div>}>
                <PostEditorPage />
              </React.Suspense>
            }
          />
          <Route
            path="/posts/:category/:slug/"
            element={
              <React.Suspense fallback={<div>...töltés</div>}>
                <PostDetail />
              </React.Suspense>
            }
          />
          <Route
            path="/drafts/:category/:slug/"
            element={
              <React.Suspense fallback={<div>...töltés</div>}>
                <PostDetail />
              </React.Suspense>
            }
          />
          <Route path="/szolo/:id/" element={<GrapePage />} />
          {hasRole(Roles.EDITOR) && (
            <Route path="/szolo/:id/tortenet/" element={<GrapeHistory />} />
          )}
          {hasRole(Roles.EDITOR) && (
            <Route
              path="/szolo/:id/compare/:version1/:version2"
              element={
                <React.Suspense fallback={<div>...töltés</div>}>
                  <CompareGrapeHistory />
                </React.Suspense>
              }
            />
          )}
          <Route
            path="/gazda/:id"
            element={<ProtectedComponent component={UserPage} />}
          />
          <Route
            path="/beallitas"
            element={<ProtectedComponent component={SetupPage} />}
          />
          <Route
            path="/szoloim"
            element={<ProtectedComponent component={MyGrapes} />}
          />
          <Route
            path="/listaim"
            element={<ProtectedComponent component={ListsPage} />}
          />
          <Route
            path="/uzenetek"
            element={<ProtectedComponent component={MessagePage} />}
          />
          <Route
            path="/uzenetek/:conversationId"
            element={<ProtectedComponent component={MessagePage} />}
          />

          {hasRole(Roles.EDITOR) && (
            <Route path="/szolo-szerkesztendo" element={<GrapesToEdited />} />
          )}
          {process.env.REACT_APP_DEV_MENU === "true" &&
            hasRole(Roles.ADMIN) && <Route path="/dev" element={<DevPage />} />}
          <Route
            path="/lista/:userId/:listId"
            element={
              <React.Suspense fallback={<div>...töltés</div>}>
                <ListPage />
              </React.Suspense>
            }
          />
          {hasRole(Roles.ADMIN) && (
            <Route path="/admin" element={<AdminPage />} />
          )}
          {hasRole(Roles.VIEW) && (
            <Route path="/" element={<MainPage {...searchParams} />} />
          )}
          <Route path="jogosulatlan" element={<UnauthorizedPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </MenuAndAppbar>
      <AnalyticsComponent />
    </BrowserRouter>
  );
};

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <QueryClientProvider client={queryClient}>
          <StateProvider>
            <Box sx={{ display: "flex" }} component="div">
              <CssBaseline />
              <MyRoutes />
              <ToastContainer
                position="bottom-center"
                pauseOnHover
                hideProgressBar
              />
              <ReactQueryDevtools initialIsOpen={false} />
            </Box>
          </StateProvider>
        </QueryClientProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}
