import React, { FC, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export interface MoreMenuItem {
  label: string;
  onClick: () => void | Promise<any>;
  icon?: React.ElementType;
  tooltip?: string;
}

export const moreMenuHoverStyle = {
  "&:hover .menu-wrapper": {
    display: "block !important",
  },
};

interface MoreMenuProps {
  menuItems: MoreMenuItem[];
  selected: boolean;
}

const MoreMenu: FC<MoreMenuProps> = ({ menuItems, selected }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className="menu-wrapper"
      style={{ display: selected || open ? "block" : "none" }}
    >
      <IconButton aria-label="more" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.label}
            onClick={() => {
              menuItem.onClick();
              handleClose();
            }}
          >
            {menuItem.icon && (
              <Tooltip title={menuItem.tooltip}>
                <menuItem.icon sx={{ marginRight: 1 }} />
              </Tooltip>
            )}
            {menuItem.tooltip ? <span>{menuItem.label}</span> : menuItem.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MoreMenu;
