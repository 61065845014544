import React, { Fragment } from "react";
import { useParams } from "react-router";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { InialWithGrapes } from "./GrapesPage";
import Link from "components/Link";

interface GrapeNavigationProps {
  iwgList: InialWithGrapes[];
}

const isLink = (
  path: string,
  param: string | undefined,
  defaultPath: string
) => {
  return param ? param !== path : path !== defaultPath;
};

const GrapeNavigation: React.FC<GrapeNavigationProps> = ({ iwgList }) => {
  const { pathInitial } = useParams();
  return (
    <Box
      sx={{
        display: "flex",
        gap: { xs: "5px", sm: "7px", md: "12px", lg: "20px" },
        margin: { xs: "5px", sm: "10px", lg: "15px" },
        paddingLeft: "10px",
        flexWrap: "wrap",
      }}
    >
      {iwgList.map((iwg) => (
        <Fragment key={iwg.pathInitial}>
          {isLink(iwg.pathInitial, pathInitial, iwgList[0].pathInitial) && (
            <Link to={`/szolok/${iwg.pathInitial}`}>{iwg.label}</Link>
          )}
          {!isLink(iwg.pathInitial, pathInitial, iwgList[0].pathInitial) && (
            <Typography
              style={{ fontWeight: 600 }}
              variant="body1"
              noWrap
              component="div"
            >
              {iwg.label}
            </Typography>
          )}
        </Fragment>
      ))}
    </Box>
  );
};

export default GrapeNavigation;
