import React from "react";
import { Box, Typography } from "@mui/material";
import Link from "./Link";
import { Helmet } from "react-helmet";
import Button from "@mui/material/Button";

const UnauthorizedPage: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Typography variant="h2" component="h2" gutterBottom>
        401 - Jogosulatlan hozzáférés
      </Typography>
      <Typography style={{marginBottom: "40px"}} variant="body1" component="p" gutterBottom>
        Elnézést, de belépés nélkül nem lehet ehhez az oldalhoz hozzáférni.
        A belépés gombot a jobb felső sarokban találja.
      </Typography>
      <Button component={Link} to="/" variant="contained" color="primary">
        Vissza a főoldalra
      </Button>
    </Box>
  );
};

export default UnauthorizedPage;
