import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const GrapeIconMui = ({ ...props }: SvgIconProps) => {
  const grapeStyles = {
    fill: "white",
    strokeWidth: 1.8,
  };
  return (
    <SvgIcon {...props} stroke="currentColor">
      <ellipse style={grapeStyles} cx="9.4" cy="7.2" rx="3" ry="3" />
      <ellipse style={grapeStyles} cx="14.8" cy="7.5" rx="3" ry="3" />
      <ellipse style={grapeStyles} cx="7" cy="10.9" rx="3" ry="3" />
      <ellipse style={grapeStyles} cx="12" cy="11.1" rx="3" ry="3" />
      <ellipse style={grapeStyles} cx="12.3" cy="19.4" rx="3" ry="3" />
      <ellipse style={grapeStyles} cx="17.7" cy="10.7" rx="3" ry="3" />
      <ellipse style={grapeStyles} cx="9.5" cy="15.7" rx="3" ry="3" />
      <ellipse style={grapeStyles} cx="15.4" cy="15.8" rx="3" ry="3" />
      <path style={grapeStyles} d="M 11.926,4.9 13.9,2.7 15.7,0.6" />
    </SvgIcon>
  );
};

export const FarmerIconMui: React.FC<SvgIconProps> = ({ ...props }) => {
  const farmerStyles = {
    fill: "currentColor",
    strokeWidth: "0.9",
    stroke: "currentColor",
  };
  return (
    <SvgIcon {...props} stroke="currentColor">
      <path
        style={farmerStyles}
        d="m7.158 6.547 1.107-4.335c0-.433 0-.65.245-.867C9.002.587 9.985.37 10.969.804a3.03 3.03 0 0 0 1.967.216l.983-.433c1.106-.217 1.966.433 2.212 1.409l.983 4.443c1.23.108 6.393.542 6.393 2.168 0 1.083-2.95 1.625-3.81 1.842-4.427.758-9.097.758-13.523.217-1.23-.11-5.285-.76-5.408-2.168 0-1.41 5.285-1.95 6.392-1.95zM10.6 1.454c-.738-.325-1.475-.11-1.721.758-.279 1.41-.565 2.82-.86 4.228a78.48 78.48 0 0 1 8.358 0 89.287 89.287 0 0 1-.86-3.901l-.247-.76c-.245-.433-.613-.541-1.229-.433l-.982.326a4.169 4.169 0 0 1-1.844 0 5.321 5.321 0 0 1-.615-.217zm3.442 5.635a68.535 68.535 0 0 0-8.36.325 11.007 11.007 0 0 0-3.195.542c-.31.105-.6.252-.86.434v.325c.614.433 1.597.65 2.211.759l2.46.433a46.52 46.52 0 0 0 9.834.217 32.342 32.342 0 0 0 3.81-.542 6.647 6.647 0 0 0 2.827-.975.097.097 0 0 0 0-.11 1.824 1.824 0 0 0-.614-.432c-1.23-.434-2.459-.542-3.688-.76z"
      />
      <path
        style={farmerStyles}
        d="M11.126 16.05c-.2-.112-.4-.444-.3-.665l.3-.222c.2 0 .3.332.5.443.5.221.7-.11.7-.664v-1.77c0-.554 0-1.107.2-1.44.5-.774 1.4-1.327 2.2-.774.4.332.1.885-.2.664-.7-.332-1.3 0-1.6.775v2.545c0 .775-.3 1.107-.8 1.328a1 1.107 0 0 1-1-.22zm0 0"
      />
      <path
        style={farmerStyles}
        d="M14.717 13.907c-.304 0-.456-.243-.304-.486.153-.73 1.218-.852 1.828-.608.303 0 .76.243.76.608 0 .243-.304.608-.608.364a1.198 1.198 0 0 0-1.523 0zm-5.023-.73c.608.365 0 .973-.609.608-.456-.364-.913-.243-1.522 0-.457 0-.61-.364-.305-.608.76-.608 1.675-.486 2.436 0zm2.74 7.296c-1.522.243-3.044-.73-3.349-2.19 0-.363.609-.607.914-.242 0 1.338 1.827 2.067 3.043 1.46a1.94 1.94 0 0 0 1.219-1.46l.152-.243c.914 0 .609.85.457 1.216a3.044 2.432 0 0 1-2.436 1.58zm0 0"
      />
      <path
        style={farmerStyles}
        d="M5.306 11.629c.311 0 .311.325.311.542v5.202c.071 1.885 1.545 3.61 3.89 4.552 4.046 1.409 8.557-.542 9.337-3.793v-1.3c.012-1.59.012-3.18 0-4.77 0-.216.31-.541.62-.433.312 0 .468.325.468.542v5.094c0 2.6-2.334 4.768-5.445 5.527-2.852.742-5.998.055-7.935-1.734-1.246-1.084-1.868-2.384-1.868-4.01v-5.203l.466-.217z"
      />
    </SvgIcon>
  );
};
