import React, { useState } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";

const EditorRoleManagement = () => {
  const [email, setEmail] = useState("");
  const [enabled, setEnabled] = useState(true);

  const handleAddEditorRole = () => {
    const functions = getFunctions();
    const addEditorRole = httpsCallable(functions, "addEditorRole");

    addEditorRole({ email, enabled })
      .then((response) => {
        console.log(response.data);
        //@ts-ignore
        if (response.data?.errorInfo) {
          //@ts-ignore
          toast.error(response.data?.errorInfo.message);
        } else {
          //@ts-ignore
          toast(response.data.message);
        }
      })
      .catch((e) => toast.error(e.message));
  };

  return (
    <Box>
      <Typography variant="h6" noWrap component="div">
        Szerkesztő jog adása / elvétele
      </Typography>
      <Box sx={{ display: "flex", gap: "20px" }}>
        <Checkbox
          checked={enabled}
          onChange={(event) => setEnabled(event.target.checked)}
        />
        <TextField
          id="outlined-name"
          label="email cím vagy uid"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <Button
          onClick={handleAddEditorRole}
          size="small"
          variant="contained"
        >
          {!enabled && "Ne "} Legyen szerkesztő
        </Button>
      </Box>
    </Box>
  );
};

export default EditorRoleManagement;
