import React from "react";
import GrapeListItem from "../GrapeListItem";
import List from "@mui/material/List";
import { Box } from "@mui/material";
import { InialWithGrapes, PixelPerColumns } from "./GrapesPage";
import { NEW_GRAPE_DURATION_DAYS } from "data/grape";

interface GrapeColumnsProps {
  iwg: InialWithGrapes;
  width: number;
}

const GrapeColumns: React.FC<GrapeColumnsProps> = React.memo(
  ({ iwg, width }) => {
    let allGrapes = iwg.grapesWithId.length;
    const columnNumber = Math.floor(width / PixelPerColumns);
    const grapesIncolumn: number[] = columnNumber === 0 ? [allGrapes] : [];

    for (let i = 0; i < columnNumber; i++) {
      const thisColumn = Math.ceil(allGrapes / (columnNumber - i));
      grapesIncolumn.push(thisColumn + (i === 0 ? 0 : grapesIncolumn[i - 1]));
      allGrapes = allGrapes - thisColumn;
    }

    const newGrapeThreshold = new Date();
    newGrapeThreshold.setDate(newGrapeThreshold.getDate() - NEW_GRAPE_DURATION_DAYS);

    return (
      <Box
        sx={{
          padding: "0px 16px",
          display: "flex",
          flexDirection: "row",
          flex: "0 0 auto",
        }}
      >
        {grapesIncolumn.map((count, index) => (
          <List
            sx={{
              width: "100%",
              maxWidth: { xs: "400px" },
              bgcolor: "background.paper",
            }}
            key={index}
          >
            {iwg.grapesWithId
              .slice(index === 0 ? 0 : grapesIncolumn[index - 1], count)
              .map(({ grapeId, grape }) => (
                <GrapeListItem
                  grape={grape}
                  key={grapeId}
                  grapeId={grapeId}
                  width={columnNumber ? PixelPerColumns : width}
                  disableAnimation
                  isNewGrape={
                    grape?.created
                      ? new Date(grape.created.seconds * 1000) > newGrapeThreshold
                      : false
                  }
                />
              ))}
          </List>
        ))}
      </Box>
    );
  }
);

export default GrapeColumns;
