import React, { useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import {
  useCreateTag,
  Tag,
  useGetTags,
  useDeleteTag,
  useModifyTag,
  useGetTagCategories
} from "data/tag";
import ConfirmDialog from "components/common/ConfirmDialog/ConfirmDialog";
import NewEditTagDialog, { TagForm, tagsFormId } from "./NewEditTagDialog";
import MenuItem from "@mui/material/MenuItem";
import { useConfirmDialog } from "components/common/ConfirmDialog/useConfirmDialog";

const TagsPanel: React.FC = () => {
  const { data: tags, isLoading } = useGetTags();
  const { data: tagCategories } = useGetTagCategories();

  const [searchQuery, setSearchQuery] = useState("");

  const [dialogProps, setDialogProps, setDialogOpen] = useConfirmDialog();
  const [confirmDialogProps, setConfirmDialogProps, setConfirmDialogOpen] =
    useConfirmDialog();
  const { mutateAsync: createTag } = useCreateTag();
  const { mutateAsync: modifyTag } = useModifyTag();
  const { mutateAsync: deleteTag } = useDeleteTag();
  const [currentTag, setCurrentTag] = useState<Tag | undefined>(undefined);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    null
  );

  const filteredTags = useMemo(() => {
    let filteredToCategory = tags ?? [];

    if (selectedCategoryId !== null) {
      filteredToCategory = filteredToCategory.filter(
        (tag) => tag.categoryId === selectedCategoryId
      );
    }

    return (
      filteredToCategory.filter(
        (tag) =>
          tag.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (tag?.description ?? "")
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
      ) ?? []
    );
  }, [tags, searchQuery, selectedCategoryId]);

  const cleanData = (data: TagForm) => {
    const cleanedData: Partial<TagForm> = {};
    Object.keys(data).forEach((key ) => {
      const value = data[key as keyof TagForm];
      if (value !== undefined) {
        // @ts-ignore
        cleanedData[key] = value;
      }
    });
    return cleanedData;
  };

  const handleTagsFormSave = async (data: TagForm) => {
    const cleanedData = cleanData(data) as TagForm;
    if (currentTag === undefined) {
      const maxId =
        tags
          ?.map((tag) => tag.id)
          .reduce((max, curr) => (curr > max ? curr : max), 0) ?? 0;
      await createTag({
        ...cleanedData,
        id: maxId + 1,
      });
      setDialogOpen(false);
    } else {
      await modifyTag({
        ...cleanedData,
        id: currentTag.id,
      });
      setDialogOpen(false);
    }
  };

  const handleDeleteTag = async (id: number) => {
    setConfirmDialogProps({
      title: "Biztosan törölni szeretnéd?",
      onConfirm: async () => {
        await deleteTag(id);
        setConfirmDialogOpen(false);
      },
    });
    setConfirmDialogOpen(true);
  };

  const onEditDialogOpen = (tag: Tag) => {
    setCurrentTag(tag);
    setDialogProps({
      title: "Címke módosítás",
      formId: tagsFormId,
      onConfirm: () => {},
    });
    setDialogOpen(true);
  };

  const onNewTagDialogOpen = () => {
    setCurrentTag(undefined);
    setDialogProps({
      title: "Új Címke",
      formId: tagsFormId,
      onConfirm: () => {},
    });
    setDialogOpen(true);
  };

  const handleChangeCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedCategoryId(value === "összes" ? null : value);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          padding: "10px",
          marginBottom: "20px",
        }}
      >
        <TextField
          label="Search Tags"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <TextField
          select
          label="Category"
          variant="outlined"
          size="small"
          value={selectedCategoryId ?? "összes"}
          onChange={handleChangeCategory}
          style={{ minWidth: "220px" }}
        >
          <MenuItem key={-1} value={"összes"}>
            {"Összes"}
          </MenuItem>
          {tagCategories?.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </TextField>
        <Button
          sx={{
            height: "40px",
          }}
          size="small"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={onNewTagDialogOpen}
        >
          Új címke
        </Button>
      </Box>

      <List sx={{ overflow: "auto" }}>
        {filteredTags?.map((tag) => (
          <ListItem
            key={tag.id}
            secondaryAction={
              <Box sx={{ display: "flex", gap: "10px" }}>
                <IconButton edge="end" onClick={() => onEditDialogOpen(tag)}>
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" onClick={() => handleDeleteTag(tag.id)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            }
          >
            <ListItemText
              primary={`${tag.id}. ${tag.name} ${tag?.namePostfix ?? ""} (${
                tagCategories?.find(
                  (category) => category.id === tag.categoryId
                )?.name ?? "Nincs kategória"
              })`}
              secondary={(tag.description ?? "").substring(0, 50)}
            />
          </ListItem>
        ))}
      </List>

      <ConfirmDialog {...dialogProps}>
        <NewEditTagDialog onFormSave={handleTagsFormSave} tag={currentTag} />
      </ConfirmDialog>
      <ConfirmDialog {...confirmDialogProps} />
    </Box>
  );
};

export default TagsPanel;
