import Button from "@mui/material/Button";
import { db } from "../../utils/firebase";
import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  setDoc,
  Timestamp,
} from "@firebase/firestore";
import { useGetShortGrapes } from "data/grape";

import { FC } from "react";

const getGrape = async (db: Firestore, id: string) => {
  const docRef = doc(db, "grapes", id);
  const docSnap = await getDoc(docRef);

  return docSnap;
};

const CreateDateForGrapes: FC = () => {
  const { data: grapes } = useGetShortGrapes();

  const handleFillCreateForGrapes = async () => {
    Object.entries(grapes!).forEach(async ([grapeId, grape], index) => {
      const grapeRef = await getGrape(db, grapeId);

      let minTimestamp = new Timestamp(new Date().getTime() / 1000, 0);

      const grapeHistCol = collection(db, "grapes", grapeId, "history");
      const histSnapshot = await getDocs(grapeHistCol);
      histSnapshot.docs.forEach((doc) => {
        console.log("hist", doc.data());
        if (minTimestamp.seconds > doc.data().date.seconds) {
          minTimestamp = doc.data().date;
        }
      });

      const docRef = doc(db, "grapes", grapeId);
      await setDoc(docRef, {
        ...grapeRef.data(),
        created: new Date(minTimestamp.seconds * 1000),
      });
    });
  };

  return (
    <Button
      onClick={handleFillCreateForGrapes}
      color="info"
      size="small"
      variant="outlined"
      disabled
    >
      create dátum kitöltése history alapján
    </Button>
  );
};

export default CreateDateForGrapes;
