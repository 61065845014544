import React, {
  FC,
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Tag, useGetTagCategories, useGetTags } from "data/tag";
import {
  NEW_GRAPE_DURATION_DAYS,
  ShortGrape,
  useGetShortGrapes,
} from "data/grape";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import { Waypoint } from "react-waypoint";
import GrapeListItem from "components/GrapeListItem";
import { useMediaQuery, Theme, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import TagFilterDropdown from "./TagFilterDropdown";
import Collapse from "@mui/material/Collapse";
import ReactMarkdown from "react-markdown";
import { CustomImage, CustomLink } from "components/Post/MarkdownRender";
import gfm from "remark-gfm";
import SortOption, { SortOptionType, filterAndSortGrapes } from "./SortOption";
import LinearProgress from "@mui/material/LinearProgress";
import {
  extractTagIdsFromUrl,
  generateTagUrl,
  groupTagsByCategory,
  updateUrlParams,
  useAdditionalSearch,
} from "./utility";
import { Helmet } from "react-helmet";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import debounce from "lodash/debounce";
import useScrollPosition from "hooks/useScrollPosition";

const GRAPE_LOAD_STEP = 50;

export interface ShortGrapeWithId extends ShortGrape {
  id: string;
}

const GrapeSearch: FC = () => {
  const location = useLocation();
  const [paramTagIds, setParamTagIds] = useState<number[]>([]);
  const [sortOption, setSortOption] = useState<SortOptionType>("nepszeru-elol");

  const { data: tags, isLoading: tagsLoading } = useGetTags();
  const { data: tagCategories, isLoading: tagCategoriesLoading } =
    useGetTagCategories();
  const { data: shortGrapes, isLoading: grapesLoading } = useGetShortGrapes();
  const [visibleGrapesCount, setVisibleGrapesCount] = useState(GRAPE_LOAD_STEP);
  const [displayedGrapes, setDisplayedGrapes] = useState<ShortGrapeWithId[]>(
    []
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [collapseEnded, setCollapseEnded] = useState(false);
  const searchFieldRef = useRef<HTMLInputElement>();

  const idToTagMap = useMemo(() => {
    return new Map(tags?.map((tag) => [tag.id, tag]));
  }, [tags]);

  const newGrapeThreshold = new Date();
  newGrapeThreshold.setDate(
    newGrapeThreshold.getDate() - NEW_GRAPE_DURATION_DAYS
  );

  const scrollRef = useScrollPosition();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tagsParam = searchParams.get("cimkek");
    const sortParam =
      (searchParams.get("rendezes") as SortOptionType) ?? "nepszeru-elol";
    const searchTermParam = searchParams.get("kereses") ?? "";
    const tagIds = tagsParam ? extractTagIdsFromUrl(tagsParam) : [];
    setParamTagIds(tagIds);
    setSortOption(sortParam);
    setVisibleGrapesCount(GRAPE_LOAD_STEP);
    if (!searchTerm) {
      setSearchTerm(searchTermParam);
    }
  }, [location]);

  const updateURLWithTagsAndSort = useCallback(
    (tagIds: number[], newSearchTerm: string) => {
      const selectedTags: Tag[] = tagIds
        .map((tagId) => tags?.find((tag) => tag.id === tagId))
        .filter((tag): tag is Tag => tag !== undefined);

      let params: Record<string, string> = {};

      if (tagIds.length > 0) {
        params["cimkek"] = generateTagUrl(selectedTags);
      }

      if (sortOption && sortOption !== "nepszeru-elol") {
        params["rendezes"] = sortOption;
      }

      if (newSearchTerm) {
        params["kereses"] = newSearchTerm;
      }

      const url = location.pathname + updateUrlParams(params);
      navigate(url);
    },
    [tags, sortOption, location.pathname, navigate]
  );

  const groupedTagsByCategory = useMemo(() => {
    const selectedTagObjects = (tags ?? []).filter((tag) =>
      paramTagIds.includes(tag.id)
    );
    return groupTagsByCategory(selectedTagObjects, tagCategories ?? []);
  }, [tags, tagCategories, paramTagIds]);

  const filterForTags = useMemo(
    () => filterAndSortGrapes(shortGrapes, sortOption, groupedTagsByCategory),
    [shortGrapes, sortOption, groupedTagsByCategory]
  );

  const {
    searchResults: filteredGrapes,
    searchTerm,
    setSearchTerm,
  } = useAdditionalSearch(filterForTags);

  useEffect(() => {
    setDisplayedGrapes(filteredGrapes.slice(0, visibleGrapesCount));
  }, [filteredGrapes, visibleGrapesCount]);

  const handleTagRemove = (tagIdToRemove: number) => {
    const newTagIds = paramTagIds.filter((id) => id !== tagIdToRemove);
    updateURLWithTagsAndSort(newTagIds, searchTerm);
  };

  const handleTagAdding = (newTagId: number) => {
    const newTagIds = [...paramTagIds, newTagId];
    updateURLWithTagsAndSort(newTagIds, searchTerm);
  };

  const debouncedSearchChange = useMemo(
    () =>
      debounce((newSearchTerm: string) => {
        updateURLWithTagsAndSort(paramTagIds, newSearchTerm);
      }, 300),
    [paramTagIds, updateURLWithTagsAndSort]
  );

  const handleSearchChange = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
    debouncedSearchChange(newSearchTerm);
  };

  const loadMoreGrapes = () => {
    setVisibleGrapesCount((currentCount) => currentCount + GRAPE_LOAD_STEP);
  };

  const toggleDescription = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  if (tagsLoading || tagCategoriesLoading || grapesLoading) {
    return <LinearProgress />;
  }

  const tagNames = paramTagIds
    .map((id) => idToTagMap.get(id)?.name)
    .filter(Boolean)
    .join(", ");
  const description = tagNames
    ? `Keresés szőlő fajták és címkék alapján: ${tagNames}.`
    : "Keresés szőlő fajták és címkék alapján.";

  const lastTagId = paramTagIds[paramTagIds.length - 1];

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
      component="div"
    >
      <Helmet>
        <title>Szőlő keresése címkék alapján</title>
        <meta name="description" content={description} />
      </Helmet>
      <Box ref={scrollRef} sx={{ width: "100%", overflow: "auto" }}>
        <Card
          sx={{
            maxWidth: { xs: "800px", lg: "1200px" },
            width: "100%",
            background: "white",
            alignSelf: "center",
            margin: "auto",
            position: "relative",
            my: 2,
          }}
          component="div"
        >
          <CardContent>
            <h1>Szőlő keresése cimkék alapján</h1>

            <Typography
              variant="h6"
              color="GrayText"
              noWrap
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Címkék:
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {paramTagIds.length == 0 ? (
                <Box
                  sx={{
                    padding: "10px",
                    backgroundColor: "#f9f9f9",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    color: "#333",
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Válasszon ki egy vagy több címkét a találat szűkítéséhez
                </Box>
              ) : (
                paramTagIds.map((id) => (
                  <Chip
                    key={id}
                    label={idToTagMap.get(id)?.name ?? "töltődik..."}
                    onDelete={() => handleTagRemove(id)}
                  />
                ))
              )}
            </Box>
            <Box sx={{ marginTop: "10px" }}>
              <TagFilterDropdown
                tags={tags ?? []}
                tagCategories={tagCategories ?? []}
                selectedTags={paramTagIds}
                onTagAdd={handleTagAdding}
                animateBackground={paramTagIds.length === 0}
              />
            </Box>
            <Collapse
              in={
                paramTagIds?.length > 0 &&
                !!idToTagMap.get(lastTagId)?.description
              }
              unmountOnExit
            >
              <Typography
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                color="GrayText"
                variant="h6"
              >
                Az utolsó címke leírása:
              </Typography>

              <Box
                sx={{
                  backgroundColor: "lightgray",
                  padding: "10px",
                }}
              >
                <Typography variant="h5">
                  {idToTagMap.get(lastTagId)?.name ?? ""}
                </Typography>
                <Collapse
                  in={isDescriptionExpanded}
                  collapsedSize={60}
                  addEndListener={() => {
                    setCollapseEnded(isDescriptionExpanded);
                  }}
                >
                  <Box
                    sx={{
                      maxHeight: isDescriptionExpanded ? "none" : "100px",
                      overflow: collapseEnded ? "visible" : "scroll",
                    }}
                  >
                    <ReactMarkdown
                      children={idToTagMap.get(lastTagId)?.description ?? ""}
                      components={{
                        a: CustomLink,
                        img: CustomImage,
                      }}
                      remarkPlugins={[gfm]}
                    />
                  </Box>
                </Collapse>
                <Box sx={{ textAlign: "right", marginTop: "10px" }}>
                  <button onClick={toggleDescription}>
                    {isDescriptionExpanded ? "Kevesebb" : "Több"}
                  </button>
                </Box>
              </Box>
            </Collapse>
            <Typography
              variant="h6"
              color="GrayText"
              noWrap
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Rendezés:
            </Typography>
            <SortOption currentSort={sortOption} />
            <Typography
              variant="h6"
              color="GrayText"
              noWrap
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Szűrés nevek hasonlósága alapján:
            </Typography>
            <TextField
              label="Szőlő név"
              value={searchTerm}
              size="small"
              onChange={(event) =>
                handleSearchChange(event.target.value as string)
              }
              inputRef={searchFieldRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        handleSearchChange("");
                        if (searchFieldRef.current) {
                          searchFieldRef.current.focus();
                        }
                      }}
                      edge="end"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography
              variant="h6"
              color="GrayText"
              noWrap
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              {`Találat (${filteredGrapes.length} db):`}
            </Typography>
            {filteredGrapes.length === 0 && (
              <Typography variant="body1">
                Nincs találat a megadott szűrők alapján.
              </Typography>
            )}
            <Box
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              {displayedGrapes.map((grape, index) => (
                <React.Fragment key={grape.name}>
                  <GrapeListItem
                    grape={grape as ShortGrape}
                    grapeId={grape.id}
                    minWidth={isSmall ? undefined : 500}
                    width={isSmall ? undefined : 500}
                    grow={isSmall}
                    isNewGrape={
                      grape?.created
                        ? new Date(grape.created.seconds * 1000) >
                          newGrapeThreshold
                        : false
                    }
                  />
                  {index === displayedGrapes.length - 8 && (
                    <Waypoint onEnter={loadMoreGrapes} />
                  )}
                </React.Fragment>
              ))}
            </Box>
            {visibleGrapesCount < filteredGrapes.length && (
              <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                <CircularProgress />
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default GrapeSearch;
