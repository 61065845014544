import React, { FC, useMemo } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useGrapeHistoryQuery } from "data/grapeHistory";
import { useParams } from "react-router-dom";
import GrapeHistoryCard from "./GrapeHistoryCard";
import VersionSelector from "./VersionSelector";

const GrapeHistory: FC = () => {
  const { id: grapeId } = useParams<{ id: string }>();
  const { data } = useGrapeHistoryQuery(grapeId!);

  const reversedData = useMemo(() => data?.slice().reverse(), [data]);

  return (
    <Box
      sx={{
        maxWidth: { xs: "800px" },
        width: "100%",
        alignSelf: "center",
        flex: "1 1 auto",
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
      component="div"
    >
      {reversedData && <VersionSelector grapeHistory={reversedData} grapeId={grapeId!} />}
      {reversedData ? (
        reversedData.map((history, index) => (
          <GrapeHistoryCard
            history={history}
            versionNumber={reversedData.length - index}
            key={history.date.toString()}
          />
        ))
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
};

export default GrapeHistory;
