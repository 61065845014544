import React, { FC } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "components/Link";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";

import { Post } from "data/posts";
import { CustomLink } from "./MarkdownRender";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import gfm from 'remark-gfm';
import Chip from "@mui/material/Chip";

interface ListPostItemProps {
  post: Post;
  isDraftSelected?: boolean;
}

export const ListPostItem: FC<ListPostItemProps> = ({
  post,
  isDraftSelected = false,
}) => (
  <Card
    key={post.id}
    sx={{
      maxWidth: { xs: "800px", lg: "1200px" },
      width: "100%",
      background: "white",
      alignSelf: "center",
      flex: "0 0 auto",
      position: "relative",
      my: 2,
    }}
    component="div"
  >
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignContent: "space-between",
          }}
        >
          <Link
            to={`/${isDraftSelected ? "drafts" : "posts"}/${post.category}/${
              post.slug
            }`}
          >
            <Typography variant="h5">
              {post.title}
              {post.state === "rejected" && (
                <Chip
                  label="Elutasítva"
                  color="secondary"
                  size="small"
                  style={{ marginLeft: "10px" }}
                />
              )}
            </Typography>
          </Link>
          <Box flexGrow={1}>
            <ReactMarkdown
              children={post.summary}
              components={{
                a: CustomLink,
                img: CustomLink,
              }}
              remarkPlugins={[gfm]}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="caption" flexGrow={1}>
              {post.authorName ? post.authorName : "Unknown"}
              {", "}
              {post.createdAt.toDate().toLocaleDateString("hu")}
            </Typography>
            <Button
              component={Link}
              to={`/${isDraftSelected ? "drafts" : "posts"}/${post.category}/${
                post.slug
              }`}
            >
              Tovább olvasok
            </Button>
          </Box>
        </CardContent>
      </Grid>
      <Grid
        style={{
          backgroundColor: "#EEE6F7",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0px",
        }}
        item
        xs={12}
        md={4}
      >
        <Link
          to={`/${isDraftSelected ? "drafts" : "posts"}/${post.category}/${
            post.slug
          }`}
        >
          <CardMedia
            sx={{
              maxWidth: "250px",
              objectFit: "contain",
              margin: "auto",
            }}
            component="img"
            image={post.imageURL}
            alt={post.title}
          />
        </Link>
      </Grid>
    </Grid>
  </Card>
);
