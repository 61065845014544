import React, { FC, useState } from "react";
import MaterialAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import { getNameByPath } from "./DrawerComponent";
import Notifications from "./Notifications";
import { useMediaQuery, Theme } from "@mui/material";
import useAuth from "hooks/useAuth";
import { useRights } from "hooks/useRights";
import MessageIcon from "./MessageIcon";
import usePageVisitLogger, { useBotPageVisitLoggerFirestore } from "data/dailyVisits";

interface AppBarProps {
  drawerWidth: number;
  handleDrawerToggle: () => void;
  logoutMenuAnchor: HTMLSpanElement | null;
  setLogoutMenuAnchor: (element: HTMLSpanElement | null) => void;
  handleLogout: () => void;
  pathname: string;
}

const AppBar: FC<AppBarProps> = ({
  drawerWidth,
  handleDrawerToggle,
  logoutMenuAnchor,
  setLogoutMenuAnchor,
  handleLogout,
  pathname,
}) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [loginMenuAnchor, setLoginMenuAnchor] = useState<null | HTMLElement>(
    null
  );
  const { authUser, handleFacebookLogin, handleGoogleLogin } = useAuth();

  const { hasRole } = useRights();
  usePageVisitLogger();
  useBotPageVisitLoggerFirestore();

  const handleLoginMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setLoginMenuAnchor(event.currentTarget);
  };

  const handleLoginMenuClose = () => {
    setLoginMenuAnchor(null);
  };

  return (
    <MaterialAppBar
      position="fixed"
      sx={{
        width: { lg: `calc(100% - ${drawerWidth}px)` },
        ml: { lg: `${drawerWidth}px` },
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { lg: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          {getNameByPath(pathname)}
        </Typography>
        <Box sx={{ flexGrow: 1 }} component="div" />
        {authUser && <MessageIcon userId={authUser.uid} />}
        {authUser && <Notifications userId={authUser.uid} />}
        {authUser && (
          <Button
            onClick={(event) => {
              setLogoutMenuAnchor(event.currentTarget);
            }}
            style={{ color: "white" }}
            startIcon={
              authUser.photoURL ? (
                <Avatar
                  alt={authUser.displayName ?? undefined}
                  src={authUser.photoURL ?? ""}
                  sx={{
                    width: { xs: 24, sm: 50 },
                    height: { xs: 24, sm: 50 },
                  }}
                />
              ) : (
                <AccountCircle />
              )
            }
          >
            {!isSmall && authUser.displayName}
          </Button>
        )}
        {!authUser && (
          <Button
            onClick={handleLoginMenuOpen}
            style={{ color: "white" }}
            startIcon={<AccountCircle />}
          >
            {"Belépés"}
          </Button>
        )}
        <Menu
          id="logout-menu"
          anchorEl={logoutMenuAnchor}
          keepMounted
          open={!!logoutMenuAnchor}
          onClose={() => setLogoutMenuAnchor(null)}
        >
          <MenuItem onClick={handleLogout}>Kilépés</MenuItem>
        </Menu>
        <Menu
          id="login-menu"
          anchorEl={loginMenuAnchor}
          keepMounted
          open={Boolean(loginMenuAnchor)}
          onClose={handleLoginMenuClose}
        >
          <MenuItem
            onClick={() => {
              handleFacebookLogin();
              handleLoginMenuClose();
            }}
          >
            Bejelentkezés Facebookkal
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleGoogleLogin();
              handleLoginMenuClose();
            }}
          >
            Bejelentkezés Google-lel
          </MenuItem>
        </Menu>
      </Toolbar>
    </MaterialAppBar>
  );
};

export default AppBar;
