import React, { FC, PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import Toolbar from "@mui/material/Toolbar";
import { useLocation } from "react-router-dom";
import { useRights } from "hooks/useRights";
import { getAuth } from "@firebase/auth";
import { Helmet } from "react-helmet";
import AppBar from "./AppBar";
import DrawerComponent from "./DrawerComponent";

const drawerWidth = 240;
const SmallNavWidth = 31;

const MenuAndAppbar: FC<PropsWithChildren> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [logoutMenuAnchor, setLogoutMenuAnchor] =
    React.useState<HTMLSpanElement | null>(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { pathname } = useLocation();
  const { hasRole, authUser } = useRights();

  React.useEffect(() => {
    setMobileOpen(false);
  }, [pathname]);

  const handleLogout = () => {
    const auth = getAuth();
    auth.signOut().then(() => setLogoutMenuAnchor(null));
  };

  return (
    <>
      <Helmet>
        {pathname && !pathname.includes("szolok") && (
          <link rel="canonical" href={`https://szoloink.hu${pathname}`} />
        )}
      </Helmet>
      <AppBar
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        logoutMenuAnchor={logoutMenuAnchor}
        setLogoutMenuAnchor={setLogoutMenuAnchor}
        handleLogout={handleLogout}
        pathname={pathname}
      />
      <Box
        component="nav"
        sx={{
          width: { lg: drawerWidth },
          flexShrink: { lg: 0 },
          paddingTop: { xs: "56px", sm: "64px" },
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <DrawerComponent
            pathname={pathname}
            hasRole={hasRole}
            isIconView={true}
          />
        </Box>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <DrawerComponent pathname={pathname} hasRole={hasRole} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", lg: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <DrawerComponent pathname={pathname} hasRole={hasRole} />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: {
            xs: `calc(100vw - ${SmallNavWidth}px)`,
            lg: `calc(100vw - ${drawerWidth}px)`,
          },
          background: "#EEEEEE",
          display: "flex",
          flexDirection: "column",
          padding: "0px",
          height: "100vh",
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </>
  );
};

export default MenuAndAppbar;
