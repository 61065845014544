import React from "react";
import { Typography, Box, Paper } from "@mui/material";
import { ShortUserList } from "data/user";
import { UserConversation } from "data/messages-realtime";
import { assignColorsToParticipants } from "../utils";
import Avatar from "@mui/material/Avatar";

interface ConversationHeaderProps {
  selectedUserConversation: UserConversation | null | undefined;
  users?: ShortUserList;
  currentUserId: string;
}

const ConversationHeader: React.FC<ConversationHeaderProps> = ({
  selectedUserConversation,
  users,
  currentUserId,
}) => {
  const participantColors = assignColorsToParticipants(
    selectedUserConversation?.participantIds ?? []
  );

  const renderParticipantNames = () => {
    if (!selectedUserConversation || !users) return null;

    return selectedUserConversation.participantIds.map((id) => (
      <Box key={id} sx={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
        <Avatar
          src={users[id]?.photoURL}
          sx={{ width: 24, height: 24 }}
        />
        <Paper
          key={id}
          sx={{
            padding: "5px 10px",
            margin: "5px",
            backgroundColor:
              id === currentUserId ? "primary.light" : participantColors[id],
            color: id === currentUserId ? "white" : "black",
            display: "inline-flex",
          }}
        >
          <Typography variant="body2">
            {users[id]?.name || "Unknown"}
          </Typography>
        </Paper>
      </Box>
    ));
  };

  return (
    <Box
      sx={{
        padding: "10px",
        border: "1px solid #ddd",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        flex: "0 0 auto",
      }}
    >
      <Typography variant="body1" sx={{ marginRight: "10px" }}>
        Résztvevők:
      </Typography>
      {renderParticipantNames()}
    </Box>
  );
};

export default ConversationHeader;
